import { useState, useEffect } from "react";
import Button from "../../components/button/Button";
import { Modal } from "../../components/Modal/Modal";
import styles from "./InProgressIdentification.module.css";
import { OrakDatePickerRange } from "src/components/DatePicker/DatePickerRange";
import { defineDates } from "src/services/apiService";

export const InProgressModal = ({
  isOpen,
  onClose,
  description,
  setter,
  getter,
  onChange,
  type,
  typeApi,
  identificationId,
  setIsSentModalOpen,
  servicesCompanies = null,
}) => {
  const [textErrorEntreprise, setTextErrorEntreprise] = useState(null);
  const [textErrorEmail, setTextErrorEmail] = useState(null);
  const [textErrorDate, setTextErrorDate] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [company, setCompany] = useState("");

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = async () => {
    setIsSending(true);
    setTextErrorEntreprise(null);
    setTextErrorEmail(null);
    setTextErrorDate(null);
    if (
      getter.entreprise == "" ||
      getter.emailEntreprise == "" ||
      validateEmail(getter.emailEntreprise) == null ||
      getter.start == "" ||
      getter.start == null ||
      getter.end == "" ||
      getter.end == null
    ) {
      if (getter.entreprise == "") {
        setTextErrorEntreprise("Veuillez entrer le nom de l'entreprise.");
      }
      if (
        getter.emailEntreprise == "" ||
        validateEmail(getter.emailEntreprise) == null
      ) {
        setTextErrorEmail("Veuillez entrer une adresse mail valide.");
      }
      if (
        getter.start == "" ||
        getter.start == null ||
        getter.end == "" ||
        getter.end == null
      ) {
        setTextErrorDate("Veuillez entrer une date de début et de fin.");
      }
      setIsSending(false);
      return;
    }
    const responseDefine = await defineDates(
      typeApi,
      getter.start,
      getter.end,
      getter.emailEntreprise,
      getter.description,
      identificationId,
      getter.entreprise
    );
    onClose();
    setIsSentModalOpen(true);
    setIsSending(false);
  };

  useEffect(() => {
    if (servicesCompanies && getter.companyName != "") {
      var companyTemp = servicesCompanies.filter(
        (company) => company.companyName == getter.entreprise
      );
      if (companyTemp.length > 0) {
        if (companyTemp[0].companyEmail == getter.emailEntreprise) {
          setCompany(companyTemp[0].id);
        }
      }
    }
  }, [servicesCompanies]);

  const handleSelectCompany = (companySelected) => {
    if (companySelected != "") {
      var company = servicesCompanies.filter(
        (com) => com.id == companySelected
      );
      setter({
        ...getter,
        entreprise: company[0].companyName,
        emailEntreprise: company[0].companyEmail,
      });
      setCompany(company[0].id);
    } else {
      setter({ ...getter, entreprise: "", emailEntreprise: "" });
      setCompany("");
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} removeCross={true}>
      <div className={styles.modalContainer}>
        <div className={styles.modalTitle}>{description}</div>
        <div className={styles.infoModalContainer}>
          <div className={styles.formInputModalContainer}>
            {servicesCompanies ? (
              <>
                <div className={styles.modalSubtitle}>
                  Choisir une entreprise
                </div>
                <select
                  className={styles.selectCompany}
                  value={company}
                  onChange={(e) => handleSelectCompany(e.target.value)}
                >
                  <option value="">Choisissez une entreprise</option>
                  {servicesCompanies &&
                    servicesCompanies.map((company, index) => {
                      return (
                        <option key={index} value={company.id}>
                          {company.companyName}
                        </option>
                      );
                    })}
                </select>
              </>
            ) : null}
            <div className={styles.modalSubtitle}>{type} éffectué par</div>
            <input
              type="text"
              className={`main-input ${styles.formInputModal}`}
              placeholder="Nom de l’organisme / entreprise concernée"
              value={getter.entreprise}
              onChange={(e) =>
                setter({ ...getter, entreprise: e.target.value })
              }
            />
            {textErrorEntreprise && (
              <div className={styles.textErrorModal}>{textErrorEntreprise}</div>
            )}
            <div className={styles.modalSubtitle}>Email</div>
            <input
              type="email"
              className={`main-input ${styles.formInputModal}`}
              placeholder="Email de l’organisme / entreprise concernée"
              value={getter.emailEntreprise}
              onChange={(e) =>
                setter({ ...getter, emailEntreprise: e.target.value })
              }
            />
            {textErrorEmail && (
              <div className={styles.textErrorModal}>{textErrorEmail}</div>
            )}
            <div>
              <OrakDatePickerRange
                placeholder="JJ/MM/AAAA"
                selected={getter.start}
                onChange={onChange}
                startDate={getter.start}
                endDate={getter.end}
                // disabled={true}
              />
              {textErrorDate && (
                <div className={styles.textErrorModal}>{textErrorDate}</div>
              )}
            </div>
            <input
              type="text"
              className={`main-input ${styles.formInputModal}`}
              placeholder="Texte libre"
              value={getter.description}
              onChange={(e) =>
                setter({ ...getter, description: e.target.value })
              }
            />
            <div className={styles.containerBtnModal}>
              <button
                onClick={isSending == true ? null : handleSubmit}
                className={styles.btnOnline}
              >
                {isSending == true ? "Envoi..." : "Envoyer"}
              </button>
              <button onClick={onClose} className={styles.btnOnlineClose}>
                Fermer
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
