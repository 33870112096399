import * as yup from "yup";
// import { getErrorText } from "../CreateClient/validationSchema";
import { VALIDATION_ERRORS } from "src/utils/constants";

export const getErrorText = (fieldName) =>
  `${fieldName} est un champ obligatoire`;

export const locationFormSchema = yup.object().shape({
  name: yup.string().trim().required(getErrorText("Nom social")),
  address: yup.string().trim().required(getErrorText("Adresse")),
  cp: yup.string().trim().required(getErrorText("CP")),
  ville: yup.string().trim().required(getErrorText("Ville")),
  building: yup.string().trim().required(getErrorText("Bâtiment")),
  floor: yup.string().trim().required(getErrorText("Étage")),
  place: yup.string().trim().required(getErrorText("Lieu")),
  informations: yup.string().trim(),
  firstNameContact: yup.string().trim().required(getErrorText("Nom")),
  lastNameContact: yup.string().trim().required(getErrorText("Prénom")),
  phoneContact: yup.string().trim().required(getErrorText("N° de téléphone")),
  liberationDate: yup.string().trim().nullable(),
  platformHeight: yup.string().trim().nullable(),
  emailContact: yup
    .string()
    .trim()
    .email(VALIDATION_ERRORS.EMAIL)
    .required(getErrorText("Adresse email")),
});

export const locationFormSchema2 = yup.object().shape({
  name: yup.string().trim(),
  address: yup.string().trim(),
  cp: yup.string().trim(),
  ville: yup.string().trim(),
  building: yup.string().trim(),
  floor: yup.string().trim().required(getErrorText("Étage")),
  place: yup.string().trim().required(getErrorText("Lieu")),
  informations: yup.string().trim(),
  firstNameContact: yup.string().trim().required(getErrorText("Nom")),
  lastNameContact: yup.string().trim().required(getErrorText("Prénom")),
  phoneContact: yup.string().trim().required(getErrorText("N° de téléphone")),
  emailContact: yup
    .string()
    .trim()
    .email(VALIDATION_ERRORS.EMAIL)
    .required(getErrorText("Adresse email")),
});

export const carpetInformationSchema = yup.object().shape({
  // commercial: yup.string().required(getErrorText("Commercial")),
  poseurName: yup.string().trim().nullable(),
  // .required(getErrorText("Poseur")),
  proprietaireMoquette: yup.string().trim().nullable(),
  // .required(getErrorText("Propriétaire de la moquette")),
});
