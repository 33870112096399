import questionMark from "src/assets/icons/question-mark.svg";

import styles from "src/components/VenueInformation/VenueInformation.module.css";

export const InfoFooter = () => {
  return (
    <>
      <img src={questionMark} alt="question-mark-icon" />
      <p className={styles.footerText}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim
        veniam.
      </p>
    </>
  );
};
