import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "src/pages/listing-products/ListingProducts.module.css";

export default function CheckContainer({
  setFilter,
  getFilter,
  values,
  type,
  handleCheck,
  description,
  arrChecked,
}) {
  return (
    <div className={styles.filterSubContainer}>
      <div
        className={styles.filterHeader}
        onClick={() => setFilter(!getFilter)}
      >
        <div>{description}</div>
        <div>
          <FontAwesomeIcon icon={getFilter ? faXmark : faChevronDown} />
        </div>
      </div>
      {getFilter ? (
        <div className={styles.brandsContainer}>
          {values &&
            values.map((val, index) => {
              if (type == "liberation") {
                var date = `${val.slice(8, 10)}/${val.slice(5, 7)}/${val.slice(
                  0,
                  4
                )}`;
              }
              return (
                <div key={index} className={styles.brandContainer}>
                  <input
                    type="checkbox"
                    checked={arrChecked.includes(val) ? true : false}
                    onChange={(e) => handleCheck(type, val, e.target.checked)}
                    id={`${type}${index}`}
                  />
                  <label htmlFor={`${type}${index}`}>
                    {type == "liberation" ? date : val}
                  </label>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
}
