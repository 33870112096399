import React from "react";
import styles from "src/components/planningCard/PlanningCard.module.css";

import building from "src/assets/icons/Building.svg";

export default function PlanningCard({ card }) {
  return (
    <div className={styles.container}>
      <div className={styles.container__flex}>
        <div className={styles.container__flexBaseline}>
          <img src={building} alt="check-logo" className={styles.checkLogo} />
          <p style={{ fontWeight: "500", marginLeft: "6px" }}>{card.company}</p>
        </div>
        <p style={{ fontWeight: "300", textAlign: "right" }}>{card.fullName}</p>
      </div>
      <p style={{ textDecoration: "underline", fontWeight: "300" }}>
        {card.phone}
      </p>

      <div className={styles.container__flex} style={{ marginTop: "7px" }}>
        <p>Information de rendez-vous</p>
        <p>
          {card.hoursStart} à {card.hoursEnd}
        </p>
      </div>
      <p className={styles.container__address}>{card.address}</p>
      <div className={styles.container__bottom}>
        <p>{card.building}</p>
        {card.stage == "" ? (
          ""
        ) : (
          <>
            <span className={styles.borderLeft}></span>
            <p>
              {card.stage == 1 ? `${card.stage}er` : `${card.stage}ème`} étage
            </p>
          </>
        )}
        {card.digicode == "" ? (
          ""
        ) : (
          <p>
            Digicode : <span className={styles.borderLeft}></span>{" "}
            {card.digicode}
          </p>
        )}
      </div>
    </div>
  );
}
