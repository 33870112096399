import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import inputStyles from "src/components/CreateClient/CreateClient.module.css";
import { forwardRef } from "react";
import "./DatePicker.css";

const _DatePickerInput = (
  { onClick, placeholder, value, fieldName, error, ...rest },
  ref
) => {
  return (
    <div
      // className={inputStyles.formInputContainer}
      onClick={onClick}
      style={{ position: "relative" }}
    >
      <input
        className="main-input-datepicker "
        placeholder={placeholder}
        name={fieldName}
        onClick={onClick}
        value={value}
        onChange={() => {}}
        ref={ref}
      />
      {error && <p className={inputStyles.formError}>{error}</p>}
    </div>
  );
};

const DatePickerInput = forwardRef(_DatePickerInput);

export const OrakDatePicker = ({
  handleSetDate,
  value,
  placeholder,
  disableFuture,
  minDate = false,
  isClearable = false,
}) => {
  return (
    <div style={{ width: "100%" }}>
      <DatePicker
        label="Date"
        dateFormat="dd-MM-yyyy"
        value={value}
        selected={value}
        onChange={handleSetDate}
        inputFormat="dd/MM/yyyy"
        placeholderText={placeholder}
        maxDate={disableFuture && new Date()}
        showYearDropdown
        minDate={minDate}
        yearDropdownItemNumber={25}
        customInput={<DatePickerInput />}
        isClearable={isClearable}
      />
    </div>
  );
};
