import building from "src/assets/icons/Building.svg";
import styles from "./MyIdentifications.module.css";

export const IdentificationCard = ({ customerData }) => {
  const {
    firstNameContact,
    lastNameContact,
    isDraft,
    address: { name, address },
  } = customerData;
  const contactName = `${firstNameContact} ${lastNameContact}`;
  return (
    <div className={styles.container}>
      <div className={styles.container__flex}>
        <div className={styles.container__flexBaseline}>
          <img src={building} alt="check-logo" className={styles.checkLogo} />
          <p style={{ fontWeight: "500", marginLeft: "6px" }}>
            {name} {isDraft == 0 ? null : " - sauvegardé"}
          </p>
        </div>
        <p className={styles.cardText}>{contactName}</p>
      </div>
      <p style={{ marginTop: "12px" }} className={styles.cardText}>
        {address}
      </p>
    </div>
  );
};
