import styles from "src/components/VenueInformation/VenueInformation.module.css";
import checkIcon from "src/assets/check.svg";

export const SelectionBlock = ({ text, isSelected, handleClick }) => {
  return (
    <div
      className={`${styles.roomAttendanceBlock} ${
        isSelected ? styles.selected : ""
      }`}
      onClick={handleClick}
    >
      {text}
      {isSelected && (
        <div
          style={{
            position: "absolute",
            height: "8rem",
            right: "10px",
            top: "10px",
          }}
        >
          <img src={checkIcon} className={styles.checkIcon} alt="check-icon" />
        </div>
      )}
    </div>
  );
};
