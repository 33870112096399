import styles from "./VenueInformation.module.css";

import * as yup from "yup";
import * as API from "src/services/apiService";
import { Loading } from "src/components/Loading/Loading";

import imageIcon from "src/assets/icons/image-icon.svg";
import editImageIcon from "src/assets/icons/edit-image-icon.svg";
import crossIcon from "src/assets/icons/cross.svg";
import crossIconBlack from "src/assets/icons/cross-black.svg";
import { useContext, useEffect, useRef, useState } from "react";
import { ProductPhotosModal } from "./ProductPhotosModal";
import Camera, { FACING_MODES } from "react-html5-camera-photo";
import { getErrorText } from "../CreateClient/validationSchema";
import { set, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInput } from "../CreateClient/CreateClientForm";
import { HiddenButton } from "./VenueInformation";
import { dataURIToFile } from "src/utils/functions";
import { AppContext } from "src/Contexts/AppContext";
import { v4 as uuidv4 } from "uuid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCameraRotate, faXmark } from "@fortawesome/free-solid-svg-icons";

const carpetDetailsSchema = yup.object().shape({
  productName: yup.string().trim().required(getErrorText("Nom du produit")),
  productBrand: yup
    .string()
    .trim()
    .required(getErrorText("Marque : Interface")),
});
const carpetDetailsSchemaOptionnal = yup.object().shape({
  productName: yup.string().trim().nullable(),
  productBrand: yup.string().trim().nullable(),
});

const ProductPhoto = ({
  itemKey,
  handleSelectImage,
  handleRemoveImage,
  imagePath = null,
  isHidding = { isHidding },
  index,
}) => {
  const [image, setImage] = useState(imagePath);
  const [modalOpen, setModalOpen] = useState(false);
  const [cameraOpen, setCameraOpen] = useState(false);
  const [file, setFile] = useState();
  const inputRef = useRef(null);
  const [flagRefresh, setFlagRefresh] = useState(false);
  const [cameraType, setCameraType] = useState(FACING_MODES.ENVIRONMENT);

  const handleChangeCamera = () => {
    if (cameraType == "environment") {
      setCameraType(FACING_MODES.USER);
    } else {
      setCameraType(FACING_MODES.ENVIRONMENT);
    }
  };

  const {
    updateIdentificationImages,
    state: { selectedIdentification },
  } = useContext(AppContext);

  const openSelectionModal = () => {
    setModalOpen(true);
  };

  useEffect(() => {
    setImage(imagePath);
  }, [imagePath]);

  const handleAddImage = (e, itemKey) => {
    if (selectedIdentification) {
      var newImage = e.target.files[0];
      newImage.isDeleted = 0;

      var curImagesArray = selectedIdentification.imagePath;
      curImagesArray[itemKey] = newImage;

      var cloneSelectedIdentification = selectedIdentification;
      cloneSelectedIdentification.imagePath = curImagesArray;
      updateIdentificationImages(cloneSelectedIdentification);
    } else {
      handleSelectImage({ [itemKey]: e.target.files[0] });
    }

    let reader = new FileReader();
    reader.onload = (e) => setImage(e.target.result);
    reader.readAsDataURL(e.target.files[0]);
    setFile(e.target.files[0]);
    setModalOpen(false);
  };

  const addImageFromGallery = () => {
    inputRef?.current?.click();
  };

  const handleOpenCamera = () => {
    setModalOpen(false);
    setCameraOpen(true);
  };

  const onTakePhoto = (dataUri) => {
    setImage(dataUri);
    setCameraOpen(false);
    const fileName = `${Math.floor(Math.random() * 9999999) + 1}.jpg`;
    let convertedFile = dataURIToFile(dataUri, fileName);
    var itemKey = uuidv4();
    handleSelectImage({ [itemKey]: convertedFile });
  };

  const handleRemove = (e) => {
    setImage(null);
    handleRemoveImage(itemKey);
  };

  return (
    <>
      <ProductPhotosModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        addImageFromGallery={addImageFromGallery}
        handleOpenCamera={handleOpenCamera}
      />
      {cameraOpen && (
        <div className={styles.camera}>
          <FontAwesomeIcon
            className={styles.closeCamera}
            icon={faXmark}
            onClick={() => setCameraOpen(false)}
          >
            Switch
          </FontAwesomeIcon>
          {/* <img
            className={styles.closeCamera}
            src={crossIconBlack}
            alt="close-camera"
            onClick={() => setCameraOpen(false)}
          /> */}
          <Camera
            idealFacingMode={cameraType}
            onTakePhoto={(dataUri) => onTakePhoto(dataUri)}
          />
          <FontAwesomeIcon
            className={styles.switchCamera}
            icon={faCameraRotate}
            onClick={handleChangeCamera}
          >
            Switch
          </FontAwesomeIcon>
        </div>
      )}
      <div className={styles.photoContainer}>
        {image ? (
          <div className={styles.imageContainer}>
            <img
              className={styles.selectedImage}
              src={image}
              alt="selected-product"
            />
          </div>
        ) : (
          <div className={styles.blankImageContainer}>
            <img src={imageIcon} alt="product-images" />
          </div>
        )}
        {isHidding == false && (
          <>
            <input
              type="file"
              accept="image/*"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={(e) => handleAddImage(e, itemKey)}
            />
            <div className={styles.btnContainer}>
              <button
                className={`${styles.removePhotoBtn} ${
                  image ? "" : styles.disabled
                }`}
                onClick={(e) => handleRemove(e, itemKey)}
              >
                <span>
                  <img src={crossIcon} alt="remove-product-images" />
                </span>
              </button>
              <button
                className={styles.addPhotoBtn}
                onClick={openSelectionModal}
              >
                <span>
                  <img
                    style={{ marginRight: "5px" }}
                    src={editImageIcon}
                    alt="add-product-images"
                  />
                </span>
                Photo
              </button>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export const ProductPhotoContainer = ({
  submitBtnRef,
  handleData,
  handleCreateIdentification,
  setScreenDataValid,
  isHidding = false,
  isReviewCommercial = false,
  curProductImages = null,
  setImageFile = null,
  setRemoveImage = null,
  isOptional = false,
}) => {
  const {
    getLoading,
    getLoaded,
    setIdentification,
    updateIdentificationImages,
    state: { selectedIdentification, loading },
  } = useContext(AppContext);

  const [productImages, setProductImages] = useState([]);

  const pictureURLs = useRef([]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(
      isOptional == true ? carpetDetailsSchemaOptionnal : carpetDetailsSchema
    ),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    const { productName, productBrand } = data;
    if (selectedIdentification) {
      handleData("productName", productName);
      handleData("productBrand", productBrand);
      handleData("clientId", selectedIdentification.client_userID);
      setScreenDataValid(true);
    } else {
      handleCreateIdentification({ productName, productBrand, productImages });
    }
  };

  const handleProductImages = (data) => {
    var newProductImages = [...productImages, data];
    setProductImages(newProductImages);
    if (selectedIdentification) {
      var cloneSelectedIdentification = selectedIdentification;
      cloneSelectedIdentification.imagePath = newProductImages;
      updateIdentificationImages(cloneSelectedIdentification);
      // setImageFile(currentProductImages);
    }
  };

  const handleRemoveImage = (itemKey, file) => {
    // For Operator
    var currentProductImages = [...productImages];
    if (selectedIdentification) {
      currentProductImages.sort((a, b) => {
        return a.isDeleted - b.isDeleted;
      });
      currentProductImages.splice(itemKey, 1, { isDeleted: 0 });
    } else {
      currentProductImages.splice(itemKey, 1);
    }
    setProductImages(currentProductImages);

    // For Edit (commercial)
    if (selectedIdentification) {
      // if (file != null) {
      //   let reader = new FileReader();
      //   reader.readAsDataURL(null);
      // }

      var cloneSelectedIdentification = selectedIdentification;
      var cloneSelectedIdentificationImages = selectedIdentification.imagePath;
      cloneSelectedIdentificationImages.splice(itemKey, 1, null);
      cloneSelectedIdentification.imagePath = currentProductImages;

      updateIdentificationImages(cloneSelectedIdentification);
      // setImageFile(currentProductImages);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (selectedIdentification) {
      if (!selectedIdentification.imagePath) {
        selectedIdentification.imagePath = [];
      }
      const { productName, productBrand, imagePath } = selectedIdentification;

      setProductImages(selectedIdentification.imagePath);
      // if (setImageFile) {
      //   setImageFile(selectedIdentification.imagePath);
      // }
      setValue("productName", productName);
      setValue("productBrand", productBrand);

      // const loadPicture = async (path) => {
      //   try {
      //     var response = await API.getPicture(path);
      //     pictureURLs.current.push(response.data.payload);
      //   } catch (ex) {
      //     console.log("ex", ex);
      //   }
      // };

      //Reinit
      pictureURLs.current = [];

      if (imagePath !== undefined) {
        async function LoadEachPicture() {
          async function processUsers() {
            getLoading();
            let result;
            let promises = [];
            for (let i = 0; i < selectedIdentification.imagePath.length; i++) {
              promises.push(
                await API.getPicture(
                  selectedIdentification.imagePath[i].filePath,
                  selectedIdentification.id
                )
              );
            }
            result = await Promise.all(promises);
            for (let i = 0; i < selectedIdentification.imagePath.length; i++) {
              selectedIdentification.imagePath[i].result = result[i];
            }
            return selectedIdentification;
          }
          var allPictures = await processUsers();
          getLoaded();
        }

        LoadEachPicture();
      }
    } else {
      getLoaded();
    }
  }, [selectedIdentification]);

  const loadImageBlock = () => {
    var images = [];
    for (let i = 0; i < selectedIdentification.imagePath.length; i++) {
      if (
        selectedIdentification.imagePath[i] != null &&
        selectedIdentification.imagePath[i].isDeleted == 0
      ) {
        images.push(selectedIdentification.imagePath[i]);
      }
    }
    for (let i = 0; i < 4; i++) {
      if (images[i] == undefined) {
        images.push(null);
      }
    }
    return images;
  };

  return (
    <div className={styles.itemContainer}>
      <div>
        {isHidding == false && (
          <>
            {isReviewCommercial == false && (
              <form onSubmit={handleSubmit(onSubmit)}>
                <FormInput
                  style={{ marginTop: "2.5rem" }}
                  className="main-input"
                  placeholder="Marque - EX: Interface"
                  fieldName="productBrand"
                  register={register}
                  error={errors?.productBrand?.message}
                />
                <FormInput
                  className="main-input"
                  placeholder="Nom du produit"
                  fieldName="productName"
                  register={register}
                  error={errors?.productName?.message}
                />
                <HiddenButton type="submit" btnRef={submitBtnRef} />
              </form>
            )}
          </>
        )}
        {loading ? (
          <Loading />
        ) : (
          <div className={styles.productPhotoMainContainer}>
            {selectedIdentification != null &&
            selectedIdentification.imagePath ? (
              loadImageBlock().map((img, index) =>
                img != null ? (
                  <div key={index} className={styles.productPhotosContainer}>
                    <ProductPhoto
                      itemKey={index}
                      isHidding={isHidding}
                      handleSelectImage={handleProductImages}
                      handleRemoveImage={handleRemoveImage}
                      imagePath={img.result}
                      index={index}
                    />
                  </div>
                ) : isHidding == false ? (
                  <div key={index} className={styles.productPhotosContainer}>
                    <ProductPhoto
                      itemKey={index}
                      handleSelectImage={handleProductImages}
                      handleRemoveImage={handleRemoveImage}
                      isHidding={isHidding}
                      index={index}
                    />
                  </div>
                ) : (
                  <div key={index}></div>
                )
              )
            ) : (
              <>
                <div className={styles.productPhotoMainContainer}>
                  <ProductPhoto
                    itemKey={0}
                    handleSelectImage={handleProductImages}
                    handleRemoveImage={handleRemoveImage}
                    isHidding={isHidding}
                  />
                  <ProductPhoto
                    itemKey={1}
                    handleSelectImage={handleProductImages}
                    handleRemoveImage={handleRemoveImage}
                    isHidding={isHidding}
                  />
                  <ProductPhoto
                    itemKey={2}
                    handleSelectImage={handleProductImages}
                    handleRemoveImage={handleRemoveImage}
                    isHidding={isHidding}
                  />
                  <ProductPhoto
                    itemKey={3}
                    handleSelectImage={handleProductImages}
                    handleRemoveImage={handleRemoveImage}
                    isHidding={isHidding}
                  />
                </div>
              </>
            )}
          </div>
        )}
        {/* 
        {selectedIdentification == undefined ? (
          <>
            <div className={styles.productPhotoMainContainer}>
              <ProductPhoto
                itemKey={1}
                handleSelectImage={handleProductImages}
                handleRemoveImage={handleRemoveImage}
              />
              <ProductPhoto
                itemKey={2}
                handleSelectImage={handleProductImages}
                handleRemoveImage={handleRemoveImage}
              />
              <ProductPhoto
                itemKey={3}
                handleSelectImage={handleProductImages}
                handleRemoveImage={handleRemoveImage}
              />
              <ProductPhoto
                itemKey={4}
                handleSelectImage={handleProductImages}
                handleRemoveImage={handleRemoveImage}
              />
            </div>
          </>
        ) : null} */}
      </div>
    </div>
  );
};

{
  /* <ProductPhoto
  itemKey={1}
  handleSelectImage={handleProductImages}
  handleRemoveImage={handleRemoveImage}
/>
<ProductPhoto
  itemKey={2}
  handleSelectImage={handleProductImages}
  handleRemoveImage={handleRemoveImage}
/>
</div>
<div className={styles.productPhotoMainContainer}>
<ProductPhoto
  itemKey={3}
  handleSelectImage={handleProductImages}
  handleRemoveImage={handleRemoveImage}
/>
<ProductPhoto
  itemKey={4}
  handleSelectImage={handleProductImages}
  handleRemoveImage={handleRemoveImage}
/> */
}
