import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "src/Contexts/AppContext";
import Header from "src/components/header/Header";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams } from "react-router-dom";
import { sendExpertiseDemande } from "src/services/apiService";
import { IdentificationComplete } from "src/components/VenueInformation/IdentificationComplete";

import styles from "./CustomerCard.module.css";

export default function Contact() {
  const { clientid, type } = useParams();
  const [typeMsg, setTypeMsg] = useState(type);
  const [message, setMessage] = useState("");
  const [step, setStep] = useState(1);

  const dropdown = [
    { value: 0, label: "Demande d’expertise" },
    { value: 1, label: "Céder de la moquette" },
    { value: 2, label: "Une question" },
    { value: 3, label: "Autre" },
  ];

  const handleSend = async (e) => {
    e.preventDefault();
    var subject = "";
    if (typeMsg == 0) {
      subject = "Demande d’expertise";
    } else if (typeMsg == 1) {
      subject = "Céder de la moquette";
    } else if (typeMsg == 2) {
      subject = "Une question";
    } else if (typeMsg == 3) {
      subject = "Autre";
    }

    const res = await sendExpertiseDemande(message, subject);
    if (res.data.payload.status == "OK") {
      setStep(2);
    }
  };

  return (
    <>
      <Header
        screenTarget={`/client-detail/${clientid}/0`}
        icon={faChevronLeft}
      >
        <div style={{ paddingLeft: "10px" }}>Contact</div>
      </Header>
      {step == 1 && (
        <form onSubmit={handleSend}>
          <div className={styles.container}>
            <div className={styles.infoMoquetteContainer}>
              <div className={styles.formInputContainer}>
                <select
                  className={`main-input ${styles.formInput}`}
                  value={typeMsg}
                  onChange={(e) => setTypeMsg(e.target.value)}
                >
                  {dropdown.map((type) => {
                    return (
                      <option key={type.value} value={type.value}>
                        {type.label}
                      </option>
                    );
                  })}
                </select>
                <textarea
                  type="text"
                  className={`main-input ${styles.formInput}`}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
            </div>
          </div>
          <div className={styles.containerBtn}>
            <button type="submit" className={styles.btnOnline}>
              Envoyer
            </button>
          </div>
        </form>
      )}
      {step == 2 && <IdentificationComplete message="Message envoyé" />}
    </>
  );
}
