import styles from "./SearchInput.module.css";

export const SearchInput = ({ placeholder, handleChange }) => {
  return (
    <div>
      <input
        onChange={handleChange}
        className={styles["search-input"]}
        type="text"
        placeholder={placeholder}
      />
    </div>
  );
};
