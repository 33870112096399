import React, { useEffect, useState } from "react";
import ListCard from "src/components/clientCard/ListCard";
import Button from "src/components/button/Button";
import { useNavigate } from "react-router-dom";
import { Loading } from "src/components/Loading/Loading";

import styles from "./List.module.css";

export default function List({ length = 0, type, obj, handlePage, page = 1 }) {
  const [arrBtn, setArrBtn] = useState([]);
  let navigate = useNavigate();
  useEffect(() => {
    if (obj) {
      let arr = [];
      if (obj) {
        arr = Array.apply(null, Array(Math.ceil(length / 10))).map(
          (val, idx) => idx
        );
        setArrBtn(arr);
      }
    } else {
      setArrBtn([]);
    }
  }, [obj]);

  return (
    <div className={styles.container}>
      {obj && obj.length ? (
        obj.map((arr, index) => {
          return <ListCard key={index} type={type} obj={arr} />;
        })
      ) : (
        <div>Il n'y a pas de document...</div>
      )}
      <div className={styles.pageCountContainer}>
        <Loading />
        {arrBtn &&
          arrBtn.map((btn, index) => {
            return (
              <button
                key={index}
                className={
                  page === index + 1 ? styles.btnNextActive : styles.btnNext
                }
                onClick={() => handlePage(index + 1, type)}
              >
                {index + 1}
              </button>
            );
          })}
      </div>
    </div>
  );
}
