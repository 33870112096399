import React from "react";
import styles from "src/components/planningCard/PlanningCard.module.css";

import building from "src/assets/icons/Building.svg";

export const CustomerCard = ({ customerData }) => {
  return (
    <div className={styles.container}>
      <div className={styles.container__flex}>
        <div className={styles.container__flexBaseline}>
          <img src={building} alt="check-logo" className={styles.checkLogo} />
          <p style={{ fontWeight: "500", marginLeft: "6px" }}>
            {customerData.companyName}
          </p>
        </div>
        <p style={{ fontWeight: "300" }}>{customerData.clientName}</p>
      </div>
      <p style={{ fontWeight: "500" }}>Siège social</p>
      <p style={{ fontWeight: "300" }}>{customerData.address}</p>
      <p style={{ fontWeight: "300" }}>{customerData.emailAddress}</p>
      <div className={styles.container__bottom}>
        <p>{customerData.building}</p>
        <p style={{ fontWeight: "300" }}>{customerData.contactNumber}</p>
      </div>
    </div>
  );
};
