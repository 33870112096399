import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "src/Contexts/AppContext";
import Header from "src/components/header/Header";
import {
  faChevronLeft,
  faPaperclip,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import { numberWithSpace } from "src/utils/functions";
import { CompanyBanner } from "src/components/VenueInformation/CompanyBanner";
import { useParams } from "react-router-dom";
import {
  getIdentification,
  archiveIdentification,
  getServicesCompanies,
} from "src/services/apiService";
import { OrakDatePickerRange } from "src/components/DatePicker/DatePickerRange";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoMoquette from "src/assets/icons/info_moquette2.svg";
import { ProductPhotoContainer } from "src/components/VenueInformation/ProductPhotos";
import { useNavigate, useLocation } from "react-router-dom";
import { InProgressModal } from "./InProgressModal";
import { Modal } from "src/components/Modal/Modal";

import styles from "src/pages/in-progress-identification/InProgressIdentification.module.css";

export const DateCard = ({
  description,
  setter,
  getter,
  type,
  typeApi,
  identificationId,
  setIsSentModalOpen,
  servicesCompanies = null,
}) => {
  const hours = [
    { value: 1, label: "Entre 8:00 et 9:00" },
    { value: 2, label: "Entre 9:00 et 10:00" },
    { value: 3, label: "Entre 10:00 et 11:00" },
    { value: 4, label: "Entre 11:00 et 12:00" },
    { value: 5, label: "Entre 12:00 et 13:00" },
    { value: 6, label: "Entre 13:00 et 14:00" },
    { value: 7, label: "Entre 14:00 et 15:00" },
    { value: 8, label: "Entre 15:00 et 16:00" },
    { value: 9, label: "Entre 16:00 et 17:00" },
    { value: 10, label: "Entre 17:00 et 18:00" },
    { value: 11, label: "Entre 18:00 et 19:00" },
    { value: 12, label: "Entre 19:00 et 20:00" },
  ];
  const [isOpen, setIsOpen] = useState(false);

  const onClose = () => {
    setIsOpen(false);
  };

  const handleSetDate = (dates) => {
    const [start, end] = dates;
    setter({ ...getter, start: start, end: end });
  };

  return (
    <>
      <InProgressModal
        isOpen={isOpen}
        onClose={onClose}
        description={description}
        placeholder="JJ/MM/AAAA"
        onChange={handleSetDate}
        getter={getter}
        setter={setter}
        type={type}
        typeApi={typeApi}
        identificationId={identificationId}
        setIsSentModalOpen={setIsSentModalOpen}
        servicesCompanies={servicesCompanies}
      />
      <div className={styles.dateCardContainer}>
        <div className={styles.dateCardSubSontainer}>
          <div className={styles.dateCardDescription}>{description}</div>
          <div className={styles.dateCardModif} onClick={() => setIsOpen(true)}>
            <span>
              <FontAwesomeIcon icon={faPen} />
            </span>
            Modifier
          </div>
        </div>
        <div className={styles.dateCardSubSontainer}>
          <div>
            <OrakDatePickerRange
              placeholder="JJ/MM/AAAA"
              selected={getter.start}
              onChange={handleSetDate}
              startDate={getter.start}
              endDate={getter.end}
              disabled={true}
            />
          </div>
          {/* <div className={styles.dateCardHours}>
          <select
            className={styles.selectHours}
            onChange={(e) => setter({ ...getter, hour: e.target.value })}
          >
            {hours.map((hour, i) => {
              return (
                <option key={i} value={hour.value}>
                  {hour.label}
                </option>
              );
            })}
          </select>
        </div> */}
        </div>
      </div>
    </>
  );
};

export default function InProgressIdentification() {
  // const [identificationData, setIdentificationData] = useState(null);
  const { identificationId } = useParams();
  const [errorEnlevement, setErrorEnlevement] = useState(null);
  const [errorDepose, setErrorDepose] = useState(null);
  const [errorNettoyage, setErrorNettoyage] = useState(null);
  const { currentStatus } = useLocation().state;
  const [isSentModalOpen, setIsSentModalOpen] = useState(false);
  const [servicesCompanies, setServicesCompanies] = useState(null);
  const [dateEnlevement, setDateEnlevement] = useState({
    start: "",
    end: "",
    entreprise: "",
    description: "",
    emailEntreprise: "",
  });
  const [datePalette, setDatePalette] = useState({
    start: "",
    end: "",
    entreprise: "",
    description: "",
    emailEntreprise: "",
  });
  const [dateNettoyage, setDateNettoyage] = useState({
    start: "",
    end: "",
    entreprise: "",
    description: "",
    emailEntreprise: "",
  });
  const [dateDepose, setDateDepose] = useState({
    start: "",
    end: "",
    entreprise: "",
    description: "",
    emailEntreprise: "",
  });

  const {
    state: { myIdentifications, selectedIdentification },
    getAddressIndentificationByClientId,
    setIdentification,
  } = useContext(AppContext);

  const navigate = useNavigate();

  const fetchIdentification = async () => {
    const res = await getIdentification(identificationId);
    setIdentification(res.data.payload[0]);
    const res2 = await getAddressIndentificationByClientId(
      res.data.payload[0].client_userID
    );

    const responseServicesCompanies = await getServicesCompanies();
    setServicesCompanies(responseServicesCompanies.data.payload.data);
  };

  useEffect(() => {
    fetchIdentification();
  }, []);

  const handleArchive = async (identificationId) => {
    const res = await archiveIdentification(identificationId);
    if (res.data.status == "OK") {
      navigate("/my-identifications");
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const getServicesCompaniesType = (type) => {
    if (servicesCompanies) {
      return servicesCompanies.filter(
        (companies) => companies.serviceType == type
      );
    }
  };

  const handleOnLine = () => {
    setErrorEnlevement(null);
    setErrorNettoyage(null);
    setErrorDepose(null);
    if (
      dateEnlevement.start == "" ||
      dateEnlevement.end == null ||
      dateNettoyage.start == "" ||
      dateNettoyage.end == null ||
      dateDepose.start == "" ||
      dateDepose.end == null ||
      dateEnlevement.entreprise == "" ||
      dateNettoyage.entreprise == "" ||
      dateDepose.entreprise == "" ||
      dateEnlevement.emailEntreprise == "" ||
      dateNettoyage.emailEntreprise == "" ||
      dateDepose.emailEntreprise == "" ||
      validateEmail(dateEnlevement.emailEntreprise) == null ||
      validateEmail(dateNettoyage.emailEntreprise) == null ||
      validateEmail(dateDepose.emailEntreprise) == null
    ) {
      if (
        dateEnlevement.start == "" ||
        dateEnlevement.end == null ||
        dateEnlevement.entreprise == "" ||
        dateEnlevement.emailEntreprise == "" ||
        validateEmail(dateEnlevement.emailEntreprise) == null
      ) {
        setErrorEnlevement("Veuillez remplir tous les champs");
      }
      if (
        dateNettoyage.start == "" ||
        dateNettoyage.end == null ||
        dateNettoyage.entreprise == "" ||
        dateNettoyage.emailEntreprise == "" ||
        validateEmail(dateNettoyage.emailEntreprise) == null
      ) {
        setErrorNettoyage("Veuillez remplir tous les champs");
      }
      if (
        dateDepose.start == "" ||
        dateDepose.end == null ||
        dateDepose.entreprise == "" ||
        dateDepose.emailEntreprise == "" ||
        validateEmail(dateDepose.emailEntreprise) == null
      ) {
        setErrorDepose("Veuillez remplir tous les champs");
      }
      window.scrollTo(0, 0);
    } else {
      navigate(`/on-line/commercial/${identificationId}`, {
        state: [dateEnlevement, dateNettoyage, dateDepose],
      });
    }
  };

  useEffect(() => {
    if (selectedIdentification) {
      if (selectedIdentification.dateDepose_start) {
        setDateDepose({
          ...dateDepose,
          start: new Date(selectedIdentification.dateDepose_start),
          end: new Date(selectedIdentification.dateDepose_end),
          entreprise: selectedIdentification.depose_entreprise,
          description: selectedIdentification.depose_description,
          emailEntreprise: selectedIdentification.depose_email,
        });
      }
      if (selectedIdentification.dateEnlevement_start) {
        setDateEnlevement({
          ...dateEnlevement,
          start: new Date(selectedIdentification.dateEnlevement_start),
          end: new Date(selectedIdentification.dateEnlevement_end),
          entreprise: selectedIdentification.enlevement_entreprise,
          description: selectedIdentification.enlevement_description,
          emailEntreprise: selectedIdentification.enlevement_email,
        });
      }
      if (selectedIdentification.dateNettoyage_start) {
        setDateNettoyage({
          ...dateNettoyage,
          start: new Date(selectedIdentification.dateNettoyage_start),
          end: new Date(selectedIdentification.dateNettoyage_end),
          entreprise: selectedIdentification.nettoyage_entreprise,
          description: selectedIdentification.nettoyage_description,
          emailEntreprise: selectedIdentification.nettoyage_email,
        });
      }
      if (selectedIdentification.datePalette_start) {
        setDatePalette({
          ...datePalette,
          start: new Date(selectedIdentification.datePalette_start),
          end: new Date(selectedIdentification.datePalette_end),
          entreprise: selectedIdentification.palette_entreprise,
          description: selectedIdentification.palette_description,
          emailEntreprise: selectedIdentification.palette_email,
        });
      }
    }
  }, [selectedIdentification]);

  const onCloseSentModal = () => {
    setIsSentModalOpen(false);
  };

  return (
    <>
      <Header screenTarget={`/my-identifications`} icon={faChevronLeft}>
        <div style={{ paddingLeft: "10px" }}>Identification en cours</div>
      </Header>
      <Modal
        isOpen={isSentModalOpen}
        onClose={onCloseSentModal}
        removeCross={true}
      >
        <p style={{ textAlign: "center" }}>Demande bien envoyée</p>
        <button
          className={styles.btnModalPop}
          onClick={() => setIsSentModalOpen(false)}
        >
          Fermer
        </button>
      </Modal>
      <div style={{ display: "flex" }}>
        {currentStatus == "inProgress" ? (
          <div className={styles.statusContainerInProgress}>
            <div className={styles.orangeLight}></div>
            <div className={styles.textStatus}>En cours</div>
          </div>
        ) : currentStatus == "terminated" ? (
          <div className={styles.statusContainerComplete}>
            <div className={styles.greenLight}></div>
            <div className={styles.textStatus}>Terminée</div>
          </div>
        ) : null}
        {selectedIdentification &&
        selectedIdentification.subStatus == "re-employment" ? (
          <div className={styles.statusContainerInProgress}>
            <div className={styles.orangeLight}></div>
            <div className={styles.textStatus}>En attente de réemploi</div>
          </div>
        ) : selectedIdentification &&
          selectedIdentification.subStatus == "re-employed" ? (
          <div className={styles.statusContainerComplete}>
            <div className={styles.greenLight}></div>
            <div className={styles.textStatus}>Réemployée</div>
          </div>
        ) : null}
      </div>
      {currentStatus == "terminated" &&
        selectedIdentification &&
        selectedIdentification.subStatus == "re-employment" && (
          <div className={styles.containerCardContainer}>
            <a
              target="_blank"
              href={`https://www.optimal-karpet.fr/api/Templates/getTemplate?token=${selectedIdentification.token}&identificationID=${identificationId}&mode=prelevement`}
              className={styles.cardContainer}
            >
              <div className={styles.leftBlock}>
                <FontAwesomeIcon icon={faPaperclip} />
                <p>Télécharger l’attestation prélèvement</p>
              </div>
            </a>
          </div>
        )}
      {currentStatus == "terminated" &&
        selectedIdentification &&
        selectedIdentification.subStatus == "re-employed" && (
          <div className={styles.containerCardContainer}>
            <a
              target="_blank"
              href={`https://www.optimal-karpet.fr/api/Templates/getTemplate?token=${selectedIdentification.token}&identificationID=${identificationId}&mode=reemploye`}
              className={styles.cardContainer}
            >
              <div className={styles.leftBlock}>
                <FontAwesomeIcon icon={faPaperclip} />
                <p>Télécharger l’attestation réemploi</p>
              </div>
            </a>
          </div>
        )}
      <div className={styles.container}>
        <div className={styles.greenCardInfosContainer}>
          <div className={styles.greenCardInfosContainerBlock}>
            <p>Nombre de m2 récupérables</p>
            <p>
              {numberWithSpace(
                selectedIdentification ? selectedIdentification.surface_m2 : "-"
              )}

              <span> m2</span>
            </p>
          </div>
        </div>
      </div>
      {selectedIdentification && (
        <div className={styles.container}>
          <CompanyBanner />
        </div>
      )}
      {currentStatus == "inProgress" && (
        <>
          <div className={styles.container}>
            <DateCard
              description="Définir une date de nettoyage "
              type="Nettoyage"
              typeApi="nettoyage"
              getter={dateNettoyage}
              setter={setDateNettoyage}
              identificationId={identificationId}
              setIsSentModalOpen={setIsSentModalOpen}
              servicesCompanies={getServicesCompaniesType(1)}
            />
            {errorNettoyage && (
              <div className={styles.textError}>{errorNettoyage}</div>
            )}
          </div>
          <div className={styles.container}>
            <DateCard
              description="Définir une date de livraison des caisses palette "
              type="Récupération des caisses palette"
              typeApi="palette"
              getter={datePalette}
              setter={setDatePalette}
              identificationId={identificationId}
              setIsSentModalOpen={setIsSentModalOpen}
              servicesCompanies={getServicesCompaniesType(2)}
            />
          </div>
          <div className={styles.container}>
            <DateCard
              description="Définir une date de dépose "
              type="Dépose"
              typeApi="depose"
              getter={dateDepose}
              setter={setDateDepose}
              identificationId={identificationId}
              setIsSentModalOpen={setIsSentModalOpen}
              servicesCompanies={getServicesCompaniesType(3)}
            />
            {errorDepose && (
              <div className={styles.textError}>{errorDepose}</div>
            )}
          </div>
          <div className={styles.container} style={{ marginTop: "-1rem" }}>
            <DateCard
              description="Définir une date d’enlèvement "
              type="Enlèvement"
              typeApi="enlevement"
              getter={dateEnlevement}
              setter={setDateEnlevement}
              identificationId={identificationId}
              setIsSentModalOpen={setIsSentModalOpen}
              servicesCompanies={getServicesCompaniesType(4)}
            />
            {errorEnlevement && (
              <div className={styles.textError}>{errorEnlevement}</div>
            )}
          </div>
        </>
      )}
      <div className={styles.container}>
        <div className={styles.infoMoquetteContainer}>
          <div className={styles.subTitleContainer}>
            <div className={styles.greenRound}>
              <img src={InfoMoquette} alt="info moquette" />
            </div>
            <div className={styles.subInfos}>
              <h2 className={styles.subTitle}>Informations moquette</h2>
              <p className={styles.subTitleInfo}>
                Renseignez les valeurs ci-dessous
              </p>
            </div>
          </div>
          {selectedIdentification && (
            <div className={styles.formInputContainer}>
              <div className={`main-input ${styles.formInput}`}>
                {selectedIdentification.productName}
              </div>
              <div className={`main-input ${styles.formInput}`} type="text">
                {selectedIdentification.productBrand}
              </div>
            </div>
          )}
          {selectedIdentification && selectedIdentification.id && (
            <ProductPhotoContainer isHidding={true} />
          )}
        </div>
        {currentStatus == "inProgress" && (
          <div className={styles.containerBtn}>
            <button className={styles.btnOnline} onClick={handleOnLine}>
              Mettre en ligne la moquette
            </button>
            <button
              className={styles.btnArchive}
              onClick={() => handleArchive(identificationId)}
            >
              Archiver l’identification
            </button>
          </div>
        )}
      </div>
    </>
  );
}
