import Button from "../button/Button";
import { Modal } from "../Modal/Modal";

export const ProductPhotosModal = ({
  isOpen,
  onClose,
  addImageFromGallery,
  handleOpenCamera,
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} removeCross={true}>
      <div style={{ width: "20rem" }}>
        <div style={{ marginTop: "1rem" }}>
          <Button variant="primary" onClick={handleOpenCamera}>
            Utiliser l'appareil photo
          </Button>
        </div>
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Button variant="primary" onClick={addImageFromGallery}>
            Ajouter depuis la galerie
          </Button>
        </div>
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Button variant="primary" onClick={onClose}>
            Fermer
          </Button>
        </div>
      </div>
    </Modal>
  );
};
