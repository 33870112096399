import React from "react";
import styles from "src/components/VenueInformation/VenueInformation.module.css";
import checkIcon from "src/assets/check.svg";

export default function ButtonSecondary({ children, status, onClick, margin }) {
  return (
    <div
      onClick={onClick}
      style={{ margin }}
      // className={`button-secondary ${status}`}
      className={`${styles.roomAttendanceBlock} ${
        status ? styles.selected : ""
      }`}
    >
      {children}
      {status && (
        <div
          style={{
            position: "absolute",
            height: "8rem",
            right: "5px",
            top: "5px",
          }}
        >
          <img src={checkIcon} className={styles.checkIcon} alt="check-icon" />
        </div>
      )}
    </div>
  );
}
