// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_card__OmwMC {\n  font-size: 14px;\n  padding-top: 41px;\n  padding-left: 18px;\n  padding-right: 18px;\n  background: #fff;\n  box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);\n  border-radius: 5px;\n  line-height: 22px;\n  font-weight: 300px;\n  margin-bottom: 36px;\n}\n\n.Form_subTitle__HBjze {\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  margin-bottom: 25px;\n  font-weight: 500;\n}\n", "",{"version":3,"sources":["webpack://./src/components/form/Form.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;EAChB,uCAAuC;EACvC,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".card {\n  font-size: 14px;\n  padding-top: 41px;\n  padding-left: 18px;\n  padding-right: 18px;\n  background: #fff;\n  box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);\n  border-radius: 5px;\n  line-height: 22px;\n  font-weight: 300px;\n  margin-bottom: 36px;\n}\n\n.subTitle {\n  font-size: 18px;\n  line-height: 22px;\n  text-align: center;\n  margin-bottom: 25px;\n  font-weight: 500;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"card": "Form_card__OmwMC",
	"subTitle": "Form_subTitle__HBjze"
};
export default ___CSS_LOADER_EXPORT___;
