import { useContext, useEffect, useState } from "react";
import styles from "src/components/VenueInformation/VenueInformation.module.css";
import { AppContext } from "src/Contexts/AppContext";
import { ROOM_ATTENDANCE_OPTIONS } from "./constants";
import { SelectionBlock } from "./SelectionBlock";
import { HiddenButton } from "./VenueInformation";

export const RoomAttendance = ({
  submitBtnRef,
  handleData,
  setScreenDataValid,
  enteredData = null,
}) => {
  const {
    state: { selectedIdentification },
  } = useContext(AppContext);

  useEffect(() => {
    if (selectedIdentification) {
      setSelectedOption(selectedIdentification.roomAttendance);
    }
  }, [selectedIdentification]);

  const [selectedOption, setSelectedOption] = useState(
    ROOM_ATTENDANCE_OPTIONS.FAIBLE
  );

  const handleSubmit = () => {
    setScreenDataValid(true);
    handleData("roomAttendance", selectedOption);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (enteredData["roomAttendance"]) {
      var value = enteredData["roomAttendance"];
      if (value == "Faible") {
        setSelectedOption(ROOM_ATTENDANCE_OPTIONS.FAIBLE);
      } else if (value == "Moyenne") {
        setSelectedOption(ROOM_ATTENDANCE_OPTIONS.MOYENNE);
      } else if (value == "Elevee") {
        setSelectedOption(ROOM_ATTENDANCE_OPTIONS.ELVEE);
      } else {
        setSelectedOption(ROOM_ATTENDANCE_OPTIONS.TRES_ELVEE);
      }
    }
  }, []);

  return (
    <div className={styles.itemContainer} style={{ padding: "20px" }}>
      <div className={styles.roomAttendanceContainer}>
        <SelectionBlock
          text={ROOM_ATTENDANCE_OPTIONS.FAIBLE}
          isSelected={selectedOption === ROOM_ATTENDANCE_OPTIONS.FAIBLE}
          handleClick={() => setSelectedOption(ROOM_ATTENDANCE_OPTIONS.FAIBLE)}
        />
        <SelectionBlock
          text={ROOM_ATTENDANCE_OPTIONS.MOYENNE}
          isSelected={selectedOption === ROOM_ATTENDANCE_OPTIONS.MOYENNE}
          handleClick={() => setSelectedOption(ROOM_ATTENDANCE_OPTIONS.MOYENNE)}
        />
      </div>
      <div className={styles.roomAttendanceContainer}>
        <SelectionBlock
          text={ROOM_ATTENDANCE_OPTIONS.ELVEE}
          isSelected={selectedOption === ROOM_ATTENDANCE_OPTIONS.ELVEE}
          handleClick={() => setSelectedOption(ROOM_ATTENDANCE_OPTIONS.ELVEE)}
        />
        <SelectionBlock
          text={ROOM_ATTENDANCE_OPTIONS.TRES_ELVEE}
          isSelected={selectedOption === ROOM_ATTENDANCE_OPTIONS.TRES_ELVEE}
          handleClick={() =>
            setSelectedOption(ROOM_ATTENDANCE_OPTIONS.TRES_ELVEE)
          }
        />
        <HiddenButton btnRef={submitBtnRef} onClick={handleSubmit} />
      </div>
    </div>
  );
};
