import BuildingCartographie from "src/assets/icons/building-cartographies.svg";
import { numberWithSpace } from "src/utils/functions";
import { useNavigate } from "react-router-dom";

import styles from "./CartographiesList.module.css";

export const Card = ({ address, idClient }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.cardContainer}
      onClick={() =>
        navigate(`/client-cartographie-detail/${idClient}/${address.id}`)
      }
    >
      <div>
        <div className={styles.cardBuildingSvg}>
          <img src={BuildingCartographie} alt="building" />
        </div>
        <div className={styles.cardSubContainer}>
          <div className={styles.cardInfosTop}>
            <p>Immeuble {address.building}</p>
            <p>{`${address.address} ${address.cp} ${address.ville}`}</p>
          </div>
          <div className={styles.cardInfosBottom}>
            <div className={styles.cardInfos}>
              <p>total m2 récupérables</p>
              <p>
                {address.surfaceRecuperable &&
                  numberWithSpace(address.surfaceRecuperable)}
                <span>M2</span>
              </p>
            </div>
            <div className={styles.cardInfos}>
              <p>Valeur estimative à date</p>
              {address.estimationRachatMoquette > 0 ? (
                <p>
                  {numberWithSpace(
                    Number(address.estimationRachatMoquette) /
                      Number(address.surfaceRecuperable)
                  )}{" "}
                  <span>€/m² HT</span>
                </p>
              ) : (
                <p className={styles.noValeur}>En cours de chiffrage</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function CartographiesList({ idClient, addressArr }) {
  return (
    <div>
      {addressArr &&
        addressArr.map((address, index) => {
          return <Card key={index} address={address} idClient={idClient} />;
        })}
    </div>
  );
}
