import React, { useContext, useEffect, useState } from "react";
import Button from "src/components/button/Button";
import Header from "src/components/header/Header";
import PlanningCard from "src/components/planningCard/PlanningCard";
import { fakeCards } from "src/pages/navigation/calendarFakeData";

import { useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import styles from "src/pages/navigation/Calendar.module.css";
import { AppContext } from "src/Contexts/AppContext";
import { OrakDatePicker } from "src/components/DatePicker/DatePicker";
import { parsePraxedoData } from "src/utils/functions";

export default function Calendar() {
  const getCurrentDate = () => new Date().toISOString().split("T")[0];

  let navigate = useNavigate();
  const [yesButton, setYesButton] = useState("active");
  const [noButton, setNoButton] = useState();
  const [cards, setCards] = useState(fakeCards);

  const [selectedDate, setSelectedDate] = useState(null);
  const [parsedDate, setParsedDate] = useState(null);
  const [parsedCalendarData, setParsedCalendarData] = useState([]);

  const {
    state: { calendarEvents, loading },
    getCalendarEvents,
  } = useContext(AppContext);

  const backButton = () => {
    console.log("backButton");
  };

  const goNextScreen = () => {
    yesButton == "active"
      ? navigate("/calendrier")
      : console.log("go to new question");
  };

  useEffect(() => {
    const currentDate = getCurrentDate();
    getCalendarEvents(currentDate);
  }, []);

  useEffect(() => {
    const parsedData = parsePraxedoData(calendarEvents);
    setParsedCalendarData(parsedData);
  }, [calendarEvents]);

  const handleSetDate = (value) => {
    setSelectedDate(value);
    const parsedDate = value.toISOString().split("T")[0];
    setParsedDate(parsedDate);
  };

  const handleFetchEvents = () => getCalendarEvents(parsedDate);

  return (
    <>
      <Header screenTarget="/home" icon={faChevronLeft}>
        Calendrier
      </Header>
      <div className="container">
        <div className={styles.title}>
          {/* <h2>Planning</h2>
          <p className={styles.titleDate}>10/12/2022</p> */}
          <OrakDatePicker
            placeholder="Sélectionner une date"
            value={selectedDate}
            handleSetDate={handleSetDate}
          />
          <Button
            className={styles.applyBtn}
            variant="primary"
            onClick={handleFetchEvents}
          >
            Filtrer
          </Button>
        </div>

        {!!parsedCalendarData.length ? (
          parsedCalendarData.map((card) => {
            return (
              <Link key={card.id} to={`/rendez-vous/${card.id}`} state={card}>
                <PlanningCard card={card} />
              </Link>
            );
          })
        ) : (
          <>
            {!loading && (
              <p style={{ display: "flex", justifyContent: "center" }}>
                Aucun évènements pour la date sélectionnée...
              </p>
            )}
          </>
        )}
      </div>
    </>
  );
}
