import { Routes, Route, Navigate } from "react-router-dom";
import Login from "src/pages/login/Login.jsx";
import LoginValidation from "src/pages/login/LoginValidation.jsx";
import Home from "src/pages/home/Home.jsx";
import NewIdentification from "src/pages/navigation/NewIdentification";
import Calendar from "src/pages/navigation/Calendar";
import RendezVous from "src/pages/navigation/RendezVous";
import MakeAnIdentification from "src/pages/navigation/MakeAnIdentification";
import { CustomerDatabase } from "src/pages/navigation/CustomerDatabase";
import CustomerCard from "src/pages/customer-card/CustomerCard.jsx";
import CartographieDetail from "src/components/clientCard/CartographieDetail";
import { CreateClient } from "src/components/CreateClient/CreateClient";
import VenueInformation from "src/components/VenueInformation/VenueInformation";
import { MyIdentifications } from "src/components/MyIdentifications/MyIdentifications";
import { StudyIdentifications } from "src/components/StudyIdentifications/StudyIdentifications";
import InProgressIdentification from "src/pages/in-progress-identification/InProgressIdentification.jsx";
import OnLine from "src/pages/in-progress-identification/OnLine";
import Contact from "src/pages/customer-card/Contact";
import ListingProducts from "src/pages/listing-products/ListingProducts.jsx";
import DetailsProducts from "src/pages/listing-products/DetailsProducts";
import SameAddress from "src/components/CreateClient/SameAddress";

import FicheClient from "src/pages/navigation/FicheClient";

function Routing() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/login" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login-validation" element={<LoginValidation />} />
        <Route path="/home" element={<Home />} />
        <Route
          path="/nouvelle-identification"
          element={<NewIdentification />}
        />
        <Route path="/calendrier" element={<Calendar />} />
        <Route path="/rendez-vous/:id" element={<RendezVous />} />
        <Route
          path="/faire-une-identification"
          element={<MakeAnIdentification />}
        />
        <Route
          path="/customer-database"
          element={<CustomerDatabase identification={true} />}
        />
        <Route
          path="/customer-database-home"
          element={<CustomerDatabase identification={false} />}
        />
        <Route path="/client/:id" element={<FicheClient />} />
        <Route
          path="/client/edit/:id"
          element={<CreateClient identification={false} edit={true} />}
        />
        <Route
          path="/client-detail/:id/:subLinkId"
          element={<CustomerCard />}
        />
        <Route
          path="/client-cartographie-detail/:clientid/:id"
          element={<CartographieDetail />}
        />
        <Route path="/client-contact/:clientid/:type" element={<Contact />} />
        <Route path="/same-address" element={<SameAddress />} />
        <Route
          path="/creation-identification/:id"
          element={<VenueInformation />}
        />
        <Route
          path="/create-client"
          element={<CreateClient identification={true} edit={false} />}
        />
        <Route
          path="/create-client-simple"
          element={<CreateClient identification={false} edit={false} />}
        />
        <Route path="/my-identifications" element={<MyIdentifications />} />
        <Route
          path="/study-identification/:identificationId"
          element={<StudyIdentifications />}
        />
        <Route
          path="/study-identification/commercial/:identificationId"
          element={<StudyIdentifications />}
        />
        <Route
          path="/in-progress-identification/commercial/:identificationId"
          element={<InProgressIdentification />}
        />
        <Route
          path="/on-line/commercial/:identificationId"
          element={<OnLine />}
        />
        <Route path="/listing-products" element={<ListingProducts />} />
        <Route
          path="/detail-products/:typeRef/:referenceID"
          element={<DetailsProducts />}
        />
      </Routes>
    </>
  );
}

export default Routing;
