import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/Contexts/AppContext";
import { VALIDATION_ERRORS, VENUE_INFO_ERRORS } from "src/utils/constants";
import { CONTRACT_OPTIONS, SELECTION_OPTION } from "./constants";
import { ContractOptions } from "./ContractOptions";
import { ContractWithOrak } from "./ContractWithOrak";
import { SelectionBlock } from "./SelectionBlock";
import { HiddenButton } from "./VenueInformation";

import styles from "./VenueInformation.module.css";

export const SelectNoInput = ({ placeholder, onChange, error, value }) => {
  return (
    <div
      style={{ marginTop: "2rem", padding: "1.5rem" }}
      className={styles.itemContainer}
    >
      <input
        defaultValue={value}
        className="main-input"
        placeholder={placeholder}
        onChange={onChange}
      />
      {error && <span className={styles.formError}>{error}</span>}
    </div>
  );
};

export const AnnualContract = ({
  handleData,
  setScreenDataValid,
  handleSetError,
  error,
  submitBtnRef,
  enteredData = null,
}) => {
  const {
    state: { selectedIdentification },
  } = useContext(AppContext);

  useEffect(() => {
    if (selectedIdentification) {
      setSelectedBlock(
        selectedIdentification.cleaningContract
          ? SELECTION_OPTION.OUI
          : SELECTION_OPTION.NON
      );
      setOrakInsuranceOption(selectedIdentification.cleaningContractWithOrak);
      if (selectedIdentification.cleaningContactWithoutOrak.length > 0) {
        setHasOrakInsurance(SELECTION_OPTION.NON);
        setOtherInsuranceProvider(
          selectedIdentification.cleaningContactWithoutOrak
        );
      }
    }
  }, [selectedIdentification]);

  const [selectedBlock, setSelectedBlock] = useState(SELECTION_OPTION.OUI);
  const [orakInsuranceOption, setOrakInsuranceOption] = useState(
    CONTRACT_OPTIONS.CONFORT
  );
  const [otherInsuranceProvider, setOtherInsuranceProvider] = useState("");

  const [hasOrakInsurance, setHasOrakInsurance] = useState(
    SELECTION_OPTION.OUI
  );

  const handleHasOrakInsurance = (selection) => {
    if (selection === SELECTION_OPTION.OUI) {
      handleSetError({
        insuranceProvider: undefined,
      });
      setScreenDataValid(true);
    } else {
      handleSetError({
        insuranceProvider: VENUE_INFO_ERRORS.insuranceProvider,
      });
      setScreenDataValid(false);
    }
    setHasOrakInsurance(selection);
  };

  const handleSubmit = () => {
    handleData("cleaningContract", selectedBlock === SELECTION_OPTION.OUI);
    handleData("cleaningContractWithOrak", orakInsuranceOption);
    handleData("cleaningContactWithoutOrak", otherInsuranceProvider);
    setScreenDataValid(true);
  };

  const handleInput = (e) => {
    if (otherInsuranceProvider) {
      handleSetError({
        insuranceProvider: undefined,
      });
    }
    setOtherInsuranceProvider(e.target.value);
    setScreenDataValid(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // if (
    //   enteredData["cleaningContract"] == false &&
    //   enteredData["cleaningContactWithoutOrak"]
    // ) {
    //   setSelectedBlock(SELECTION_OPTION.NON);
    // } else {
    //   setSelectedBlock(SELECTION_OPTION.OUI);
    //   if (enteredData["cleaningContractWithOrak"]) {
    //     setHasOrakInsurance(SELECTION_OPTION.NON);
    //     setOtherInsuranceProvider(enteredData["cleaningContactWithoutOrak"]);
    //     setOrakInsuranceOption(null);
    //   } else {
    //     setHasOrakInsurance(SELECTION_OPTION.OUI);
    //     setOtherInsuranceProvider(null);
    //     if (enteredData["cleaningContractWithOrak"]) {
    //       setOrakInsuranceOption(enteredData["cleaningContractWithOrak"]);
    //     } else {
    //       setOrakInsuranceOption(CONTRACT_OPTIONS.CONFORT);
    //     }
    //   }
    // }
  }, []);
  return (
    <>
      <div className={styles.itemContainer}>
        <div className={styles.easeOfCleaningContainer}>
          <SelectionBlock
            text={SELECTION_OPTION.OUI}
            handleClick={() => setSelectedBlock(SELECTION_OPTION.OUI)}
            isSelected={selectedBlock === SELECTION_OPTION.OUI}
          />
          <SelectionBlock
            text={SELECTION_OPTION.NON}
            handleClick={() => setSelectedBlock(SELECTION_OPTION.NON)}
            isSelected={selectedBlock === SELECTION_OPTION.NON}
          />
        </div>
      </div>
      {selectedBlock && selectedBlock === "Oui" && (
        <>
          <ContractWithOrak
            handleHasOrakInsurance={handleHasOrakInsurance}
            selectedBlock={hasOrakInsurance}
          />
          {hasOrakInsurance === SELECTION_OPTION.OUI ? (
            <ContractOptions
              orakInsuranceOption={orakInsuranceOption}
              handleChange={setOrakInsuranceOption}
            />
          ) : (
            <SelectNoInput
              error={error.insuranceProvider}
              placeholder="Avec qui ?"
              onChange={handleInput}
              value={otherInsuranceProvider}
            />
          )}
        </>
      )}
      <HiddenButton btnRef={submitBtnRef} onClick={handleSubmit} />
    </>
  );
};
