export var fakeCards = [
  {
    id: 1,
    company: "BNP Paribas",
    fullName: "David Alimi",
    phone: "02 99 82 81 81",
    hoursStart: "08:00",
    hoursEnd: "08:30",
    address: "13 rue du Lorem ipsum 75001 Paris",
    cp: "dummy",
    ville: "Paris",
    building: "Immeuble B",
    stage: 9,
    digicode: "A1938",
    email: "contact@bnp.com",
    codePostal: "35400",
    numberOfClientSites: 2,
    codeClient: 12,
    siteName: "Dummy site 1",
    contactName: "lorem ipsum",
    notesToConsider: "blala",
  },
  {
    id: 2,
    company: "Qonto",
    fullName: "John Doe",
    phone: "06 82 97 82 52",
    hoursStart: "08:30",
    hoursEnd: "09:00",
    address: "24 rue du Lorem ipsum 35000 Rennes",
    cp: "dummy",
    ville: "Paris",
    building: "Immeuble C",
    stage: 5,
    digicode: "ZE589",
    email: "contact@qonto.com",
    codePostal: "35400",
    numberOfClientSites: 2,
    codeClient: 12,
    siteName: "Dummy site 1",
    contactName: "lorem ipsum",
    notesToConsider: "blala",
  },
  {
    id: 3,
    company: "La française des jeux",
    fullName: "Dupont Martin",
    phone: "01 75 85 85 81",
    hoursStart: "09:30",
    hoursEnd: "10:00",
    address: "25 rue du Lorem ipsum 75001 Paris",
    cp: "dummy",
    ville: "Paris",
    building: "Immeuble X",
    stage: 1,
    digicode: "FE1253",
    email: "contact@fdj.com",
    codePostal: "35400",
    numberOfClientSites: 2,
    codeClient: 12,
    siteName: "Dummy site 1",
    contactName: "lorem ipsum",
    notesToConsider: "blala",
  },
  {
    id: 4,
    company: "BNP Paribas",
    fullName: "David Alimi",
    phone: "02 99 82 81 81",
    hoursStart: "08:00",
    hoursEnd: "08:30",
    address: "13 rue du Lorem ipsum 75001 Paris",
    cp: "dummy",
    ville: "Paris",
    building: "Immeuble B",
    stage: 9,
    digicode: "A1938",
    email: "contact@bnp.com",
    codePostal: "35400",
    numberOfClientSites: 2,
    codeClient: 12,
    siteName: "Dummy site 1",
    contactName: "lorem ipsum",
    notesToConsider: "blala",
  },
  {
    id: 6,
    company: "BNP Paribas",
    fullName: "David Alimi",
    phone: "02 99 82 81 81",
    hoursStart: "08:00",
    hoursEnd: "08:30",
    address: "13 rue du Lorem ipsum 75001 Paris",
    cp: "dummy",
    ville: "Paris",
    building: "Immeuble B",
    stage: 9,
    digicode: "A1938",
    email: "contact@bnp.com",
    numberOfClientSites: 2,
    codeClient: 12,
    siteName: "Dummy site 1",
    contactName: "lorem ipsum",
    notesToConsider: "blala",
  },
  {
    id: 7,
    company: "BNP Paribas",
    fullName: "David Alimi",
    phone: "02 99 82 81 81",
    hoursStart: "08:00",
    hoursEnd: "08:30",
    address: "13 rue du Lorem ipsum 75001 Paris",
    cp: "dummy",
    ville: "Paris",
    building: "Immeuble B",
    stage: 9,
    digicode: "A1938",
    email: "contact@bnp.com",
    codePostal: "35400",
    numberOfClientSites: 2,
    codeClient: 12,
    siteName: "Dummy site 1",
    contactName: "lorem ipsum",
    notesToConsider: "blala",
  },
  {
    id: 8,
    company: "BNP Paribas",
    fullName: "David Alimi",
    phone: "02 99 82 81 81",
    hoursStart: "08:00",
    hoursEnd: "08:30",
    address: "13 rue du Lorem ipsum 75001 Paris",
    cp: "dummy",
    ville: "Paris",
    building: "Immeuble B",
    stage: 9,
    digicode: "A1938",
    email: "contact@bnp.com",
    codePostal: "35400",
    numberOfClientSites: 2,
    codeClient: 12,
    siteName: "Dummy site 1",
    contactName: "lorem ipsum",
    notesToConsider: "blala",
  },
  {
    id: 9,
    company: "BNP Paribas",
    fullName: "David Alimi",
    phone: "02 99 82 81 81",
    hoursStart: "08:00",
    hoursEnd: "08:30",
    address: "13 rue du Lorem ipsum 75001 Paris",
    cp: "dummy",
    ville: "Paris",
    building: "Immeuble B",
    stage: 9,
    digicode: "A1938",
    email: "contact@bnp.com",
    codePostal: "35400",
    numberOfClientSites: 2,
    codeClient: 12,
    siteName: "Dummy site 1",
    contactName: "lorem ipsum",
    notesToConsider: "blala",
  },
];

// const a = {
//       completionData: {
//         fields: [
//           {
//             attributes: { "xsi:type": "ns2:reportField" },
//             id: "SURAFACE",
//             value: "",
//           },
//           { attributes: { "xsi:type": "ns2:reportField" }, id: "PRESTA_REAL" },
//           {
//             attributes: { "xsi:type": "ns2:tableSection" },
//             id: "PHOTO_AV_PREST",
//           },
//           { attributes: { "xsi:type": "ns2:tableSection" }, id: "photo_mob" },
//           { attributes: { "xsi:type": "ns2:tableSection" }, id: "BOLON" },
//           { attributes: { "xsi:type": "ns2:reportField" }, id: "PRESTA_SUPP" },
//           {
//             attributes: { "xsi:type": "ns2:reportField" },
//             id: "SECOND_PASS_A_PROG",
//           },
//         ],
//         lifecycleTransitionDates: [
//           { date: "2022-06-14T10:10:53.408Z", name: "pdaLoadingDate" },
//           { date: "2022-06-14T09:25:34.54Z", name: "communicationDate" },
//           { date: "2022-06-14T10:10:53.408Z", name: "lastModificationDate" },
//         ],
//       },
//       contractData: null,
//       coreData: {
//         anomaly: false,
//         contacts: [
//           {
//             coordinates: "01 30 18 23 76",
//             flags: 0,
//             label: "site",
//             type: "PHONE",
//           },
//           {
//             coordinates: "H5418-HK@accor.com",
//             flags: 0,
//             label: "site",
//             type: "EMAIL",
//           },
//           {
//             coordinates: "H5418-RD@accor.com",
//             flags: 0,
//             label: "site",
//             type: "EMAIL",
//           },
//           {
//             coordinates: "daniel.morisset@accor.com",
//             flags: 0,
//             label: "site",
//             type: "EMAIL",
//           },
//           {
//             coordinates: "01 34 29 95 60",
//             flags: 0,
//             label: "site",
//             type: "FAX",
//           },
//         ],
//         creationDate: "2022-06-14T09:25:20.711Z",
//         description:
//           "Nettoyage des moquettes de 30 chambres et d'une circulation",
//         priority: 256,
//         referentialData: {
//           attributes: { "xsi:type": "ns2:externalReferentialData" },
//           customerName: "NOVOTEL ROISSY - SIM SAS",
//           location: {
//             address: "ALLEE DES VERGERS",
//             city: "ROISSY EN FRANCE",
//             contact: "Jérôme SANCHEZ",
//             description:
//               "Nettoyage de 30 chambres et d'1 circulations.\r\n(1 circulation = 10 chambres)\r\n(1 circulation = Espace accueil et jeux d'enfants)\r\n(1 circulation = Salon équivalent en surface)\r\n(1 circulation = 400m²)",
//             geolocation: {
//               latitude: 49.001869201660156,
//               longitude: 2.5196099281311035,
//             },
//             name: "* NOVOTEL ROISSY",
//             zipCode: "95700",
//           },
//         },
//       },
//       extensions: [
//         {
//           key: "businessEvent.extension.uuid",
//           value: "da97c808-6898-4f01-a219-bec4cece8c8d",
//         },
//       ],
//       id: "21590",
//       qualificationData: { type: { duration: 300 } },
//       schedulingData: {
//         agentId: {
//           attributes: { "xsi:type": "ns2:externalEntityId" },
//           id: "411JASON",
//         },
//         schedulingDate: "2022-07-04T07:30:00Z",
//         schedulingEndDate: "2022-07-04T12:30:00Z",
//         useSchedulingHour: true,
//       },
//       status: "IN_PROGRESS",
//     }
