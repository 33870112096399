import React, { useState } from "react";

import Header from "src/components/header/Header";
import styles from "src/components/CreateClient/CreateClient.module.css";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { CreateClientForm } from "./CreateClientForm";
import { useContext, useEffect } from "react";
import { AppContext } from "src/Contexts/AppContext";
import { useNavigate } from "react-router-dom";

export const CreateClient = ({ identification, edit }) => {
  const navigate = useNavigate();

  const {
    state: { createClientSuccess, createdClientId, error },
    createAccount,
    updateAccount,
  } = useContext(AppContext);

  const [currentDataClient, setCurrentDataClient] = useState();
  useEffect(() => {
    if (
      createdClientId &&
      (error == null || error == false || !error) &&
      identification == true &&
      edit == false
    ) {
      // navigate(`/creation-identification/${createdClientId}`, {
      //   state: { infos: currentDataClient, createdClientId: createdClientId },
      // });
      navigate(`/same-address`, {
        state: { infos: currentDataClient, createdClientId: createdClientId },
      });
    } else if (
      createdClientId &&
      (error == null || error == false || !error) &&
      identification === false
    ) {
      navigate(`/client/${createdClientId}`);
    }
  }, [createClientSuccess, createdClientId, error, identification]);

  const formErrorText = error ? error : "";

  const handleCreateAccount = (data) => {
    setCurrentDataClient(data);
    createAccount(data);
  };
  const handleUpdateAccount = (data) => updateAccount(data);

  return (
    <>
      <Header screenTarget={-1} icon={faChevronLeft}>
        {edit ? "Modifier fiche client" : "Création fiche client"}
      </Header>
      <div className="container">
        {/* <div className={styles.subContainer}> */}
        <CreateClientForm
          handleCreateAccount={handleCreateAccount}
          handleUpdateAccount={handleUpdateAccount}
          error={error && formErrorText}
          edit={edit}
        />
        {/* </div> */}
      </div>
    </>
  );
};
