import { useEffect, useState } from "react";
import { CONTRACT_OPTIONS } from "./constants";
import styles from "./VenueInformation.module.css";
import starFilled from "src/assets/icons/star-filled.svg";

const SelectionBlock = ({ text, isSelected, handleClick }) => {
  return (
    <div
      className={`${styles.contractOptionBlock} ${
        isSelected ? styles.selected : ""
      }`}
      onClick={handleClick}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div style={{ display: "flex" }}>
          <p style={{ fontWeight: "500" }}>{text}</p>
          <span style={{ marginLeft: "1rem" }}>5</span>
          <img
            height="14px"
            style={{ margin: "3px .5rem" }}
            src={starFilled}
            alt="rating"
          />
        </div>
        <div style={{ display: "flex" }}>
          <p style={{ fontWeight: "500", color: "#259f64" }}>3,6g</p>
          <p style={{ fontSize: ".75rem", margin: "5px 1rem" }}>CO2eq/m2/an</p>
        </div>
      </div>
    </div>
  );
};

export const ContractOptions = ({ orakInsuranceOption, handleChange }) => {
  const [selectedBlock, setSelectedBlock] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div className={styles.contractOptionContainer}>
        <SelectionBlock
          text={CONTRACT_OPTIONS.ONESHOT}
          handleClick={() => handleChange(CONTRACT_OPTIONS.ONESHOT)}
          isSelected={orakInsuranceOption === CONTRACT_OPTIONS.ONESHOT}
        />
        <SelectionBlock
          text={CONTRACT_OPTIONS.CONFORT}
          handleClick={() => handleChange(CONTRACT_OPTIONS.CONFORT)}
          isSelected={orakInsuranceOption === CONTRACT_OPTIONS.CONFORT}
        />
      </div>
      <div className={styles.contractOptionContainer}>
        <SelectionBlock
          text={CONTRACT_OPTIONS.ZEN}
          handleClick={() => handleChange(CONTRACT_OPTIONS.ZEN)}
          isSelected={orakInsuranceOption === CONTRACT_OPTIONS.ZEN}
        />
        <SelectionBlock
          text={CONTRACT_OPTIONS.PREMIUM}
          handleClick={() => handleChange(CONTRACT_OPTIONS.PREMIUM)}
          isSelected={orakInsuranceOption === CONTRACT_OPTIONS.PREMIUM}
        />
      </div>
    </div>
  );
};
