import React, { useCallback, useEffect, useRef } from "react";
import styles from "src/components/form/Form.module.css";
import Button from "src/components/button/Button";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { appointmentDetailsFormSchema } from "./validationSchemas";
import { useNavigate, useParams } from "react-router-dom";

export default function Form({ formData }) {
  const submitButtonRef = useRef();

  const navigate = useNavigate();
  const { id } = useParams();

  const submitForm = () => {
    submitButtonRef.current?.click();
    // navigate(`/creation-identification/${id}`);
    navigate(`/customer-database/`);
  };

  const { handleSubmit, register, setValue } = useForm({
    resolver: yupResolver(appointmentDetailsFormSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    console.log(data);
  };

  useEffect(() => {
    if (formData) {
      Object.keys(formData).forEach((key) => setValue(key, formData[key]));
    }
  }, []);

  return (
    <>
      <div className={styles.card}>
        <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
          <input
            type="text"
            {...register("fullName")}
            name="fullName"
            placeholder="Nom du client"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="text"
            {...register("numberOfClientSites")}
            name="numberOfClientSites"
            placeholder="Nombre de sites client"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="text"
            {...register("codeClient")}
            name="codeClient"
            placeholder="Code client"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="text"
            {...register("siteName")}
            name="siteName"
            placeholder="Nom du site"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="text"
            {...register("address")}
            name="address"
            placeholder="Adresse"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="text"
            {...register("cp")}
            name="cp"
            placeholder="CP"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="text"
            {...register("ville")}
            name="ville"
            placeholder="Ville"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="number"
            {...register("codePostal")}
            name="codePostal"
            placeholder="Code postal"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <p className={styles.subTitle}>Coordonnées du contact en interne</p>
          <input
            type="text"
            {...register("contactName")}
            name="contactName"
            placeholder="Nom du contact"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="number"
            {...register("phone")}
            name="phone"
            placeholder="Numéro de téléphone"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <input
            type="email"
            {...register("email")}
            name="email"
            placeholder="Adresse e-mail"
            className="main-input"
            style={{ marginBottom: "42px" }}
            disabled
          />
          <p className={styles.subTitle}>Infos site</p>
          <input
            type="text"
            {...register("notesToConsider")}
            name="notesToConsider"
            placeholder="Remarques à prendre en compte"
            className="main-input"
            style={{ marginBottom: "28px" }}
            disabled
          />
          <button style={{ display: "none" }} ref={submitButtonRef}></button>
        </form>
      </div>
      <div style={{ width: "80%", margin: "0 auto" }}>
        <Button onClick={submitForm} variant="primary">
          Commencer l'identification
        </Button>
      </div>
    </>
  );
}
