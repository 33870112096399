import styles from "src/components/VenueInformation/VenueInformation.module.css";

export const PageHeader = ({ heading, subHeading, icon }) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <div className={styles.iconContainer}>
      <img src={icon} alt="info-lieu-icon" />
    </div>
    <div style={{ marginLeft: "1.5rem" }}>
      <h2>{heading}</h2>
      <p style={{ fontWeight: "200" }}>{subHeading}</p>
    </div>
  </div>
);
