import styles from "./VenueInformation.module.css";

export const ProgressBar = ({ currentValue, maxValue }) => {
  return (
    <progress
      className={styles.progressBar}
      max={maxValue}
      value={currentValue}
    />
  );
};
