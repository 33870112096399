import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "src/pages/listing-products/ListingProducts.module.css";

export default function ColorContainer({
  setFilter,
  getFilter,
  values,
  handleChooseColor,
  colorChoosen,
}) {
  return (
    <div className={styles.filterSubContainer}>
      <div
        className={styles.filterHeader}
        onClick={() => setFilter(!getFilter)}
      >
        <div>Couleurs</div>
        <div>
          <FontAwesomeIcon icon={getFilter ? faXmark : faChevronDown} />
        </div>
      </div>
      {getFilter ? (
        <div>
          <div className={styles.colorContainer}>
            {values &&
              values.map((color, index) => {
                return (
                  <div
                    key={index}
                    className={
                      colorChoosen == color.id
                        ? styles.colorButtonActive
                        : styles.colorButton
                    }
                    style={{ backgroundColor: color.color }}
                    onClick={() => handleChooseColor(color.id)}
                  ></div>
                );
              })}
          </div>
        </div>
      ) : null}
    </div>
  );
}
