import { useNavigate } from "react-router-dom";
import { withAuth } from "src/components/HOCs/withAuth";
import userIcon from "src/assets/icons/user-white.svg";
import { ConfirmationScreen } from "src/components/ConfirmationScreen/ConfirmationScreen";

function MakeAnIdentification() {
  let navigate = useNavigate();
  return (
    <ConfirmationScreen
      screenName="Faire une identification"
      mainHeading="Le prospect est-il déjà client ?"
      icon={userIcon}
      onClickYes={() => navigate("/customer-database")}
      onClickNo={() => navigate("/create-client")}
    />
  );
}

export default withAuth(MakeAnIdentification);
