import React, { useContext, useEffect, useState } from "react";
import logo from "src/assets/logo_white.svg";
import { useSearchParams } from "react-router-dom";
import Button from "src/components/button/Button";
import identification from "src/assets/icons/new-identification.svg";
import bdd from "src/assets/icons/bdd-clients.svg";
import newIdentification from "src/assets/icons/identification.svg";
import calendar from "src/assets/icons/calendar.svg";
import contactInterne from "src/assets/icons/user.svg";
import search from "src/assets/icons/search.svg";
import { useNavigate } from "react-router-dom";
import { JWT_KEY } from "src/utils/constants";
import maison from "src/assets/icons/maison.svg";
import user from "src/assets/icons/user.svg";
import TracabiliteIcon from "src/assets/icons/Tracabilite-icon.svg";

import styles from "src/pages/home/Home.module.css";
import { AppContext } from "src/Contexts/AppContext";
import { withAuth } from "src/components/HOCs/withAuth";
import { ROLES } from "src/utils/constants";
import { getUserRole, getId } from "src/utils/functions";

function Home() {
  const { resetInfosIdentificationsCreated } = useContext(AppContext);

  let navigate = useNavigate();

  const [role, setRole] = useState(null);
  const [currentUserId, setCurrentUserId] = useState(null);

  const handleReceiveCode = (target) => {
    // console.log("target", target);
  };

  const handleRedirect = (target) => {
    navigate(`${target}`, { replace: true });
  };
  const logout = () => {
    localStorage.removeItem(JWT_KEY);
    navigate(`/login`);
  };

  useEffect(() => {
    const userRole = getUserRole();
    setRole(userRole);
    const id = getId();
    setCurrentUserId(id);
    resetInfosIdentificationsCreated();
    localStorage.removeItem("orakFiltersColor");
    localStorage.removeItem("orakFiltersMinQuantity");
    localStorage.removeItem("orakFiltersMaxQuantity");
    localStorage.removeItem("orakFiltersBrand");
    localStorage.removeItem("orakFiltersLiberation");
    localStorage.removeItem("orakFiltersFabrication");
    localStorage.removeItem("orakFiltersType");
  }, []);

  return (
    <div className={styles.container}>
      <img src={logo} alt="logo" className={styles.mainLogo} />
      <h1 className={styles.title}>Bienvenue sur l'outil</h1>
      <h1 className={styles.subTitle}>
        {role == "customer"
          ? "client Orak"
          : role == "Sales"
          ? "commercial Orak"
          : role == "admin"
          ? "Admin Orak"
          : "opérateur Orak"}
      </h1>
      <div className={styles.container__buttons}>
        {role === ROLES.OPERATOR || role === ROLES.ADMIN ? (
          <>
            <Button
              variant="secondary"
              icon={calendar}
              onClick={() => handleRedirect("/calendrier")}
            >
              Calendrier
            </Button>
          </>
        ) : null}

        {role === ROLES.COMMERCIAL || role === ROLES.ADMIN ? (
          <Button
            variant="secondary"
            icon={identification}
            onClick={() => handleRedirect("/my-identifications")}
          >
            Mes identifications
          </Button>
        ) : null}

        {role === ROLES.COMMERCIAL ||
        role === ROLES.OPERATOR ||
        role === ROLES.ADMIN ? (
          <Button
            variant="secondary"
            icon={newIdentification}
            onClick={() => handleRedirect("/nouvelle-identification")}
          >
            Nouvelle identification
          </Button>
        ) : null}

        {/* {role === ROLES.OPERATOR ? (
          <Button
            variant="secondary"
            icon={contactInterne}
            onClick={() => handleReceiveCode("/login")}
          >
            Editer mon profil
          </Button>
        ) : null} */}

        {role === ROLES.COMMERCIAL || role === ROLES.ADMIN ? (
          <>
            <Button
              variant="secondary"
              icon={bdd}
              onClick={() => handleRedirect("/customer-database-home")}
            >
              Base de données clients
            </Button>

            <Button
              variant="secondary"
              icon={TracabiliteIcon}
              onClick={() => handleRedirect(`/listing-products`)}
            >
              Rechercher une référence
            </Button>
          </>
        ) : null}
      </div>

      {role === ROLES.CUSTOMER ? (
        <>
          <Button
            variant="secondary"
            icon={maison}
            onClick={() => handleRedirect(`/client-detail/${currentUserId}/0`)}
          >
            Tableau de bord
          </Button>
          {/* <Button variant="secondary" icon={user}>
            Modifier mon profil
          </Button> */}
        </>
      ) : null}

      <Button variant="tertiary" onClick={logout}>
        Déconnexion
      </Button>
      <p className={styles.copyright}>Copyright Orak 2022</p>
    </div>
  );
}

export default withAuth(Home);
