export function createActions(actionType) {
  return [
    `request ${actionType}`,
    `success ${actionType}`,
    `fail ${actionType}`,
  ];
}

export const REQUEST = 0;
export const SUCCESS = 1;
export const FAIL = 2;
