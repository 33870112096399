import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppContext } from "src/Contexts/AppContext";
import { JWT_KEY } from "src/utils/constants";

export const withAuth = (WrappedComponent) => (props) => {
  const {
    state: { tokenVerified },
  } = useContext(AppContext);

  const navigate = useNavigate();
  const [queryParams] = useSearchParams();
  const token = queryParams.get("token");

  const DEBUG = eval(process.env.REACT_APP_DEBUG);

  const accessToken = localStorage.getItem(JWT_KEY);
  var isNoRole = false;
  if (process.env.REACT_APP_NOROLE == "true") {
    isNoRole = true;
  }

  // useEffect(() => {
  //   if (isNoRole == false) {
  //     if (!accessToken) {
  //       navigate("/login", { replace: true });
  //     }
  //   }
  // }, [tokenVerified]);

  if (isNoRole == false) {
    if (accessToken) {
      return <WrappedComponent {...props} />;
    }
  } else {
    return <WrappedComponent {...props} />;
  }

  return null;
};
