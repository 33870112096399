import styles from "./Modal.module.css";
import { createPortal } from "react-dom";
import crossIcon from "src/assets/icons/cross-black.svg";

export const Modal = ({ isOpen, onClose, children, removeCross = false }) => {
  const domNode = document.getElementById("root");

  return (
    <>
      {isOpen &&
        domNode &&
        createPortal(
          <aside role="none" className={styles.modal}>
            <div className={styles.container}>
              <div className={styles.modalBody}>
                {removeCross == false ? (
                  <div style={{ position: "relative" }}>
                    <img
                      style={{
                        position: "absolute",
                        right: "1rem",
                        cursor: "pointer",
                      }}
                      src={crossIcon}
                      alt="close-modal"
                      onClick={onClose}
                    />
                  </div>
                ) : null}
                <div style={{ marginTop: removeCross == false ? "2rem" : "" }}>
                  {children}
                </div>
              </div>
            </div>
          </aside>,
          domNode
        )}
    </>
  );
};
