import http from "src/services/httpService";
import { JWT_KEY } from "src/utils/constants";
import { getJWTFromLocalStorage } from "src/utils/functions";

const authFolder = "Auth";
const userFolder = "Users";
const ordersFolder = "Orders";
const invoiceFolder = "Invoices";
const identificationFolder = "Identifications";

export const getLoginLink = (email) => {
  const apiEndpoint = `${process.env.REACT_APP_API_URL}/${authFolder}/login`;
  return http.post(apiEndpoint, {
    email,
    isWebApp: true,
    hostEnv: window.location.host,
  });
};

export const checkLoginToken = (token) => {
  const url = `${process.env.REACT_APP_API_URL}/${authFolder}/checkLoginToken?token=${token}`;
  return http.get(url);
};

export async function getCommercialsList() {
  const url = `${process.env.REACT_APP_API_URL}/${userFolder}/getAllCommercials`;
  const jwt = getJWTFromLocalStorage();
  var response = await http.post(url, {
    jwt,
  });
  return response.data.payload.data;
}

export const createAccount = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/${authFolder}/createAccount`;
  return http.post(url, data);
};
export const updateAccount = async (data) => {
  const url = `${process.env.REACT_APP_API_URL}/${userFolder}/updateClient`;
  const jwt = getJWTFromLocalStorage();
  data = [{ ...data, jwt }];
  return http.post(url, data[0]);
};

export const getClients = async (offset) => {
  const url = `${process.env.REACT_APP_API_URL}/${userFolder}/getClients`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt, offset });
};

export const getClientById = async (id) => {
  const url = `${process.env.REACT_APP_API_URL}/${userFolder}/getClientById`;
  const jwt = getJWTFromLocalStorage();
  const response = await http.post(url, { id, jwt });
  return response;
};

export const searchClients = async (companyName) => {
  const url = `${process.env.REACT_APP_API_URL}/${userFolder}/searchClient`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt, companyName });
};

export const createIdentification = (payload) => {
  const url = `${process.env.REACT_APP_API_URL}/Identifications/create`;
  const jwt = getJWTFromLocalStorage();
  console.log("====================================");
  console.log("payload", payload);
  console.log("====================================");
  return http.post(url, { jwt, ...payload });
};

export const archiveIdentification = (identificationId) => {
  const url = `${process.env.REACT_APP_API_URL}/Identifications/archive`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt, identificationId });
};

export const updateIdentification = (
  payload,
  identificationId,
  curImagesID
) => {
  const url = `${process.env.REACT_APP_API_URL}/Identifications/update`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt, ...payload, identificationId, curImagesID });
};

export const updateIdentificationSameSale = (payload, identificationId) => {
  const url = `${process.env.REACT_APP_API_URL}/Identifications/updateSameSale`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt, ...payload, identificationId });
};

export const addImagesToIdentification = (identificationID, files, isEdit) => {
  const url = `${process.env.REACT_APP_API_URL}/Files/addFileToIdentification`;
  const jwt = getJWTFromLocalStorage();
  const payloadFormData = new FormData();
  payloadFormData.append("jwt", jwt);
  payloadFormData.append("identificationID", identificationID);
  payloadFormData.append("files", files);
  payloadFormData.append("isEdit", isEdit);
  return http.post(url, payloadFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const addDocumentationFileToIdentification = (
  identificationID,
  files,
  column
) => {
  const url = `${process.env.REACT_APP_API_URL}/Files/addDocumentationFileToIdentification`;
  const jwt = getJWTFromLocalStorage();
  const payloadFormData = new FormData();
  payloadFormData.append("jwt", jwt);
  payloadFormData.append("identificationID", identificationID);
  payloadFormData.append("files", files);
  payloadFormData.append("column", column);
  return http.post(url, payloadFormData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

export const getIdentifications = () => {
  const url = `${process.env.REACT_APP_API_URL}/Identifications/fetchAllIdentifications`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, {
    jwt,
  });
};

export const getIdentification = (identificationID) => {
  const url = `${process.env.REACT_APP_API_URL}/Identifications/getIdentification`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, {
    jwt,
    identificationID: identificationID,
  });
};

export const getCalendarEvents = (startDate) => {
  const url = `${process.env.REACT_APP_API_URL}/Praxedo/GetEvents`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, {
    jwt,
    startDate,
    endDate: startDate,
    nbItems: 20,
  });
};

export const getPicture = (filePath, IdentificationsID) => {
  // const url = `${process.env.REACT_APP_API_URL}/Files/DownloadSecureFile`;
  // const jwt = getJWTFromLocalStorage();
  return `https://orak-data.s3.eu-west-3.amazonaws.com/${filePath}`;

  // With presign-url :
  // return http.post(url, {
  //   jwt,
  //   identificationID: IdentificationsID,
  //   filePath: filePath,
  // });
};
export const getAccountBills = (page, type) => {
  const url = `${process.env.REACT_APP_API_URL}/${ordersFolder}/getAccountBills`;
  const jwt = getJWTFromLocalStorage();
  // const jwt =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTksInJvbGUiOiJTYWxlcyIsImVtYWlsIjoiZGF2aWRAZWxlc3QuaW8iLCJmaXJzdE5hbWUiOiJEYXZpZCIsImxhc3ROYW1lIjoiQWxpbWkiLCJpc0FkbWluIjoxLCJjb2RlQ2xpZW50IjoiNDExQ0xFQU5ZIiwiaWF0IjoxNjYxMDY4MzMxLCJleHAiOjE2NjM2NjAzMzF9.Enp53y06TvXEt99PD13tC-hBlTTxgF7pzU8ODBbUvqg";
  return http.post(url, { jwt: jwt, page: page, type: type });
};

export const getAddressIndentificationByClientId = (clientId) => {
  const url = `${process.env.REACT_APP_API_URL}/${identificationFolder}/getAddressIndentificationByClientId`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt, clientId });
};

export const getIdentificationsByAddressId = (addressId, idClient) => {
  const url = `${process.env.REACT_APP_API_URL}/${identificationFolder}/getIdentificationsByAddressId`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt, addressId, idClient });
};

export const getAllDropdown = () => {
  const url = `${process.env.REACT_APP_API_URL}/Products/getAllDropdown`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt });
};

export const getServicesCompanies = () => {
  const url = `${process.env.REACT_APP_API_URL}/Products/getServicesCompanies`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt });
};

export const getProductsList = () => {
  const url = `${process.env.REACT_APP_API_URL}/Products/getProductsList`;
  const jwt = getJWTFromLocalStorage();
  return http.post(url, { jwt });
};

export const createNewProduct = (
  form,
  curImagesID,
  mainPhoto,
  additionalImage
) => {
  const jwt = getJWTFromLocalStorage();
  const url = `${process.env.REACT_APP_API_URL}/Products/createNewProduct`;
  return http.post(url, {
    jwt,
    form,
    curImagesID,
    mainPhoto,
    additionalImage,
  });
};

export const removeFileToIdentification = (id) => {
  const jwt = getJWTFromLocalStorage();
  const url = `${process.env.REACT_APP_API_URL}/Files/removeFileToIdentification`;
  return http.post(url, { jwt, id });
};

export const sendExpertiseDemande = (message, subject) => {
  const jwt = getJWTFromLocalStorage();
  const url = `${process.env.REACT_APP_API_URL}/Profile/sendExpertiseDemande`;
  return http.post(url, { jwt, message, subject });
};

export const getAllProducts = () => {
  const url = `${process.env.REACT_APP_API_URL}/Products/listing`;
  return http.get(url);
};

export const getAllProductsFilter = () => {
  const url = `${process.env.REACT_APP_API_URL}/References/listingFilters`;
  return http.get(url);
};

export const getInfosReference = (typeRef, referenceID) => {
  const jwt = getJWTFromLocalStorage();
  const url = `${process.env.REACT_APP_API_URL}/References/getInfos`;
  return http.post(url, { jwt, typeRef, referenceID });
};

export const sendingRequestProcess = (identificationID) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const jwt = getJWTFromLocalStorage();
  const url = `${process.env.REACT_APP_API_URL}/Identifications/sendingRequestProcess`;
  return http.post(url, { jwt, identificationID, baseUrl });
};

export const startingProcess = (identificationID) => {
  const jwt = getJWTFromLocalStorage();
  const url = `${process.env.REACT_APP_API_URL}/Identifications/startingProcess`;
  return http.post(url, { jwt, identificationID });
};

export const getProductInfos = (productID) => {
  const url = `${process.env.REACT_APP_API_URL}/References/getInfosProduct?productID=${productID}`;
  return http.get(url);
};

export const defineDates = (
  type,
  dateDebut,
  dateFin,
  emailEntreprise,
  text,
  identificationID,
  societe
) => {
  const jwt = getJWTFromLocalStorage();
  const url = `${process.env.REACT_APP_API_URL}/Products/defineDates`;
  return http.post(url, {
    jwt,
    type,
    dateDebut,
    dateFin,
    emailEntreprise,
    text,
    identificationID,
    societe,
  });
};
