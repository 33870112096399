import { useContext, useEffect, useState } from "react";
import styles from "src/components/VenueInformation/VenueInformation.module.css";
import { AppContext } from "src/Contexts/AppContext";
import { VENUE_INFO_ERRORS } from "src/utils/constants";
import { HiddenButton } from "./VenueInformation";

export const Surface = ({
  submitBtnRef,
  handleData,
  handleSetError,
  error,
  setScreenDataValid,
  enteredData = null,
}) => {
  const {
    state: { selectedIdentification },
  } = useContext(AppContext);

  useEffect(() => {
    if (selectedIdentification) {
      setCarpetArea(selectedIdentification.surface_m2);
      setSurfaceReview(selectedIdentification.surfaceReview);
    }
  }, [selectedIdentification]);

  const [carpetArea, setCarpetArea] = useState(null);
  const [surfaceReview, setSurfaceReview] = useState("");

  const handleInputChange = (e) => setCarpetArea(e.target.value);

  const handleSubmit = () => {
    if (carpetArea && surfaceReview != "") {
      setScreenDataValid(true);
      handleData("surface", carpetArea);
      handleData("surfaceReview", surfaceReview);
      handleSetError({ surface: null });
    } else if (surfaceReview == "") {
      handleSetError({ surface: VENUE_INFO_ERRORS.surfaceReview });
    } else {
      handleSetError({ surface: VENUE_INFO_ERRORS.emptySurfaceArea });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (enteredData["surface"]) {
      setCarpetArea(enteredData["surface"]);
      setSurfaceReview(enteredData["surfaceReview"]);
    }
  }, []);

  return (
    <div style={{ marginTop: "1rem" }} className={styles.itemContainer}>
      <div className={styles.surfaceContainer}>
        <p className={styles.surfaceHeading}>Quelle est la surface en m2</p>
        <div className={styles.inputContainer}>
          <input
            defaultValue={carpetArea}
            id="surface-input"
            placeholder="XX"
            type="number"
            onChange={handleInputChange}
            className={styles.surfaceInput}
            autoFocus={
              selectedIdentification
                ? false
                : enteredData["surface"]
                ? false
                : true
            }
          />
          <label className={styles.inputLabel} htmlFor="surface-input">
            m2
          </label>
        </div>
        <div className={styles.inputContainer}>
          <select
            className={styles.surfaceSelect}
            value={surfaceReview}
            onChange={(e) => setSurfaceReview(e.target.value)}
          >
            <option value="">Veuillez choisir la source de l'estimation</option>
            <option value="oeil">Estimation à l’œil</option>
            <option value="telemetre">Vérification par télémètre</option>
          </select>
        </div>
        {error && <span className={styles.formError}>{error?.surface}</span>}
        <HiddenButton btnRef={submitBtnRef} onClick={handleSubmit} />
      </div>
    </div>
  );
};
