import { Notyf } from "notyf";
import "notyf/notyf.min.css";
import "src/utils/Toast.css";

const toast = new Notyf({
  duration: 1000,
  position: {
    x: "center",
    y: "bottom",
  },
  types: [
    {
      type: "unexpectedError",
      message: "An unexpected error occured",
      duration: 4000,
      dismissible: true,
      className: "toast",
      background: "indianred",
    },
  ],
});

export default toast;
