import * as yup from "yup";

export const appointmentDetailsFormSchema = yup.object().shape({
  fullName: yup.string().trim().required(),
  numberOfClientSites: yup.string().trim().required(),
  codeClient: yup.string().trim().required(),
  siteName: yup.string().trim().required(),
  address: yup.string().trim().required(),
  cp: yup.string().trim().required(),
  ville: yup.string().trim().required(),
  codePostal: yup.string().trim().required(),
  contactName: yup.string().trim().required(),
  phone: yup.string().trim().required(),
  email: yup.string().trim().required(),
  notesToConsider: yup.string().trim(),
});
