import Header from "src/components/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { withAuth } from "src/components/HOCs/withAuth";
import { Loading } from "src/components/Loading/Loading";

import styles from "src/components/VenueInformation/VenueInformation.module.css";
import * as API from "src/services/apiService";
import { PageHeader } from "./PageHeader";
import Button from "../button/Button";
import { useContext, useEffect, useRef, useState } from "react";
import { STEPS, SCREEN_HEADINGS, TOP_BANNER_SCREENS } from "./constants";
import { InfoFooter } from "./InfoFooter";
import { IdentificationComplete } from "./IdentificationComplete";
import { ProgressBar } from "./ProgressBar";
import { AppContext } from "src/Contexts/AppContext";
import { parseUpdateSameSaleIdentificationData } from "src/utils/functions";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CarpetInformation } from "./CarpetInformation";
import { CarpetEvaluation } from "./CarpetEvaluation";
import { DownloadableDocs } from "./DownloadableDocs";
import { CompanyBanner } from "./CompanyBanner";

export const HiddenButton = ({ btnRef, onClick, type = "button" }) => (
  <button
    ref={btnRef}
    onClick={onClick}
    style={{ display: "none" }}
    type={type}
  />
);

const VenueInformation = ({
  processSteps = STEPS,
  processHeading = "Faire une identification",
  identificationData,
}) => {
  const {
    state: { identificationCreated, selectedIdentification },
    updateIdentificationSameSale,
    setIdentification,
    setClient,
  } = useContext(AppContext);

  const { id: clientId, identificationId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [activeScreen, setActiveScreen] = useState("carpetInformation");
  const [finalStepText, setFinalStepText] = useState("");

  const [screenIndex, setScreenIndex] = useState(1);
  const [errors, setErrors] = useState({});
  const [currentScreenDataValid, setScreenDataValid] = useState(true);
  const [newIdentificationData, setNewIdendificationData] =
    useState(identificationData);
  const [checkNoCreationDate, setCheckNoCreationDate] = useState(false);
  const [statusReview, setStatusReview] = useState(-1);

  const isCommercialIdentification = location.pathname.includes("/commercial");
  const refs = {
    [processSteps.CARPET_INFORMATION]: useRef(null),
    [processSteps.CARPET_EVALUATION]: useRef(null),
    [processSteps.DOWNLOADABLE_DOCS]: useRef(null),
  };
  const [enteredData, setEnteredData] = useState({ clientId });

  const handleSetError = (error) => {
    setErrors((prevState) => ({ ...prevState, ...error }));
  };

  const getScreenIndex = (goBack = false) => {
    let nextScreenIndex;
    const currentIndex = Object.values(processSteps).indexOf(activeScreen);

    if (goBack) {
      if (currentIndex !== 0) {
        nextScreenIndex = Object.values(processSteps)[currentIndex - 1];
        setActiveScreen(nextScreenIndex);
        setScreenIndex(
          Object.values(processSteps).indexOf(nextScreenIndex) - 1
        );
      }
    } else {
      nextScreenIndex = Object.values(processSteps)[currentIndex + 1];
      setActiveScreen(nextScreenIndex);
      setScreenIndex(Object.values(processSteps).indexOf(nextScreenIndex) + 1);
    }
  };

  const handleContinueBtn = () => {
    refs[activeScreen]?.current?.click();
  };

  const finishReview = (statusProcess) => {
    setStatusReview(statusProcess);
    handleContinueBtn();
  };

  useEffect(() => {
    if (checkNoCreationDate == true) {
      getScreenIndex(true);
      setScreenDataValid(true);
      var prevIndex = Object.values(processSteps).indexOf(activeScreen) + 1;
      var prevScreenName = Object.values(processSteps)[prevIndex];
      setActiveScreen(prevScreenName);
    }
  }, [checkNoCreationDate]);

  const handleBackBtn = () => {
    const currentIndex = Object.values(processSteps).indexOf(activeScreen);
    if (currentIndex === 0) {
      navigate(-1);
    } else {
      if (checkNoCreationDate == true) {
        var prevIndex = Object.values(processSteps).indexOf(activeScreen) - 2;
      } else {
        var prevIndex = Object.values(processSteps).indexOf(activeScreen) - 1;
      }
      getScreenIndex(true);
      setScreenDataValid(true);
      var prevScreenName = Object.values(processSteps)[prevIndex];
      setActiveScreen(prevScreenName);
    }
  };

  const handleData = (key, value, screenValid = false) => {
    setEnteredData((prevState) => ({ ...prevState, [key]: value }));
    if (currentScreenDataValid || screenValid) {
      getScreenIndex();
    }
  };

  useEffect(() => {
    if (statusReview != -1) {
      handleUpdateIdentification();
    }
  }, [statusReview]);

  const handleUpdateIdentification = async (data) => {
    var status = "";
    var isDraft = false;
    if (statusReview == 1) {
      isDraft = true;
    }
    if (statusReview == 1) {
      status = "toStudy";
    } else if (statusReview == 2) {
      status = "pending";
    } else if (statusReview == 3) {
      status = "inProgress";
    }
    data = {
      ...enteredData,
      ...data,
      identificationID: selectedIdentification.id,
      isDraft,
      status,
    };
    const parsedData = parseUpdateSameSaleIdentificationData(data);
    const res = await updateIdentificationSameSale(
      parsedData,
      identificationId
    );

    if (statusReview == 3) {
      navigate(`/in-progress-identification/commercial/${identificationId}`, {
        state: { currentStatus: "inProgress" },
      });
    }
  };

  useEffect(() => {
    if (identificationCreated && activeScreen != "carpetInformation") {
      if (isCommercialIdentification == true && statusReview == 2) {
        setFinalStepText("Votre identification est terminée");
      } else if (isCommercialIdentification == true && statusReview == 1) {
        setFinalStepText("Votre identification a bien été sauvegardée");
      }
      setScreenDataValid(true);
      getScreenIndex();
    }
  }, [identificationCreated]);

  useEffect(() => {
    if (isCommercialIdentification) {
      async function getIdentification(identificationId) {
        try {
          var response = await API.getIdentification(identificationId);
          const {
            data: { payload },
          } = response;

          if (payload.length > 0) {
            setNewIdendificationData(payload[0]);
            setIdentification(payload[0]);
          }
        } catch (ex) {
          console.log("getIdentification failed", ex);
        }
      }
      getIdentification(identificationId);
    } else {
      setIdentification(null);
      setNewIdendificationData(true);
    }

    if (location.state != null) {
      setClient(location.state);
    }
    enteredData["surface"] = selectedIdentification.surface_m2;
  }, []);
  const renderComponent = () => {
    switch (activeScreen) {
      case processSteps.CARPET_INFORMATION:
        return (
          <CarpetInformation
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
            setCheckNoCreationDate={setCheckNoCreationDate}
          />
        );
      case processSteps.CARPET_EVALUATION:
        return (
          <CarpetEvaluation
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
          />
        );
      case processSteps.DOWNLOADABLE_DOCS:
        return (
          <DownloadableDocs
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            handleUpdateIdentification={handleUpdateIdentification}
            statusReview={statusReview}
          />
        );
      default:
        return <CarpetInformation />;
    }
  };

  return (
    <>
      <Header screenTarget={-1} icon={faChevronLeft}>
        {processHeading}
      </Header>
      <div className="container">
        {activeScreen === processSteps.FINAL_STEP ? (
          <div className={styles.subContainer}>
            <IdentificationComplete message={finalStepText} />
          </div>
        ) : (
          <div className="identification-steps">
            <ProgressBar
              currentValue={screenIndex}
              maxValue={Object.keys(processSteps).length}
            />
            {TOP_BANNER_SCREENS.includes(activeScreen) && (
              <CompanyBanner identificationData={newIdentificationData} />
            )}
            <div className={styles.subContainer}>
              <PageHeader
                heading={SCREEN_HEADINGS[activeScreen].heading}
                subHeading={SCREEN_HEADINGS[activeScreen].subHeading}
                icon={SCREEN_HEADINGS[activeScreen].icon}
              />
            </div>
            {newIdentificationData && renderComponent()}
            <div className={styles.footerContainer}>
              <div style={{ margin: "3rem 0" }}>
                <InfoFooter />
              </div>
              <div className="footerButtonsContainer">
                <div
                  className={styles.backIconContainer}
                  onClick={handleBackBtn}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>

                {activeScreen === processSteps.PRODUCT_PHOTOS &&
                // newIdentificationData) ||
                !selectedIdentification ? (
                  <Button
                    className={styles.footerCtaBtn}
                    variant="primary"
                    onClick={handleContinueBtn}
                  >
                    Finaliser l’identification
                  </Button>
                ) : activeScreen === processSteps.DOWNLOADABLE_DOCS ? (
                  <div className={styles.buttonsContainer}>
                    <div className={styles.btnEnd}>
                      <Button
                        className={styles.footerCtaBtn}
                        variant="primary"
                        onClick={() => finishReview(1)}
                      >
                        Sauvegarder et reprendre plus tard
                      </Button>
                    </div>
                    <div className={styles.btnEnd}>
                      <Button
                        className={styles.footerCtaBtn}
                        variant="primary"
                        onClick={() => finishReview(2)}
                      >
                        Finaliser l’identification
                      </Button>
                    </div>
                    <div className={styles.btnEnd}>
                      <Button
                        className={styles.footerCtaBtn}
                        variant="primary"
                        onClick={() => finishReview(3)}
                      >
                        Lancer le process de récuperation
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Button
                    className={styles.footerCtaBtn}
                    variant="primary"
                    onClick={handleContinueBtn}
                  >
                    Continuer
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuth(VenueInformation);
