import infoLieu from "src/assets/icons/info-lieu.svg";
import frequentationIcon from "src/assets/icons/frequentation_piece.svg";
import surfaceIcon from "src/assets/icons/Surface.svg";
import easeOfCleaningIcon from "src/assets/icons/facilite_nettoyage.svg";
import ratingsIcon from "src/assets/icons/rating.svg";
import easeOfRemovalIcon from "src/assets/icons/facilite_depose.svg";
import annualContractIcon from "src/assets/icons/contrat_annuel.svg";
import productPhotosIcon from "src/assets/icons/photo_produit.svg";
import orakIcon from "src/assets/icons/symbole_orak.svg";
import infoMoqueteeIcon from "src/assets/icons/info_moquette.svg";
import carpetEvaluationIcon from "src/assets/icons/prix_evaluation.svg";
import downloadIcon from "src/assets/icons/telecharger.svg";
import MachineIcon from "src/assets/icons/Machine-icon.svg";
import DeposeIcon from "src/assets/icons/Depose-icon.svg";

const COMMON_STEPS = {
  VENUE_INFORMATION: "venueInformation",
  ROOM_ATTENDANCE: "roomAttendance",
  SURFACE: "surface",
  EASE_OF_CLEANING: "easeOfCleaning",
  EASE_OF_REMOVAL: "easeOfRemoval",
  PRODUCT_RATING: "productRating",
  ANNUAL_CLEANING_CONTRACT: "annualCleaningContract",
  PRODUCT_PHOTOS: "productPhotos",
};

export const STEPS = {
  ...COMMON_STEPS,
  FINAL_STEP: "finalStep",
};

export const STEPS_COMMERCIAL = {
  ...COMMON_STEPS,
  CARPET_INFORMATION: "carpetInformation",
  CARPET_EVALUATION: "carpetEvaluation",
  DOWNLOADABLE_DOCS: "downloadableDocs",
  FINAL_STEP: "finalStep",
};

export const STEPS_SAME_COMMERCIAL = {
  CARPET_INFORMATION: "carpetInformation",
  CARPET_EVALUATION: "carpetEvaluation",
  DOWNLOADABLE_DOCS: "downloadableDocs",
  FINAL_STEP: "finalStep",
};

export const TOP_BANNER_SCREENS = [
  STEPS_COMMERCIAL.CARPET_INFORMATION,
  STEPS_COMMERCIAL.CARPET_EVALUATION,
  STEPS_COMMERCIAL.DOWNLOADABLE_DOCS,
];

export const SCREEN_HEADINGS2 = {
  venueInformation: {
    heading: "Information sur le site",
    subHeading: "Renseignez les informations demandées",
    icon: infoLieu,
  },
};

export const SCREEN_HEADINGS = {
  venueInformation: {
    heading: "Information sur le client",
    subHeading: "Renseignez les informations demandées",
    icon: infoLieu,
  },
  roomAttendance: {
    heading: "Fréquentation de la pièce",
    subHeading: "Sélectionnez les pièces concernées",
    icon: frequentationIcon,
  },
  surface: {
    heading: "Surface",
    subHeading: "Renseignez les valeurs ci-dessous",
    icon: surfaceIcon,
  },
  easeOfCleaning: {
    heading: "La moquette se nettoie-t-elle facilement ?",
    subHeading: "Voir PDF opérateur",
    icon: MachineIcon,
  },
  easeOfRemoval: {
    heading: "La dalle se dépose-t-elle facilement ?",
    subHeading: "Voir PDF opérateur",
    icon: DeposeIcon,
  },
  productRating: {
    heading: "Selon vous, la dalle présente-t-elle un potentiel de réemploi ?",
    subHeading: "Voir PDF opérateur",
    icon: ratingsIcon,
  },
  annualCleaningContract: {
    heading: "Y-a-t-il un contrat de nettoyage annuel des moquettes ?",
    subHeading: "Voir PDF opérateur",
    icon: annualContractIcon,
  },
  productPhotos: {
    heading: "Photos du produit",
    subHeading: "Renseignez les valeurs ci-dessous",
    icon: productPhotosIcon,
  },
  contractWithOrak: {
    heading: "Avec Orak ?",
    subHeading: "Renseignez les valeurs ci-dessous",
    icon: orakIcon,
  },
  carpetInformation: {
    heading: "Informations moquette",
    subHeading: "Renseignez des informations complémentaires",
    icon: infoMoqueteeIcon,
  },
  carpetEvaluation: {
    heading: "Evaluation du prix de rachat en fonction du prix du neuf",
    subHeading: "",
    icon: carpetEvaluationIcon,
  },
  downloadableDocs: {
    heading: "Documents téléchargeables",
    subHeading: "",
    icon: downloadIcon,
  },
};

export const ROOM_ATTENDANCE_OPTIONS = {
  FAIBLE: "Faible",
  MOYENNE: "Moyenne",
  ELVEE: "Elevée",
  TRES_ELVEE: "Très élevée",
};

export const SELECTION_OPTION = {
  OUI: "Oui",
  NON: "Non",
};

export const CONTRACT_OPTIONS = {
  ONESHOT: "Oneshot",
  CONFORT: "Confort",
  ZEN: "Zen",
  PREMIUM: "Premium",
};
