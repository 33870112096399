// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CustomerDatabase_topContainer__CZyiI {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.CustomerDatabase_addCustomerButton__fvDnj {\n  background-color: var(--green);\n  width: 4rem;\n  height: 3.5rem;\n  border: none;\n  border-radius: 5px;\n  color: #fff;\n  font-size: 2rem;\n  font-weight: 200;\n  cursor: pointer;\n}\n\n.CustomerDatabase_searchBarContainer__B6os1 {\n  width: 100%;\n}\n\n.CustomerDatabase_viewMoreContainer__DKALh {\n  display: flex;\n  justify-content: center;\n  text-decoration: underline;\n  align-items: center;\n  cursor: pointer;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/navigation/CustomerDatabase.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,SAAS;AACX;;AAEA;EACE,8BAA8B;EAC9B,WAAW;EACX,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,0BAA0B;EAC1B,mBAAmB;EACnB,eAAe;AACjB","sourcesContent":[".topContainer {\n  display: flex;\n  justify-content: space-between;\n  gap: 20px;\n}\n\n.addCustomerButton {\n  background-color: var(--green);\n  width: 4rem;\n  height: 3.5rem;\n  border: none;\n  border-radius: 5px;\n  color: #fff;\n  font-size: 2rem;\n  font-weight: 200;\n  cursor: pointer;\n}\n\n.searchBarContainer {\n  width: 100%;\n}\n\n.viewMoreContainer {\n  display: flex;\n  justify-content: center;\n  text-decoration: underline;\n  align-items: center;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"topContainer": "CustomerDatabase_topContainer__CZyiI",
	"addCustomerButton": "CustomerDatabase_addCustomerButton__fvDnj",
	"searchBarContainer": "CustomerDatabase_searchBarContainer__B6os1",
	"viewMoreContainer": "CustomerDatabase_viewMoreContainer__DKALh"
};
export default ___CSS_LOADER_EXPORT___;
