import { useLocation } from "react-router-dom";
import {
  STEPS_COMMERCIAL,
  STEPS_SAME_COMMERCIAL,
} from "../VenueInformation/constants";
import VenueInformation from "../VenueInformation/VenueInformation";
import VenueInformationSame from "../VenueInformation/VenueInformationSame";
import { AppContext } from "src/Contexts/AppContext";
import { useContext, useEffect, useRef, useState } from "react";
import { getId } from "src/utils/functions";
import { useParams } from "react-router-dom";

export const StudyIdentifications = (props) => {
  const { identificationId } = useParams();
  const location = useLocation();
  const identificationData = location.state;
  const [process, setProccess] = useState(null);
  const [currentPage, setCurrentPage] = useState("venueInformation");
  const [isSameSale, setIsSameSale] = useState(false);

  const {
    state: { selectedIdentification },
    setIdentification,
  } = useContext(AppContext);

  useEffect(() => {
    setIdentification(identificationId);
  }, []);

  useEffect(() => {
    const currentUserID = getId();

    if (selectedIdentification) {
      if (currentUserID == selectedIdentification.operator_userID) {
        setProccess("STEPS_SAME_COMMERCIAL");
        setCurrentPage("carpetInformation");
        setIsSameSale(true);
      } else {
        setProccess("STEPS_COMMERCIAL");
      }
    }
  }, [selectedIdentification]);

  return (
    <>
      {process && process == "STEPS_COMMERCIAL" && (
        <VenueInformation
          processSteps={STEPS_COMMERCIAL}
          processHeading="Identification à étudier"
          identificationData={identificationData}
        />
      )}
      {process && process == "STEPS_SAME_COMMERCIAL" && (
        <VenueInformationSame
          processSteps={STEPS_SAME_COMMERCIAL}
          processHeading="Identification à étudier"
          identificationData={identificationData}
        />
      )}
    </>
  );
};
