import React from "react";
import styles from "./Details.module.css";
import Logo from "src/assets/icons/logo_noir.svg";

export default function ProductDetails({ informations }) {
  return (
    <div>
      <div className={styles.title}>{informations.title}</div>
      <div className={styles.subTitle}>{informations.brand}</div>
      <div className={styles.mainContainer}>
        <div className={styles.imageContainer}>
          <img src={Logo} alt="" />
        </div>
        <div className={styles.infosContainer}>
          <div className={styles.subTitle}>Fiche technique</div>
          <div className={styles.ficheContainer}>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Sous-couche</div>
              <div className={styles.ficheValue}>{informations.sousCouche}</div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Isolation phonique</div>
              <div className={styles.ficheValue}>
                {informations.isolationPhoniqueDB} db
              </div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>
                Coefficient d’absorption acoustique
              </div>
              <div className={styles.ficheValue}>
                {informations.coefAbsAccoustique}
              </div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Classement feu</div>
              <div className={styles.ficheValue}>
                {informations.classementFeu}
              </div>
            </div>
            {informations.allDocsZipURL ? (
              <div className={styles.ficheSubContainer}>
                <div className={styles.ficheTitle}>
                  Donnée technique complète
                </div>
                <a
                  target="_blank"
                  href={informations.allDocsZipURL}
                  className={styles.ficheLink}
                >
                  Consulter
                </a>
              </div>
            ) : null}
            {informations.brochureURL ? (
              <div className={styles.ficheSubContainer}>
                <div className={styles.ficheTitle}>Brochure</div>
                <a
                  target="_blank"
                  href={informations.brochureURL}
                  className={styles.ficheLink}
                >
                  Consulter
                </a>
              </div>
            ) : null}
            {informations.ficheTechniqueURL ? (
              <div className={styles.ficheSubContainer}>
                <div className={styles.ficheTitle}>Fiche technique</div>
                <a
                  target="_blank"
                  href={informations.ficheTechniqueURL}
                  className={styles.ficheLink}
                >
                  Consulter
                </a>
              </div>
            ) : null}
          </div>
          <div className={styles.subTitle}>Délai & QTé</div>
          <div className={styles.ficheContainer}>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Qté disponible en stock</div>
              <div className={styles.ficheValue}>
                {informations.quantityAvailable} m²
              </div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Minimum de commande</div>
              <div className={styles.ficheValue}>
                {informations.minimumCommandeQty} m²
              </div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Tarif HT /m²</div>
              <div className={styles.ficheValue}>
                {informations.pricePerUnit} €
              </div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Délai de livraison</div>
              <div className={styles.ficheValue}>
                {informations.delaisLivraisonJours} jours
              </div>
            </div>
          </div>
        </div>
        <div className={styles.imagesContainer}>
          {informations.images ? (
            <div className={styles.imgContainer}>
              {informations.images &&
                informations.images.map((image, index) => {
                  return (
                    <div key={index} className={styles.image}>
                      <img src={image} />
                    </div>
                  );
                })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
