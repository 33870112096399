import React from "react";

export default function Button({
  children,
  variant,
  disabled = false,
  onClick,
  icon,
  className = "",
}) {
  let buttonClasses = ["button", variant, className];
  disabled && buttonClasses.push("disabled");
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      className={buttonClasses.join(" ")}
    >
      {icon != null ? (
        <span style={{ marginRight: "15px" }}>
          <img src={icon} alt="icon" className={icon} />
        </span>
      ) : (
        ""
      )}
      {children}
    </button>
  );
}
