import { createContext, useMemo, useReducer } from "react";
import { createActions, FAIL, REQUEST, SUCCESS } from "./utils";

import * as API from "src/services/apiService";
import { fakeCards } from "src/pages/navigation/calendarFakeData";
import { JWT_KEY } from "src/utils/constants";
import { parseClientData } from "src/utils/functions";
import { Navigate } from "react-router-dom";

const initialState = {
  loading: false,
  error: null,
  accessToken: "dummy-token",
  calendarEvents: [],
  tokenVerified: false,
  commercials: [],
  createClientSuccess: false,
  getClientsOffset: 0,
  getClientViewMoreEnabled: false,
  clientsList: [],
  createdClientId: null,
  clientContactData: null,
  identificationCreated: false,
  myIdentifications: [],
  selectedIdentification: null,
  picture: null,
  calendarUserInfos: null,
  client: null,
  accountBills: null,
  addressList: -1,
  identificationsByAddressId: null,
  infosIdentificationsCreated: null,
};

const ActionTypes = {
  GET_LOGIN_LINK: "getLoginLink",
  CHECK_LOGIN_TOKEN: "checkLoginToken",
  GET_CALENDAR_EVENTS: "getCalendarEvents",
  GET_COMMERCIALS: "getCommercials",
  CREATE_CLIENT: "createClient",
  UPDATE_CLIENT: "updateClient",
  GET_CLIENTS: "getClients",
  SEARCH_CLIENT: "searchClient",
  CREATE_IDENTIFICATION: "createIdentification",
  UPDATE_IDENTIFICATION: "updateIdentification",
  GET_IDENTIFICATIONS: "getIdentifications",
  SET_IDENTIFICATION: "setIdentification",
  UPDATE_IDENTIFICATION_IMAGES: "updateIdentificationImages",
  GET_PICTURE: "getPicture",
  SET_CALENDAR_USER_INFOS: "setCalendarUserInfos",
  GET_CLIENT_BY_ID: "getClientById",
  GET_ACCOUNT_BILLS: "getAccountBills",
  SET_CLIENT: "setClient",
  GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID:
    "getAddressIndentificationByClientId",
  RESET_CONTEXT: "resetContext",
  GET_LOADING: "getLoading",
  GET_LOADED: "getLoaded",
  GET_IDENTIFICATIONS_BY_ADDRESS_ID: "getIdentificationsByAddressId",
  RESET_INFOS_IDENTIFICATIONS_CREATED: "resetInfosIdentificationsCreated",
  RESET_CREATED_CLIENT_ID: "resetCreatedClientId",
};

export const GET_LOGIN_LINK = createActions(ActionTypes.GET_LOGIN_LINK);
export const RESET_CONTEXT = createActions(ActionTypes.RESET_CONTEXT);
export const RESET_CREATED_CLIENT_ID = createActions(
  ActionTypes.RESET_CREATED_CLIENT_ID
);
export const GET_LOADING = createActions(ActionTypes.GET_LOADING);
export const GET_LOADED = createActions(ActionTypes.GET_LOADED);
export const GET_IDENTIFICATIONS_BY_ADDRESS_ID = createActions(
  ActionTypes.GET_IDENTIFICATIONS_BY_ADDRESS_ID
);
export const RESET_INFOS_IDENTIFICATIONS_CREATED = createActions(
  ActionTypes.RESET_INFOS_IDENTIFICATIONS_CREATED
);

export const CHECK_LOGIN_TOKEN = createActions(ActionTypes.CHECK_LOGIN_TOKEN);
export const GET_CALENDAR_EVENTS = createActions(
  ActionTypes.GET_CALENDAR_EVENTS
);
export const GET_COMMERCIALS = createActions(ActionTypes.GET_COMMERCIALS);
export const CREATE_CLIENT = createActions(ActionTypes.CREATE_CLIENT);
export const UPDATE_CLIENT = createActions(ActionTypes.UPDATE_CLIENT);
export const GET_CLIENTS = createActions(ActionTypes.GET_CLIENTS);
export const SEARCH_CLIENT = createActions(ActionTypes.SEARCH_CLIENT);
export const GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID = createActions(
  ActionTypes.GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID
);
export const GET_ACCOUNT_BILLS = createActions(ActionTypes.GET_ACCOUNT_BILLS);
export const CREATE_IDENTIFICATION = createActions(
  ActionTypes.CREATE_IDENTIFICATION
);
export const UPDATE_IDENTIFICATION = createActions(
  ActionTypes.UPDATE_IDENTIFICATION
);
export const GET_IDENTIFICATIONS = createActions(
  ActionTypes.GET_IDENTIFICATIONS
);
export const SET_IDENTIFICATION = createActions(ActionTypes.SET_IDENTIFICATION);
export const UPDATE_IDENTIFICATION_IMAGES = createActions(
  ActionTypes.UPDATE_IDENTIFICATION_IMAGES
);

export const GET_PICTURE = createActions(ActionTypes.GET_PICTURE);
export const SET_CALENDAR_USER_INFOS = createActions(
  ActionTypes.SET_CALENDAR_USER_INFOS
);

export const GET_CLIENT_BY_ID = createActions(ActionTypes.GET_CLIENT_BY_ID);
export const SET_CLIENT = createActions(ActionTypes.SET_CLIENT);

export const AppContext = createContext({
  state: initialState,
});

export const Reducer = (state, action) => {
  const { payload } = action;
  switch (action.type) {
    case GET_LOGIN_LINK[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case GET_LOGIN_LINK[SUCCESS]:
      return {
        ...state,
        loading: false,
      };
    case GET_LOGIN_LINK[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case CHECK_LOGIN_TOKEN[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case CHECK_LOGIN_TOKEN[SUCCESS]:
      return {
        ...state,
        tokenVerified: true,
        loading: false,
      };
    case CHECK_LOGIN_TOKEN[FAIL]:
      return {
        ...state,
        loading: false,
        tokenVerified: false,
        error: payload.error,
      };
    case GET_CALENDAR_EVENTS[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case GET_CALENDAR_EVENTS[SUCCESS]:
      return {
        ...state,
        calendarEvents: payload.data,
        loading: false,
      };
    case RESET_INFOS_IDENTIFICATIONS_CREATED[SUCCESS]:
      return {
        ...state,
        infosIdentificationsCreated: null,
        createdClientId: null,
      };
    case GET_CALENDAR_EVENTS[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case GET_COMMERCIALS[REQUEST]:
      return {
        ...state,
        createClientSuccess: false,
      };
    case GET_COMMERCIALS[SUCCESS]:
      return {
        ...state,
        commercials: payload.data,
        identificationCreated: false,
        loading: false,
      };
    case GET_COMMERCIALS[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case CREATE_CLIENT[REQUEST]:
      return {
        ...state,
        loading: true,
        client: payload.data,
      };
    case CREATE_CLIENT[SUCCESS]:
      return {
        ...state,
        createClientSuccess: true,
        createdClientId: payload.clientId,
        loading: false,
        error: false,
      };
    case CREATE_CLIENT[FAIL]:
      var errorMessage = payload.errorMessage;

      errorMessage = "EmailAlreadyUsed" ? "Email déjà utilisé" : errorMessage;

      return {
        ...state,
        loading: false,
        error: errorMessage,
        createClientSuccess: false,
      };
    case UPDATE_CLIENT[REQUEST]:
      return {
        ...state,
        loading: true,
        clientContactData: payload.data,
      };
    case UPDATE_CLIENT[SUCCESS]:
      return {
        ...state,
        createClientSuccess: true,
        createdClientId: payload.clientId,
        loading: false,
      };
    case UPDATE_CLIENT[FAIL]:
      var errorMessage = payload.errorMessage;

      errorMessage = "EmailAlreadyUsed" ? "Email déjà utilisé" : errorMessage;

      return {
        ...state,
        loading: false,
        error: errorMessage,
        createClientSuccess: false,
      };
    case GET_CLIENTS[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENTS[SUCCESS]:
      return {
        ...state,
        clientsList: payload.data,
        getClientsOffset: payload.getClientsOffset,
        enableViewMore: payload.enableViewMore,
        loading: false,
      };
    case GET_CLIENTS[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID[REQUEST]:
      return {
        ...state,
        addressList: -1,
        loading: true,
      };
    case GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID[SUCCESS]:
      return {
        ...state,
        addressList: payload,
        loading: false,
      };
    case GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case GET_IDENTIFICATIONS_BY_ADDRESS_ID[REQUEST]:
      return {
        ...state,
        identificationsByAddressId: null,
        loading: true,
      };
    case GET_IDENTIFICATIONS_BY_ADDRESS_ID[SUCCESS]:
      return {
        ...state,
        identificationsByAddressId: payload,
        loading: false,
      };
    case GET_IDENTIFICATIONS_BY_ADDRESS_ID[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case GET_CLIENT_BY_ID[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case GET_CLIENT_BY_ID[SUCCESS]:
      return {
        ...state,
        client: payload.data[0][0],
        loading: false,
      };
    case GET_CLIENT_BY_ID[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case SET_CLIENT[SUCCESS]:
      return {
        ...state,
        client: payload.data,
        error: payload.error,
      };
    case GET_ACCOUNT_BILLS[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case GET_ACCOUNT_BILLS[SUCCESS]:
      return {
        ...state,
        accountBills: payload.data,
        loading: false,
      };
    case GET_ACCOUNT_BILLS[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case SEARCH_CLIENT[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_CLIENT[SUCCESS]:
      return {
        ...state,
        clientsList: payload.data,
        loading: false,
      };
    case SEARCH_CLIENT[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case CREATE_IDENTIFICATION[REQUEST]:
      return {
        ...state,
        identificationCreated: false,
        loading: true,
      };
    case CREATE_IDENTIFICATION[SUCCESS]:
      return {
        ...state,
        loading: false,
        identificationCreated: true,
        infosIdentificationsCreated: payload,
      };
    case CREATE_IDENTIFICATION[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case UPDATE_IDENTIFICATION[REQUEST]:
      return {
        ...state,
        identificationCreated: false,
        loading: true,
      };
    case UPDATE_IDENTIFICATION[SUCCESS]:
      return {
        ...state,
        loading: false,
        identificationCreated: true,
      };
    case UPDATE_IDENTIFICATION[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case GET_IDENTIFICATIONS[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case GET_IDENTIFICATIONS[SUCCESS]:
      return {
        ...state,
        myIdentifications: payload.data,
        loading: false,
      };
    case GET_IDENTIFICATIONS[FAIL]:
      return {
        ...state,
        loading: false,
      };
    case SET_IDENTIFICATION[SUCCESS]:
      return {
        ...state,
        selectedIdentification: payload.data,
      };
    case UPDATE_IDENTIFICATION_IMAGES[SUCCESS]:
      return {
        ...state,
        selectedIdentification: payload,
      };
    case GET_PICTURE[REQUEST]:
      return {
        ...state,
        loading: true,
      };
    case GET_PICTURE[FAIL]:
      return {
        ...state,
        loading: false,
        error: payload.error,
      };
    case GET_PICTURE[SUCCESS]:
      return {
        ...state,
        loading: false,
        pictureURL: payload.data,
      };
    case SET_CALENDAR_USER_INFOS[SUCCESS]:
      return {
        ...state,
        client: payload.data,
      };
    case GET_LOADING[SUCCESS]:
      return {
        ...state,
        loading: true,
      };
    case GET_LOADED[SUCCESS]:
      return {
        ...state,
        loading: false,
      };
    case RESET_CONTEXT[SUCCESS]:
      return {
        ...state,
        client: null,
        clientContactData: null,
        selectedIdentification: null,
        identificationCreated: false,
      };
    case RESET_CREATED_CLIENT_ID[SUCCESS]:
      return {
        ...state,
        createdClientId: false,
      };

    default:
      return state;
  }
};

export const ContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);

  const getLoginLink = async (email) => {
    try {
      dispatch({
        type: GET_LOGIN_LINK[REQUEST],
      });
      const response = await API.getLoginLink(email);

      dispatch({
        type: GET_LOGIN_LINK[SUCCESS],
      });
    } catch (error) {
      dispatch({
        type: GET_LOGIN_LINK[FAIL],
        payload: { error: error.response },
      });
    }
  };

  const checkLoginToken = async (token) => {
    try {
      dispatch({
        type: CHECK_LOGIN_TOKEN[REQUEST],
      });
      const response = await API.checkLoginToken(token);

      const {
        data: {
          payload: { status, jwt, role },
        },
      } = response;

      if (status === "OK" && role == "Customer") {
        localStorage.removeItem(JWT_KEY);
        localStorage.setItem(JWT_KEY, jwt);
        dispatch({
          type: CHECK_LOGIN_TOKEN[SUCCESS],
        });
      } else if (status === "OK") {
        localStorage.setItem(JWT_KEY, jwt);

        dispatch({
          type: CHECK_LOGIN_TOKEN[SUCCESS],
        });
      } else {
        dispatch({
          type: CHECK_LOGIN_TOKEN[FAIL],
          payload: { error: "Le lien n'est plus valable, veuillez réessayer" },
        });
      }
    } catch (error) {
      dispatch({
        type: CHECK_LOGIN_TOKEN[FAIL],
        error: error.response,
      });
    }
  };

  const getCalendarEvents = async (startDate) => {
    dispatch({
      type: GET_CALENDAR_EVENTS[REQUEST],
    });
    try {
      const response = await API.getCalendarEvents(startDate);
      const {
        data: { payload },
      } = response;
      dispatch({
        type: GET_CALENDAR_EVENTS[SUCCESS],
        payload: { data: payload },
      });
    } catch (error) {
      dispatch({
        type: GET_CALENDAR_EVENTS[FAIL],
        error: error.response,
      });
    }
  };

  const getCommercials = async () => {
    try {
      dispatch({
        type: GET_COMMERCIALS[REQUEST],
      });
      const response = await API.getCommercialsList();
      const {
        data: {
          payload: { data, status },
        },
      } = response;
      if (status == "OK") {
        dispatch({
          type: GET_COMMERCIALS[SUCCESS],
          payload: { data },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_COMMERCIALS[FAIL],
        payload: { error },
      });
    }
  };

  const getLoading = () => {
    dispatch({
      type: GET_LOADING[SUCCESS],
    });
  };
  const getLoaded = () => {
    dispatch({
      type: GET_LOADED[SUCCESS],
    });
  };
  const resetInfosIdentificationsCreated = () => {
    dispatch({
      type: RESET_INFOS_IDENTIFICATIONS_CREATED[SUCCESS],
    });
  };

  const createAccount = async (data) => {
    const { first, last, telContact, emailContact } = data;
    dispatch({
      type: CREATE_CLIENT[REQUEST],
      payload: {
        data: {
          first,
          last,
          telContact,
          phoneContact: telContact,
          emailContact,
        },
      },
    });
    try {
      const response = await API.createAccount(data);
      const {
        data: { status, payload },
      } = response;
      if (status === "OK" && payload.status != "KO") {
        const clientId = payload.resp.Table[0].newID;
        dispatch({
          type: CREATE_CLIENT[SUCCESS],
          payload: { clientId },
        });
      } else if (status === "OK" && payload.status === "KO") {
        const errorMessage = payload.code;
        dispatch({
          type: CREATE_CLIENT[FAIL],
          payload: { errorMessage },
        });
      }
    } catch (error) {
      dispatch({
        type: CREATE_CLIENT[FAIL],
        payload: { error },
      });
    }
  };

  const updateAccount = async (data) => {
    try {
      const response = await API.updateAccount(data);
      const {
        data: { status, payload },
      } = response;
      if (status === "OK" && payload.status != "KO") {
        const clientId = data.id;
        dispatch({
          type: UPDATE_CLIENT[SUCCESS],
          payload: { clientId },
        });
      } else if (status === "OK" && payload.status === "KO") {
        const errorMessage = payload.code;
        dispatch({
          type: UPDATE_CLIENT[FAIL],
          payload: { errorMessage },
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_CLIENT[FAIL],
        payload: { error },
      });
    }
  };

  const getClients = async () => {
    dispatch({
      type: GET_CLIENTS[REQUEST],
    });
    try {
      const { getClientsOffset, clientsList } = state;
      const response = await API.getClients(getClientsOffset);
      const {
        data: {
          payload: { data, status },
        },
      } = response;
      if (status === "OK") {
        const parsedData = parseClientData(data);
        dispatch({
          type: GET_CLIENTS[SUCCESS],

          payload: {
            data: [...parsedData, ...clientsList],
            enableViewMore: parsedData.length > 9,
            getClientsOffset: getClientsOffset + 10,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CLIENTS[FAIL],
        payload: { error },
      });
    }
  };

  const getClientById = async (id) => {
    dispatch({
      type: GET_CLIENT_BY_ID[REQUEST],
    });
    try {
      const { client } = state;
      const response = await API.getClientById(id);
      const {
        data: {
          payload: { data, status },
        },
      } = response;
      if (status === "OK") {
        // const parsedData = parseClientData(data);
        dispatch({
          type: GET_CLIENT_BY_ID[SUCCESS],
          payload: {
            data: [data],
            // enableViewMore: parsedData.length > 9,
            // getClientsOffset: getClientsOffset + 10,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CLIENTS[FAIL],
        payload: { error },
      });
    }
  };

  const searchClient = async (companyName) => {
    dispatch({
      type: SEARCH_CLIENT[REQUEST],
    });
    try {
      const response = await API.searchClients(companyName);
      const {
        data: {
          payload: { status, data },
        },
      } = response;
      if (status === "OK") {
        const parsedData = parseClientData(data);
        dispatch({
          type: SEARCH_CLIENT[SUCCESS],
          payload: { data: parsedData },
        });
      }
    } catch (error) {
      dispatch({
        type: SEARCH_CLIENT[FAIL],
        payload: { error },
      });
    }
  };

  const createIdentification = async (payload, productImages) => {
    dispatch({ type: CREATE_IDENTIFICATION[REQUEST] });
    try {
      const createIdResponse = await API.createIdentification(payload);
      const {
        payload: { Table },
        status,
      } = createIdResponse.data;
      if (status === "OK") {
        const identificationId = Table[0].newIdentificationID;
        for (let image in productImages) {
          await API.addImagesToIdentification(
            identificationId,
            productImages[image][image],
            false
          );
        }
      }
      dispatch({
        type: CREATE_IDENTIFICATION[SUCCESS],
        payload: { infos: Table[0] },
      });
    } catch (error) {
      dispatch({
        type: CREATE_IDENTIFICATION[FAIL],
        payload: { error },
      });
    }
  };

  const updateIdentification = async (
    payload,
    identificationID,
    curImagesID
    //  productImages
  ) => {
    dispatch({ type: UPDATE_IDENTIFICATION[REQUEST] });
    try {
      const createIdResponse = await API.updateIdentification(
        payload,
        identificationID,
        curImagesID
      );
      const {
        payload: { Table },
        status,
      } = createIdResponse.data;
      // if (status === "OK") {
      //   const identificationId = Table[0].newIdentificationID;
      //   for (let image in productImages) {
      //     await API.addImagesToIdentification(
      //       identificationId,
      //       productImages[image][image],
      //       false
      //     );
      //   }
      // }
      dispatch({
        type: CREATE_IDENTIFICATION[SUCCESS],
      });
    } catch (error) {
      dispatch({
        type: CREATE_IDENTIFICATION[FAIL],
        payload: { error },
      });
    }
  };

  const updateIdentificationSameSale = async (payload, identificationID) => {
    dispatch({ type: UPDATE_IDENTIFICATION[REQUEST] });
    try {
      const createIdResponse = await API.updateIdentificationSameSale(
        payload,
        identificationID
      );
      const {
        payload: { Table },
        status,
      } = createIdResponse.data;
      dispatch({
        type: CREATE_IDENTIFICATION[SUCCESS],
      });
    } catch (error) {
      dispatch({
        type: CREATE_IDENTIFICATION[FAIL],
        payload: { error },
      });
    }
  };

  const getPicture = async () => {
    dispatch({
      type: GET_PICTURE[REQUEST],
    });
    try {
      const { selectedIdentification } = state;
      const response = await API.getPicture(
        selectedIdentification.imagePath,
        selectedIdentification.id
      );
      const {
        data: {
          payload: { data, status },
        },
      } = response;
      if (status === "OK") {
        dispatch({
          type: GET_PICTURE[SUCCESS],
          payload: { data: data },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CLIENTS[FAIL],
        payload: { error },
      });
    }
  };

  const getIdentifications = async () => {
    dispatch({
      type: GET_IDENTIFICATIONS[REQUEST],
    });
    try {
      const response = await API.getIdentifications();
      const {
        data: { payload, status },
      } = response;
      if (status === "OK") {
        dispatch({
          type: GET_IDENTIFICATIONS[SUCCESS],
          payload: { data: payload },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_IDENTIFICATIONS[FAIL],
        payload: { error },
      });
    }
  };

  const setCalendarUserInfos = (data) => {
    data.zip = data.cp;
    data.city = data.ville;
    data.first = data.contactName;
    data.emailContact = data.email;
    data.phone = data.phone.replace(/ /g, "");
    data.phoneContact = data.phone.replace(/ /g, "");
    data.firstNameContact = data.contactName;

    dispatch({
      type: SET_CALENDAR_USER_INFOS[SUCCESS],
      payload: { data },
    });
  };

  const resetContext = () => {
    dispatch({
      type: RESET_CONTEXT[SUCCESS],
    });
  };
  const resetCreatedClientId = () => {
    dispatch({
      type: RESET_CREATED_CLIENT_ID[SUCCESS],
    });
  };

  const setIdentification = (data) => {
    dispatch({
      type: SET_IDENTIFICATION[SUCCESS],
      payload: { data },
    });
  };

  const updateIdentificationImages = (data) => {
    var newStateSelectedIdentification = (state.selectedIdentification = data);

    dispatch({
      type: UPDATE_IDENTIFICATION_IMAGES[SUCCESS],
      payload: newStateSelectedIdentification,
    });
  };

  const setClient = (data) => {
    var data = {
      first: data.contactFirstname,
      last: data.contactLastname,
      phoneContact: data.contactNumber,
      emailContact: data.emailAddress,
    };

    dispatch({
      type: SET_CLIENT[SUCCESS],
      payload: { data },
    });
  };

  const getAccountBills = async (page, type) => {
    dispatch({
      type: GET_ACCOUNT_BILLS[REQUEST],
    });
    try {
      const { accountBills } = state;
      const response = await API.getAccountBills(page, type);
      const {
        data: {
          payload: { devis, pageSize, totalDevis, factures, totalFactures },
          status,
        },
      } = response;
      if (status === "OK") {
        // const parsedData = parseClientData(data);
        dispatch({
          type: GET_ACCOUNT_BILLS[SUCCESS],
          payload: {
            data: [pageSize, devis, totalDevis, factures, totalFactures],
            // enableViewMore: parsedData.length > 9,
            // getClientsOffset: getClientsOffset + 10,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: GET_ACCOUNT_BILLS[FAIL],
        payload: { error },
      });
    }
  };

  const getAddressIndentificationByClientId = async (clientId) => {
    dispatch({
      type: GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID[REQUEST],
    });
    try {
      const { addressList } = state;
      const response = await API.getAddressIndentificationByClientId(clientId);
      const {
        data: { payload, status },
      } = response;
      if (status === "OK") {
        // const parsedData = parseClientData(data);
        dispatch({
          type: GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID[SUCCESS],
          payload: payload,
        });
        return payload;
      }
    } catch (error) {
      dispatch({
        type: GET_ADDRESS_IDENTIFICATION_BY_CLIENT_ID[FAIL],
        payload: { error },
      });
    }
  };
  const getIdentificationsByAddressId = async (addressId, idClient) => {
    dispatch({
      type: GET_IDENTIFICATIONS_BY_ADDRESS_ID[REQUEST],
    });
    try {
      const { identificationsByAddressId } = state;
      const response = await API.getIdentificationsByAddressId(
        addressId,
        idClient
      );
      const {
        data: { payload, status },
      } = response;
      if (status === "OK") {
        dispatch({
          type: GET_IDENTIFICATIONS_BY_ADDRESS_ID[SUCCESS],
          payload: payload,
        });
        return payload;
      }
    } catch (error) {
      dispatch({
        type: GET_IDENTIFICATIONS_BY_ADDRESS_ID[FAIL],
        payload: { error },
      });
    }
  };

  const memoizedContextValue = useMemo(
    () => ({
      state,
      getLoginLink,
      checkLoginToken,
      getCalendarEvents,
      getCommercials,
      createAccount,
      updateAccount,
      getClients,
      searchClient,
      createIdentification,
      getIdentifications,
      setIdentification,
      getPicture,
      setCalendarUserInfos,
      getClientById,
      getAccountBills,
      setClient,
      getAddressIndentificationByClientId,
      resetContext,
      getLoading,
      getLoaded,
      getIdentificationsByAddressId,
      updateIdentificationImages,
      updateIdentification,
      updateIdentificationSameSale,
      resetInfosIdentificationsCreated,
      resetCreatedClientId,
    }),
    [state]
  );

  return (
    <AppContext.Provider value={memoizedContextValue}>
      {children}
    </AppContext.Provider>
  );
};
