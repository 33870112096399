// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Calendar_title__prFZX {\n  display: flex;\n  justify-content: space-between;\n  /* align-items: center; */\n  margin-bottom: 15px;\n  font-size: 20px;\n  letter-spacing: 1px;\n  line-height: 22px;\n}\n\n.Calendar_title__prFZX input {\n  margin-top: 0.5rem;\n}\n\n.Calendar_titleDate__vsWAb {\n  font-size: 14px;\n}\n\n.Calendar_applyBtn__pzV\\+j {\n  width: -moz-fit-content;\n  width: fit-content;\n  margin-left: 1rem;\n  height: 2.5rem;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/navigation/Calendar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB;EACzB,mBAAmB;EACnB,eAAe;EACf,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,uBAAkB;EAAlB,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".title {\n  display: flex;\n  justify-content: space-between;\n  /* align-items: center; */\n  margin-bottom: 15px;\n  font-size: 20px;\n  letter-spacing: 1px;\n  line-height: 22px;\n}\n\n.title input {\n  margin-top: 0.5rem;\n}\n\n.titleDate {\n  font-size: 14px;\n}\n\n.applyBtn {\n  width: fit-content;\n  margin-left: 1rem;\n  height: 2.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "Calendar_title__prFZX",
	"titleDate": "Calendar_titleDate__vsWAb",
	"applyBtn": "Calendar_applyBtn__pzV+j"
};
export default ___CSS_LOADER_EXPORT___;
