// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Loading_loadingAnimation__hl1I0 {\n  background-color: rgba(0, 0, 0, 0.5);\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n  overflow-y: hidden;\n  z-index: 99999;\n}\n\n@keyframes Loading_spinner__s5eU9 {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.Loading_loadingSpinner__1uE1l {\n  width: 50px;\n  height: 50px;\n  border: 4px solid var(--light-grey); /* Light grey */\n  border-top: 4px solid #383636; /* Black */\n  border-radius: 50%;\n  animation: Loading_spinner__s5eU9 1.5s linear infinite;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,eAAe;EACf,YAAY;EACZ,WAAW;EACX,MAAM;EACN,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF;AACA;EACE,WAAW;EACX,YAAY;EACZ,mCAAmC,EAAE,eAAe;EACpD,6BAA6B,EAAE,UAAU;EACzC,kBAAkB;EAClB,sDAAuC;AACzC","sourcesContent":[".loadingAnimation {\n  background-color: rgba(0, 0, 0, 0.5);\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n  overflow-y: hidden;\n  z-index: 99999;\n}\n\n@keyframes spinner {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n.loadingSpinner {\n  width: 50px;\n  height: 50px;\n  border: 4px solid var(--light-grey); /* Light grey */\n  border-top: 4px solid #383636; /* Black */\n  border-radius: 50%;\n  animation: spinner 1.5s linear infinite;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingAnimation": "Loading_loadingAnimation__hl1I0",
	"loadingSpinner": "Loading_loadingSpinner__1uE1l",
	"spinner": "Loading_spinner__s5eU9"
};
export default ___CSS_LOADER_EXPORT___;
