import { useEffect, useContext } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FormInput } from "../CreateClient/CreateClientForm";
import { getErrorText } from "../CreateClient/validationSchema";
import { OrakDatePicker } from "../DatePicker/DatePicker";
import { carpetInformationSchema } from "./validationSchema";
import { HiddenButton } from "./VenueInformation";
import { AppContext } from "src/Contexts/AppContext";

import styles from "./VenueInformation.module.css";

export const CarpetInformation = ({
  submitBtnRef,
  handleData,
  setScreenDataValid,
  enteredData = null,
  setCheckNoCreationDate,
}) => {
  const [fabricationDate, setFabricationDate] = useState("");
  const [fabricationDateError, setFabricationDateError] = useState("");
  const [isFabricationDateIsKnown, setIsFabricationDateIsKnown] =
    useState(true);

  const {
    state: { selectedIdentification },
  } = useContext(AppContext);

  const handleSetDate = (date) => {
    setFabricationDate(date);
    setFabricationDateError("");
  };

  const setFormData = (data) => {
    Object.keys(data).forEach((key) => {
      setValue(key, data[key]);
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (selectedIdentification) {
      const data = {
        poseurName: selectedIdentification.poseurName,
        proprietaireMoquette: selectedIdentification.proprietaireMoquette,
      };
      if (
        selectedIdentification.dateFabricationMoquette != "" &&
        new Date(selectedIdentification.dateFabricationMoquette) >
          new Date("01-01-1970")
      ) {
        setFabricationDate(
          new Date(selectedIdentification.dateFabricationMoquette)
        );
      } else {
        setFabricationDate(null);
      }
      setFormData(data);
    }

    if (enteredData["carpetInfo"]) {
      const data = {
        poseurName: enteredData["carpetInfo"].poseurName,
        proprietaireMoquette: enteredData["carpetInfo"].proprietaireMoquette,
      };
      if (enteredData["carpetInfo"].dateFabricationMoquette != "") {
        setFabricationDate(
          new Date(enteredData["carpetInfo"].dateFabricationMoquette)
        );
      }
      setFormData(data);
    }
    setCheckNoCreationDate(false);
  }, []);

  const {
    handleSubmit,
    register,
    formState,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(carpetInformationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    if (isFabricationDateIsKnown == true && !fabricationDate) {
      return setFabricationDateError(getErrorText("Date de fabrication"));
    }
    if (Object.keys(errors).length === 0) {
      const allData = {
        ...data,
        dateFabricationMoquette:
          isFabricationDateIsKnown == true ? fabricationDate : "",
      };

      handleData("carpetInfo", allData);
      setScreenDataValid(true);
      if (isFabricationDateIsKnown == false) {
        setCheckNoCreationDate(true);
      }
    }
  };

  return (
    <div className={styles.itemContainer}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div>
          <select
            name="commercial"
            className={styles.selectStyles}
            {...register("commercial")}
          >
            {/* <option value="" selected>
              ......
            </option>

            {OPTIONS.length &&
              OPTIONS.map(({ id, name }) => (
                <option key={id} value={id}>
                  {name}
                </option>
              ))} */}
        {/* </select>
          {errors?.commercial?.message && (
            <p
              style={{ marginTop: "-1.5rem", marginBottom: "1rem" }}
              className={styles.formError}
            >
              {errors?.commercial?.message}
            </p>
          )}
        </div> */}
        <FormInput
          register={register}
          fieldName="poseurName"
          placeholder="Poseur"
          error={errors?.poseurName?.message}
        />
        <FormInput
          register={register}
          fieldName="proprietaireMoquette"
          placeholder="Propriétaire de la moquette"
          error={errors?.proprietaireMoquette?.message}
        />
        <div>
          <label>Connaissez-vous la date de fabrication?</label>
          <div className={styles.radioContainer}>
            <div className={styles.radioYes}>
              <input
                type="radio"
                name=""
                id="radioYes"
                onChange={() => setIsFabricationDateIsKnown(true)}
                checked={isFabricationDateIsKnown == true ? true : false}
              />
              <label htmlFor="radioYes">Oui</label>
            </div>
            <div>
              <input
                type="radio"
                name=""
                id="radioNo"
                onChange={() => setIsFabricationDateIsKnown(false)}
                checked={isFabricationDateIsKnown == false ? true : false}
              />
              <label htmlFor="radioNo">Non</label>
            </div>
          </div>
        </div>
        {isFabricationDateIsKnown == true ? (
          <>
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid #252525",
                marginBottom: "2rem",
              }}
            >
              <OrakDatePicker
                value={fabricationDate}
                handleSetDate={handleSetDate}
                disableFuture
                placeholder="Date de fabrication"
              />
            </div>
            {fabricationDateError && (
              <p style={{ marginTop: "-1rem" }} className={styles.formError}>
                {fabricationDateError}
              </p>
            )}
          </>
        ) : null}
        <HiddenButton btnRef={submitBtnRef} type="submit" />
      </form>
    </div>
  );
};
