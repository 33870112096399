import React, { useState, useContext, useEffect } from "react";
import Button from "src/components/button/Button";
import Header from "src/components/header/Header";
import ClientCard from "src/components/clientCard/ClientCard";
import Form from "src/components/form/Form";
import { AppContext } from "src/Contexts/AppContext";

import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import styles from "src/pages/navigation/RendezVous.module.css";

export default function RendezVous() {
  let navigate = useNavigate();
  const location = useLocation();
  const card = location.state;

  const { setCalendarUserInfos } = useContext(AppContext);

  useEffect(() => {
    setCalendarUserInfos(card);
  }, []);

  return (
    <>
      <Header screenTarget={"/calendrier"} icon={faChevronLeft}>
        Détails du rdv
      </Header>
      <div className={styles.container}>
        <ClientCard card={card} />
        <div className={styles.card}>
          <div className={styles.container__flex} style={{ marginTop: "7px" }}>
            <p>Information de rendez-vous</p>
            <p>
              de {card.hoursStart} à {card.hoursEnd}
            </p>
          </div>
          <p className={styles.container__address}>{card.address}</p>
          <div className={styles.container__bottom}>
            <p>{card.building}</p>
            {card.stage == "" ? (
              ""
            ) : (
              <>
                <span className={styles.borderLeft}></span>
                <p>
                  {card.stage == 1 ? `${card.stage}er` : `${card.stage}ème`}{" "}
                  étage
                </p>
              </>
            )}

            {card.digicode == "" ? (
              ""
            ) : (
              <>
                <span className={styles.borderLeft}></span>
                <p>Digicode : {card.digicode}</p>
              </>
            )}
          </div>
        </div>
        {/* 
        <div className={styles.card}>
          <p style={{ fontWeight: "500" }}>Remarques</p>
          <p
            style={{ fontWeight: "300", marginTop: "7px", lineHeight: "15px" }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat.{" "}
          </p>
        </div> */}
        <Form formData={card} />
      </div>
    </>
  );
}
