import Button from "../button/Button";
import { Modal } from "../Modal/Modal";

export const DocsModal = ({ isOpen, onClose, addNewDoc }) => {
  window.scrollTo(0, 0);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div style={{ width: "20rem" }}>
        <div style={{ marginTop: "1rem", marginBottom: "1rem" }}>
          <Button variant="primary" onClick={addNewDoc}>
            Ajouter un nouveau document
          </Button>
        </div>
      </div>
    </Modal>
  );
};
