import { JWT_KEY, ROLES } from "./constants";

export const getJWTFromLocalStorage = () => localStorage.getItem(JWT_KEY);

export const parseClientData = (data) => {
  return data.map((item) => ({
    id: item.id,
    companyName: item.company,
    // clientName: `${item.first} ${item.last}`,
    contactFirstname: item.first,
    contactLastname: item.last,
    address: item.address,
    emailAddress: item.email,
    contactNumber: item.phone,
  }));
};

export const dataURIToFile = (dataUrl, filename) => {
  let arr = dataUrl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

export const getId = () => {
  const jwt = getJWTFromLocalStorage();
  if (jwt) {
    const { id } = jwtDecode(jwt);
    return id;
  }
};

export const parseCreateIdentificationData = (data) => {
  const {
    clientId,
    roomAttendance,
    surface,
    surfaceReview,
    easeOfCleaning,
    easeOfCleaningFalseReason,
    productRating,
    easeOfRemoval,
    easeOfRemovalFalseReason,
    cleaningContract,
    cleaningContractWithOrak,
    cleaningContactWithoutOrak,
    productName,
    productBrand,
    carpetValue,
    carpetInfo,
    initialPrice,
    isDraft,
    status,
    location: {
      addressID,
      address,
      cp,
      ville,
      building,
      digicode,
      floor,
      emailContact,
      firstNameContact,
      lastNameContact,
      informations,
      name,
      phoneContact,
      place,
      liberationDate,
      platformHeight,
    },
  } = data;
  return {
    operator_userID: 12,
    client_userID: clientId || "",
    surface_m2: surface,
    surfaceReview: surfaceReview,
    locationName: name,
    locationAddress: address,
    locationCP: cp,
    locationVille: ville,
    addressID: addressID,
    locationBuilding: building,
    locationDigicode: digicode,
    locationFloor: floor,
    locationPlace: place,
    locationInformations: informations,
    easeOfCleaningFalseReason: easeOfCleaningFalseReason || "",
    easeOfRemovalFalseReason: easeOfRemovalFalseReason || "",
    roomAttendance,
    easeOfCleaning,
    productRating,
    easeOfRemoval,
    cleaningContract,
    cleaningContractWithOrak,
    cleaningContactWithoutOrak,
    productName,
    productBrand,
    firstNameContact,
    lastNameContact,
    phoneContact,
    emailContact,
    estimationRachatMoquette: carpetValue || "",
    prixNeufMoquette: initialPrice || "",
    poseurName: carpetInfo?.poseurName ? carpetInfo.poseurName : "",
    proprietaireMoquette: carpetInfo?.proprietaireMoquette
      ? carpetInfo.proprietaireMoquette
      : "",
    dateFabricationMoquette: carpetInfo?.dateFabricationMoquette
      ? carpetInfo.dateFabricationMoquette
      : "",
    liberationDate,
    isDraft,
    status,
    platformHeight,
  };
};

export const parseUpdateSameSaleIdentificationData = (data) => {
  const { carpetValue, carpetInfo, initialPrice, isDraft, status } = data;
  return {
    prixNeufMoquette: initialPrice || "",
    estimationRachatMoquette: carpetValue || "",
    poseurName: carpetInfo?.poseurName ? carpetInfo.poseurName : "",
    proprietaireMoquette: carpetInfo?.proprietaireMoquette
      ? carpetInfo.proprietaireMoquette
      : "",
    dateFabricationMoquette: carpetInfo?.dateFabricationMoquette
      ? carpetInfo.dateFabricationMoquette
      : "",
    isDraft,
    status,
  };
};

export const jwtDecode = (jwt) => {
  var base64Url = jwt.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
};

export const getUserRole = () => {
  const jwt = getJWTFromLocalStorage();
  if (jwt) {
    const { role } = jwtDecode(jwt);
    if (role === "Customer") {
      return ROLES.CUSTOMER;
    } else if (role === "Sales") {
      return ROLES.COMMERCIAL;
    } else if (role == "Admin") {
      return ROLES.ADMIN;
    }
  }
  return ROLES.OPERATOR;
};

export const formatPhoneNumber = (phoneNumber) => {
  if (phoneNumber) {
    const pattern = "## ## ## ## ##";
    let i = 0;
    return pattern.replace(/#/g, (_) => phoneNumber[i++]);
  }
};

const getPhoneEmailFromEventContacts = (contacts, contactType) => {
  const contact = contacts.filter(({ type }) => type === contactType);
  if (contact.length) {
    return contact[0].coordinates;
  }
  return "";
};

const getEventHours = (dateString) => {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const parsePraxedoData = (events) => {
  return events.map((item) => {
    const {
      id,
      schedulingData: { schedulingDate, schedulingEndDate },
      coreData: {
        contacts,
        description,
        referentialData: {
          customerName,
          location: { contact, address, city, zipCode, name },
        },
      },
    } = item;
    const event = {
      id,
      company: customerName,
      fullName: contact,
      phone: contacts ? getPhoneEmailFromEventContacts(contacts, "PHONE") : "",
      hoursStart: getEventHours(schedulingDate),
      hoursEnd: getEventHours(schedulingEndDate),
      address,
      cp: zipCode,
      ville: city,
      building: "",
      stage: "",
      digicode: "",
      email: contacts ? getPhoneEmailFromEventContacts(contacts, "EMAIL") : "",
      codePostal: zipCode,
      siteName: name,
      contactName: contact,
      notesToConsider: description,
    };
    return event;
  });
};

const getPricePercent = (price, percent) => {
  return (percent / 100) * price;
};

export const calculateCarpetValue = (
  purchasingPrice,
  purchaseDate,
  surface
) => {
  if (purchasingPrice > 0) {
    try {
      const purchaseYear = new Date(purchaseDate).getFullYear();
      const currentYear = new Date().getFullYear();
      let estimatedPrice;

      for (let i = 0; i <= currentYear - purchaseYear; i++) {
        if (i === 0) {
          estimatedPrice =
            purchasingPrice - getPricePercent(purchasingPrice, 35);
        } else {
          estimatedPrice = estimatedPrice - getPricePercent(estimatedPrice, 10);
        }
      }
      return estimatedPrice * surface;
    } catch (err) {
      console.log(err);
      return 0;
    }
  } else {
    return 0;
  }
};

export const numberWithSpace = (number) => {
  var numberDecimal = (Math.round(number * 100) / 100).toFixed(2);
  var numberString = numberDecimal.toString();
  if (
    numberString[numberString.length - 1] == "0" &&
    numberString[numberString.length - 2] == "0"
  ) {
    numberString = numberString.slice(0, numberString.length - 3);
  }
  numberString = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return numberString;
};
