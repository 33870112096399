import { useState, useEffect, useContext } from "react";
import { calculateCarpetValue } from "src/utils/functions";
import styles from "./VenueInformation.module.css";
import { HiddenButton } from "./VenueInformation";
import { VENUE_INFO_ERRORS } from "src/utils/constants";
import { numberWithSpace } from "src/utils/functions";
import { AppContext } from "src/Contexts/AppContext";

export const CarpetEvaluation = ({
  enteredData,
  handleSetError,
  error,
  submitBtnRef,
  setScreenDataValid,
  handleData,
}) => {
  const [carpetPrice, setCarpetPrice] = useState(0);
  const [initialPrice, setInitialPrice] = useState(0);
  const [priceM2, setPriceM2] = useState("");
  const {
    carpetInfo: { dateFabricationMoquette },
    surface,
  } = enteredData;

  const {
    state: { selectedIdentification },
  } = useContext(AppContext);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (selectedIdentification) {
      setPriceM2(selectedIdentification.prixNeufMoquette);
      const price = calculateCarpetValue(
        selectedIdentification.prixNeufMoquette,
        dateFabricationMoquette,
        Number(surface)
      );
      var initialPriceTemp = Number(selectedIdentification.prixNeufMoquette);
      setInitialPrice(initialPriceTemp.toFixed(2));
      setCarpetPrice(price.toFixed(2));
      setScreenDataValid(true);
    }

    if (enteredData["initialPrice"]) {
      setPriceM2(enteredData["initialPrice"]);
      const price = calculateCarpetValue(
        enteredData["initialPrice"],
        dateFabricationMoquette,
        Number(surface)
      );
      var initialPriceTemp = Number(enteredData["initialPrice"]);
      setInitialPrice(initialPriceTemp.toFixed(2));
      setCarpetPrice(price.toFixed(2));
      setScreenDataValid(true);
    }
  }, []);

  const handleInputChange = (e) => {
    setPriceM2(e.target.value);
    const price = calculateCarpetValue(
      e.target.value,
      dateFabricationMoquette,
      Number(surface)
    );
    var initialPriceTemp = Number(e.target.value);
    setInitialPrice(initialPriceTemp.toFixed(2));
    setCarpetPrice(price.toFixed(2));
    setScreenDataValid(true);
  };

  const handleSubmit = () => {
    if (carpetPrice) {
      setScreenDataValid(true);
      handleData("carpetValue", carpetPrice);
      handleData("initialPrice", initialPrice);
    } else {
      // handleData("surface", carpetArea);
      // } else {
      handleSetError({ carpetEvaluation: VENUE_INFO_ERRORS.carpetEvaluation });
    }
  };

  return (
    <div className={styles.itemContainer}>
      <p>
        Calcul de 35% de décote la première année puis 10% à partir de la
        deuxième année
      </p>
      <div style={{ margin: "2rem 0" }}>
        <input
          className="main-input"
          placeholder="Prix du neuf au m2"
          value={priceM2}
          onChange={handleInputChange}
          type="number"
        />
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "8rem" }}>
          <h1 style={{ fontWeight: "400" }}>Estimation prix rachat</h1>
        </div>
        <h1 style={{ color: "var(--green)" }}>
          {numberWithSpace(carpetPrice / surface)} €/m²
        </h1>
      </div>
      {error && (
        <span className={styles.formError}>{error?.carpetEvaluation}</span>
      )}

      <HiddenButton
        btnRef={submitBtnRef}
        type="submit"
        onClick={handleSubmit}
      />
    </div>
  );
};
