import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "src/components/CreateClient/CreateClient.module.css";
import Button from "../button/Button";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { createClientFormSchema } from "./validationSchema";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AppContext } from "src/Contexts/AppContext";
import { getCommercialsList } from "src/services/apiService";

import Building from "src/assets/icons/building_blanc.svg";
import Contact from "src/assets/icons/contact_blanc.svg";

export const FormInput = ({
  register,
  error,
  className,
  placeholder,
  fieldName,
  editValue = null,
}) => {
  const inputClasses = className || `main-input ${styles.formInput}`;
  return (
    <div className={styles.formInputContainer}>
      <input
        placeholder={placeholder}
        className={inputClasses}
        type="text"
        name={fieldName}
        {...register(fieldName)}
        defaultValue={editValue}
      />
      {error && <p className={styles.formError}>{error}</p>}
    </div>
  );
};

export const CreateClientForm = ({
  handleCreateAccount,
  handleUpdateAccount,
  error,
  edit,
}) => {
  const inputClass = `main-input ${styles.formInput}`;
  let { id } = useParams();
  const navigate = useNavigate();
  const [commercials, setCommercials] = useState([]);
  const [commercialSelectedID, setCommercialSelectedID] = useState([]);
  const [currentAddressInfos, setCurrentAddressInfos] = useState([]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      commercial: null,
    },
    resolver: yupResolver(createClientFormSchema),
    mode: "onSubmit",
    reValidateMode: "onSubmit",
  });

  const location = useLocation();

  const onSubmit = (data) => {
    if (edit) {
      data = [{ ...data, id: id }];
      handleUpdateAccount(data[0]);
    } else {
      handleCreateAccount(data);
    }
  };

  const errorCommercials = errors?.commercial?.message;

  const {
    getClientById,
    state: { enableViewMore, client },
  } = useContext(AppContext);

  useEffect(() => {
    if (edit) {
      const loadInfos = async () => {
        getClientById(id);
        var commercials = await getCommercialsList();

        var curCommercialID = client.commercial;
        commercials.forEach(function (commercial, i) {
          if (commercial.id === curCommercialID) {
            commercials.splice(i, 1);
            commercials.unshift(commercial);
          }
        });
        setCommercials(commercials);
        setCommercialSelectedID(commercials[0].id);
        setValue("commercial", commercials[0].id);
      };
      loadInfos();
    } else {
      const loadInfos = async () => {
        var commercials = await getCommercialsList();
        setCommercials(commercials);
        setCommercialSelectedID(commercials[0].id);
        setValue("commercial", commercials[0].id);
      };
      loadInfos();
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <select
        name="commercial"
        {...register("commercial")}
        className={styles.commercialsSelect}
        onChange={(e) => setCommercialSelectedID(e.target.value)}
        value={commercials.length > 0 ? commercialSelectedID : null}
      >
        <option value="" disabled>
          Commercial référent
        </option>

        {commercials.length &&
          commercials.map(({ id, first, last }) => (
            <option key={id} value={id}>
              {`${first} ${last}`}
            </option>
          ))}
      </select>
      {errorCommercials && (
        <p style={{ marginTop: "1rem" }} className={styles.formError}>
          {errorCommercials}
        </p>
      )}
      <div className={styles.infoContainer}>
        <div className={styles.subTitleContainer}>
          <div className={styles.greenRound}>
            <img src={Building} alt="building blanc" />
          </div>
          <div className={styles.subInfos}>
            <h2 className={styles.subTitle}>Informations</h2>
            <p className={styles.subTitleInfo}>
              Renseignez les valeurs ci-dessous
            </p>
          </div>
        </div>
        <FormInput
          placeholder="Nom social"
          className={inputClass}
          register={register}
          fieldName="nomSocial"
          error={errors?.nomSocial?.message}
          editValue={client?.company}
        />
        <FormInput
          placeholder="Adresse"
          className={inputClass}
          register={register}
          fieldName="adresse"
          error={errors?.nomSocial?.message}
          editValue={client?.address}
        />
        <FormInput
          placeholder="CP"
          className={inputClass}
          register={register}
          fieldName="cp"
          error={errors?.cp?.message}
          editValue={client?.zip}
        />
        <FormInput
          placeholder="Ville"
          className={inputClass}
          register={register}
          fieldName="ville"
          error={errors?.ville?.message}
          editValue={client?.city}
        />
        <FormInput
          placeholder="Numéro de téléphone"
          className={inputClass}
          register={register}
          fieldName="tel"
          error={errors?.tel?.message}
          editValue={client?.phone}
        />
        <FormInput
          placeholder="Email"
          className={inputClass}
          register={register}
          fieldName="email"
          error={errors?.email?.message}
          editValue={client?.email}
        />
        <FormInput
          placeholder="Numéro de SIRET"
          className={inputClass}
          register={register}
          fieldName="siret"
          error={errors?.siret?.message}
          editValue={client?.siret}
        />
        <FormInput
          placeholder="Numéro de TVA intracommunautaire"
          className={inputClass}
          register={register}
          fieldName="tva"
          error={errors?.tva?.message}
          editValue={client?.tva}
        />
        <div className={styles.subTitleContainer}>
          <div className={styles.greenRound}>
            <img src={Contact} alt="contact" />
          </div>
          <div className={styles.subInfos}>
            <h2 className={styles.subTitle}>Contact en interne</h2>
            <p className={styles.subTitleInfo}>
              Avec qui échangez-vous en interne?
            </p>
          </div>
        </div>
        <FormInput
          placeholder="Prénom"
          className={inputClass}
          type="text"
          register={register}
          error={errors?.first?.message}
          fieldName="first"
          editValue={client?.first}
        />
        <FormInput
          placeholder="Nom"
          className={inputClass}
          register={register}
          fieldName="last"
          error={errors?.last?.message}
          editValue={client?.last}
        />
        <FormInput
          placeholder="N° de téléphone"
          className={inputClass}
          register={register}
          fieldName="telContact"
          error={errors?.telContact?.message}
          editValue={client?.phoneContact}
        />
        <FormInput
          placeholder="Adresse email"
          className={inputClass}
          register={register}
          fieldName="emailContact"
          error={errors?.emailContact?.message}
          editValue={client?.emailContact}
        />
        {error && (
          <p
            className={styles.formError}
            style={{ textAlign: "center", marginBottom: "2rem" }}
          >
            {error}
          </p>
        )}
      </div>
      <div className="footerButtonsContainer">
        <div className={styles.backIconContainer} onClick={() => navigate(-1)}>
          <FontAwesomeIcon icon={faChevronLeft} />
        </div>
        <Button className={styles.footerCtaBtn} variant="primary" type="submit">
          {edit ? "Enregistrer" : "Enregistrer la fiche"}
        </Button>
      </div>
    </form>
  );
};
