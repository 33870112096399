import Routing from "src/routing/Routing";
import "./App.css";
import "react-html5-camera-photo/build/css/index.css";

import { Loading } from "./components/Loading/Loading";
import { ContextProvider } from "./Contexts/AppContext";
import ScrollUp from "./components/ScrollUp/ScrollUp.jsx";

function App() {
  return (
    <div style={{ minHeight: "100vh" }}>
      <ScrollUp>
        <ContextProvider>
          <Loading />
          <Routing />
        </ContextProvider>
      </ScrollUp>
    </div>
  );
}

export default App;
