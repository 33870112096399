import Header from "src/components/header/Header";
import ClientCard from "src/components/clientCard/ClientCard";

import { useNavigate, useLocation } from "react-router-dom";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import Building from "src/assets/icons/building_blanc.svg";
import Contact from "src/assets/icons/contact_blanc.svg";
import Button from "src/components/button/Button";
import axios from "axios";
import { useParams } from "react-router-dom";

import { useEffect, useContext } from "react";
import { AppContext } from "src/Contexts/AppContext";

import styles from "src/pages/navigation/FicheClient.module.css";

export default function FicheClient() {
  const { id: idClient } = useParams();
  let navigate = useNavigate();
  const location = useLocation();
  const card = location.state;

  const {
    getClientById,
    resetCreatedClientId,
    state: { client },
  } = useContext(AppContext);

  useEffect(() => {
    resetCreatedClientId();
    const loadClient = async () => {
      var response = await getClientById(idClient);
    };
    loadClient();
  }, []);

  const handleAction = () => {
    navigate(`/client/edit/${idClient}`, { state: client });
  };

  return (
    <>
      {client ? (
        <>
          <Header
            screenTarget={"/customer-database-home"}
            icon={faChevronLeft}
            iconAction={faPen}
            // action={`/client/edit/${idClient}`}
            handleAction={handleAction}
          >
            <div style={{ paddingLeft: "10px" }}>Fiche Client</div>
          </Header>
          <div className={styles.container}>
            <div className={styles.commercial}>
              {client && client.commercial && client.commercial !== -1
                ? `${client.commercialFirstName} ${client.commercialLastName}`
                : "Il n'y a pas de commercial référent"}
            </div>
            <div className={styles.infoContainer}>
              <div className={styles.subTitleContainer}>
                <div className={styles.greenRound}>
                  <img src={Building} alt="building blanc" />
                </div>
                <h2 className={styles.subTitle}>Informations</h2>
              </div>
              <div className={styles.infoField}>{client && client.company}</div>
              <div className={styles.infoField}>{client && client.address}</div>
              <div className={styles.infoField}>{client && client.zip}</div>
              <div className={styles.infoField}>{client && client.city}</div>
              <div className={styles.infoField}>{client && client.country}</div>
              <div className={styles.infoField}>{client && client.phone}</div>
              <div className={styles.infoField}>{client && client.email}</div>
              <div className={styles.infoField}>{client && client.siret}</div>
              <div className={styles.subTitleContainer}>
                <div className={styles.greenRound}>
                  <img src={Contact} alt="contact" />
                </div>
                <h2 className={styles.subTitle}>Contact en interne</h2>
              </div>
              <div className={styles.infoField}>{client && client.first}</div>
              <div className={styles.infoField}>{client && client.last}</div>
              <div className={styles.infoField}>
                {client && client.phoneContact}
              </div>
              <div
                className={styles.infoField}
                style={{ paddingBottom: "15px" }}
              >
                {client && client.emailContact}
              </div>
            </div>
            <div style={{ width: "70%", margin: "auto" }}>
              <Button
                className={styles.footerCtaBtn}
                variant="primary"
                type="submit"
                onClick={() => navigate(`/client-detail/${idClient}/0`)}
              >
                Voir le détail
              </Button>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
