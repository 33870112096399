// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".LoginValidation_container__-ICo9 {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.LoginValidation_subContainer__5OIae {\n  background: #fff;\n  padding: 58px 45px;\n  margin: 0 10px;\n}\n\n.LoginValidation_checkLogo__VgI6z {\n  display: block;\n  margin: 0 auto;\n  width: 70px;\n  height: 70px;\n}\n\n.LoginValidation_title__-PaRB {\n  margin-top: 30px;\n  margin-bottom: 20px;\n  line-height: 38px;\n  font-weight: 500;\n  font-size: 34px;\n  text-align: center;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/login/LoginValidation.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,cAAc;EACd,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;EACf,kBAAkB;AACpB","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.subContainer {\n  background: #fff;\n  padding: 58px 45px;\n  margin: 0 10px;\n}\n\n.checkLogo {\n  display: block;\n  margin: 0 auto;\n  width: 70px;\n  height: 70px;\n}\n\n.title {\n  margin-top: 30px;\n  margin-bottom: 20px;\n  line-height: 38px;\n  font-weight: 500;\n  font-size: 34px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "LoginValidation_container__-ICo9",
	"subContainer": "LoginValidation_subContainer__5OIae",
	"checkLogo": "LoginValidation_checkLogo__VgI6z",
	"title": "LoginValidation_title__-PaRB"
};
export default ___CSS_LOADER_EXPORT___;
