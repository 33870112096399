import styles from "src/components/VenueInformation/VenueInformation.module.css";
import { PageHeader } from "./PageHeader";
import userIcon from "src/assets/icons/user-white.svg";
import { HiddenButton } from "./VenueInformation";
import Button from "../button/Button";
import * as yup from "yup";
import ButtonSecondary from "src/components/button/ButtonSecondary";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { locationFormSchema, locationFormSchema2 } from "./validationSchema";
import { FormInput } from "../CreateClient/CreateClientForm";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/Contexts/AppContext";
import { getErrorText } from "src/components/CreateClient/validationSchema";
import { useParams, useLocation } from "react-router-dom";
import { OrakDatePicker } from "src/components/DatePicker/DatePicker";

export const Form = ({
  submitBtnRef,
  handleData,
  setScreenDataValid,
  handleSetError,
  error,
  adresse = null,
  enteredData = null,
  isSameSaleReview,
  currentAddressInfos = null,
  newIdentification = null,
  setNewIdentification = null,
}) => {
  const handleSubmitBtn = () => {
    handleData("location", {});
    setScreenDataValid(true);
  };
  const [isNewAddressActive, setIsNewAddressActive] = useState(true);
  const [addressArr, setAddressArr] = useState(null);
  const [liberationDate, setLiberationDate] = useState("");

  const {
    getAddressIndentificationByClientId,
    state: {
      clientContactData,
      selectedIdentification,
      client,
      addressList,
      infosIdentificationsCreated,
    },
  } = useContext(AppContext);

  const { id } = useParams();
  const { pathname: location } = useLocation();

  const [addressSelectedID, setAddressSelectedID] = useState("");

  useEffect(() => {
    const loadAddresses = async () => {
      var addresses = await getAddressIndentificationByClientId(
        id ? id : selectedIdentification.client_userID
      );
    };
    loadAddresses();

    if (newIdentification != null && enteredData["location"] != undefined) {
      const data = {
        addressID: newIdentification.addressID,
      };
      setFormData(data);
      setIsNewAddressActive(false);
      setAddressSelectedID(Number(newIdentification.addressID));
      setNewIdentification(null);
    }

    if (currentAddressInfos && enteredData["location"] == undefined) {
      const data = {
        name: currentAddressInfos.nomSocial,
        address: currentAddressInfos.adresse,
        cp: currentAddressInfos.cp,
        ville: currentAddressInfos.ville,
        emailContact: currentAddressInfos.emailContact,
        firstNameContact: currentAddressInfos.first,
        lastNameContact: currentAddressInfos.last,
        phoneContact: currentAddressInfos.telContact,
        name: currentAddressInfos.nomSocial,
        // emailContact: enteredData["location"].emailContact,
        // firstNameContact: enteredData["location"].firstNameContact,
        // floor: enteredData["location"].floor,
        // informations: enteredData["location"].informations,
        // lastNameContact: enteredData["location"].lastNameContact,
        // phoneContact: enteredData["location"].phoneContact,
        // place: enteredData["location"].place,
      };
      setFormData(data);
    }

    if (enteredData["location"]) {
      const data = {
        address: enteredData["location"].address,
        addressID: enteredData["location"].addressID,
        building: enteredData["location"].building,
        cp: enteredData["location"].cp,
        digicode: enteredData["location"].digicode,
        emailContact: enteredData["location"].emailContact,
        firstNameContact: enteredData["location"].firstNameContact,
        floor: enteredData["location"].floor,
        informations: enteredData["location"].informations,
        lastNameContact: enteredData["location"].lastNameContact,
        name: enteredData["location"].name,
        phoneContact: enteredData["location"].phoneContact,
        place: enteredData["location"].place,
        ville: enteredData["location"].ville,
      };
      setFormData(data);
    }
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (addressArr != null && addressArr.length > 0 && selectedIdentification) {
      setAddressSelectedID(selectedIdentification.addressID);
    } else if (addressArr != null && addressArr.length > 0) {
      setAddressSelectedID(addressArr[0].id);
    } else if (infosIdentificationsCreated) {
      setAddressSelectedID(infosIdentificationsCreated.infos.currentAddressID);
    }
  }, [isNewAddressActive]);

  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(
      isNewAddressActive ? locationFormSchema : locationFormSchema2
    ),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const onSubmit = (data) => {
    if (isNewAddressActive == false) {
      data.addressID = addressSelectedID;
    } else {
      data.addressID = "";
    }
    if (!Object.keys(errors).length) {
      setScreenDataValid(true);
      data.liberationDate = liberationDate != "" ? liberationDate : "";
      handleData("location", data, true);
    }
  };

  const setFormData = (data) => {
    Object.keys(data).forEach((key) => {
      setValue(key, data[key]);
    });
  };

  useEffect(() => {
    if (client) {
      const {
        first: firstNameContact,
        last: lastNameContact,
        phoneContact: phoneContact,
        emailContact,
      } = client;

      var data = {
        firstNameContact,
        lastNameContact,
        phoneContact,
        emailContact,
      };

      setFormData(data);
    }
  }, [client]);

  useEffect(() => {
    if (
      selectedIdentification &&
      (!enteredData || enteredData["location"] == undefined)
    ) {
      const { firstNameContact, lastNameContact, phoneContact, emailContact } =
        selectedIdentification;
      const data = {
        ...selectedIdentification.address,
        firstNameContact,
        lastNameContact,
        phoneContact,
        emailContact,
        floor: selectedIdentification.floor,
        place: selectedIdentification.place,
        informations: selectedIdentification.informations,
      };
      setFormData(data);
    }
  }, [selectedIdentification]);

  useEffect(() => {
    if (addressArr && addressArr.length > 0) {
      if (location.includes("study-identification")) {
        handleSetAddressIdentification(false);
      }
    }
    if (addressList && addressList.addresses) {
      const arrTemp = [];
      Object.keys(addressList.addresses).forEach(function (key, index) {
        arrTemp.push(addressList.addresses[key]);
      });
      setAddressArr(arrTemp);
    }
  }, [addressList]);

  const handleSetAddressIdentification = (value) => {
    setIsNewAddressActive(value);
    if (value === true) {
      setAddressSelectedID("");
    }
  };
  return (
    <div className={styles.itemContainer}>
      {addressArr && addressArr != -1 && addressArr.length > 0 && (
        <>
          <div className={styles.blockButton}>
            <ButtonSecondary
              onClick={() => handleSetAddressIdentification(true)}
              status={isNewAddressActive === true ? true : false}
            >
              Nouvelle adresse
            </ButtonSecondary>
            <ButtonSecondary
              onClick={() => handleSetAddressIdentification(false)}
              status={isNewAddressActive === false ? true : false}
            >
              Adresse déjà existante
            </ButtonSecondary>
          </div>
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        {addressArr && addressArr.length > 0 && isNewAddressActive === false && (
          <>
            <select
              className={styles.commercialsSelect}
              onChange={(e) => setAddressSelectedID(e.target.value)}
              defaultValue={
                selectedIdentification
                  ? selectedIdentification.addressID
                  : addressSelectedID
              }
            >
              {addressArr &&
                addressArr.map((ad, index) => {
                  return (
                    <option key={index} value={ad.id}>
                      {ad.address}
                    </option>
                  );
                })}
            </select>
          </>
        )}
        {isNewAddressActive === true && (
          <>
            <FormInput
              placeholder="Nom social"
              type="text"
              register={register}
              error={errors?.name?.message}
              fieldName="name"
            />
            <FormInput
              placeholder="Adresse"
              type="text"
              register={register}
              error={errors?.address?.message}
              fieldName="address"
            />
            <FormInput
              placeholder="CP"
              type="number"
              register={register}
              error={errors?.cp?.message}
              fieldName="cp"
            />
            <FormInput
              placeholder="Ville"
              type="text"
              register={register}
              error={errors?.ville?.message}
              fieldName="ville"
            />

            <FormInput
              placeholder="Bâtiment"
              type="text"
              register={register}
              error={errors?.building?.message}
              fieldName="building"
            />
            <FormInput
              placeholder="Digicode"
              type="text"
              register={register}
              error={errors?.digicode?.message}
              fieldName="digicode"
            />
            <div
              style={{
                width: "100%",
                borderBottom: "1px solid #252525",
                marginBottom: "2rem",
              }}
            >
              <OrakDatePicker
                value={liberationDate}
                handleSetDate={setLiberationDate}
                minDate={new Date()}
                placeholder="Date de Libération du bail"
                isClearable={true}
              />
            </div>
          </>
        )}
        <FormInput
          placeholder="Étage"
          type="text"
          register={register}
          error={errors?.floor?.message}
          fieldName="floor"
        />
        <FormInput
          placeholder="Lieu (bureau, machine à café, couloir...)"
          type="text"
          register={register}
          error={errors?.place?.message}
          fieldName="place"
        />
        <FormInput
          placeholder="Informations complémentaires"
          type="text"
          register={register}
          error={errors?.informations?.message}
          fieldName="informations"
        />
        <FormInput
          placeholder="Hauteur de quai"
          type="text"
          register={register}
          error={errors?.informations?.message}
          fieldName="platformHeight"
        />
        <PageHeader
          heading="Contact en interne"
          subHeading="Avec qui échangez-vous en interne ?"
          icon={userIcon}
        />
        <br></br>
        <FormInput
          style={{ marginTop: "1rem" }}
          placeholder="Prénom"
          type="text"
          register={register}
          error={errors?.firstNameContact?.message}
          fieldName="firstNameContact"
        />
        <FormInput
          register={register}
          error={errors?.lastNameContact?.message}
          fieldName="lastNameContact"
          placeholder="Nom"
          type="text"
        />
        <FormInput
          placeholder="N° de téléphone"
          type="text"
          register={register}
          error={errors?.phoneContact?.message}
          fieldName="phoneContact"
        />
        <FormInput
          placeholder="Adresse email"
          type="text"
          register={register}
          error={errors?.emailContact?.message}
          fieldName="emailContact"
        />

        <HiddenButton btnRef={submitBtnRef} type="submit" />
        {/* <HiddenButton btnRef={submitBtnRef} onClick={onSubmit} type="submit" /> */}
      </form>
    </div>
  );
};
