export const VALIDATION_ERRORS = {
  EMAIL: "L'e-mail doit être un e-mail valide",
  EMAIL_EMPTY: "L'e-mail est un champ obligatoire",
};

export const JWT_KEY = "orakAccessToken";

export const VENUE_INFO_ERRORS = {
  emptySurfaceArea: "Veuillez entrer la superficie",
  surfaceReview: "Veuillez choisir la source de l'estimation",
  easeOfCleaningFalseReason: "Veuillez entrer la raison",
  insuranceProvider: "Veuillez indiquer le nom du prestataire",
  carpetEvaluation: "Veuillez entrer un montant correct",
};

export const ROLES = {
  OPERATOR: "operator",
  COMMERCIAL: "Sales",
  CUSTOMER: "customer",
  ADMIN: "admin",
};

export const IDENTIFICATION_STATUSES = {
  TO_STUDY: "toStudy",
  IN_PROGRESS: "inProgress",
  TERMINATED: "terminated",
};
