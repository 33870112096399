import { useContext, useEffect, useState } from "react";
import { SelectNoInput } from "./AnnualContract";
import { SELECTION_OPTION } from "./constants";
import { SelectionBlock } from "./SelectionBlock";
import { VENUE_INFO_ERRORS } from "src/utils/constants";

import styles from "./VenueInformation.module.css";
import { HiddenButton } from "./VenueInformation";
import { AppContext } from "src/Contexts/AppContext";

export const EaseOfRemoval = ({
  handleData,
  setScreenDataValid,
  handleSetError,
  error,
  submitBtnRef,
  enteredData = null,
}) => {
  const {
    state: { selectedIdentification },
  } = useContext(AppContext);
  const [selectedBlock, setSelectedBlock] = useState(SELECTION_OPTION.OUI);
  const [reason, setReason] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (enteredData["easeOfRemoval"] == false) {
      setSelectedBlock(SELECTION_OPTION.NON);
      setReason(enteredData["easeOfRemovalFalseReason"]);
    }
  }, []);

  useEffect(() => {
    if (
      (selectedBlock === SELECTION_OPTION.NON && reason && reason.length > 0) ||
      selectedBlock === SELECTION_OPTION.OUI
    ) {
      setScreenDataValid(true);
    } else {
      setScreenDataValid(false);
    }
  }, [reason]);

  useEffect(() => {
    if (selectedIdentification && enteredData["easeOfRemoval"] == undefined) {
      setSelectedBlock(
        selectedIdentification.easeOfRemoval
          ? SELECTION_OPTION.OUI
          : SELECTION_OPTION.NON
      );
      setReason(selectedIdentification.easeOfRemovalFalseReason);
    }
  }, [selectedIdentification]);

  const handleSubmit = () => {
    handleData("easeOfRemoval", selectedBlock === SELECTION_OPTION.OUI);
    if (selectedBlock === SELECTION_OPTION.OUI) {
      setScreenDataValid(true);
      return;
    }
    if (selectedBlock === SELECTION_OPTION.NON && reason) {
      handleData("easeOfRemovalFalseReason", reason);
      handleSetError({
        easeOfRemoval: null,
      });
      setScreenDataValid(true);
    } else {
      handleSetError({
        easeOfCleaning: VENUE_INFO_ERRORS.easeOfCleaningFalseReason,
      });
      setScreenDataValid(false);
    }
  };

  const handleSelectNo = () => {
    setSelectedBlock(SELECTION_OPTION.NON);
    handleSetError({
      easeOfRemoval: null,
    });
    setScreenDataValid(false);
  };

  const handleSelectYes = () => {
    setSelectedBlock(SELECTION_OPTION.OUI);
    handleSetError({ easeOfRemoval: undefined });
    setScreenDataValid(true);
  };

  const handleInput = (e) => setReason(e.target.value);

  return (
    <>
      <div className={styles.itemContainer}>
        <div className={styles.easeOfCleaningContainer}>
          <SelectionBlock
            text={SELECTION_OPTION.OUI}
            handleClick={handleSelectYes}
            isSelected={selectedBlock === SELECTION_OPTION.OUI}
          />
          <SelectionBlock
            text={SELECTION_OPTION.NON}
            handleClick={handleSelectNo}
            isSelected={selectedBlock === SELECTION_OPTION.NON}
          />
        </div>
      </div>
      {selectedBlock === SELECTION_OPTION.NON && (
        <SelectNoInput
          placeholder="Dites-nous pourquoi"
          error={error?.easeOfCleaning}
          onChange={handleInput}
          value={reason}
        />
      )}
      <HiddenButton btnRef={submitBtnRef} onClick={handleSubmit} />
    </>
  );
};
