import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "src/Contexts/AppContext";
import Header from "src/components/header/Header";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import CartographiesList from "src/components/clientCard/CartographiesList";
import List from "src/components/clientCard/List";
import AttestationList from "src/components/clientCard/AttestationsList";
import Button from "src/components/button/Button";

import { numberWithSpace } from "src/utils/functions";

import styles from "./CustomerCard.module.css";
import { useParams, useNavigate } from "react-router-dom";

import { getUserRole } from "src/utils/functions";

export const SubLink = ({ name, active, action }) => {
  const style = active ? styles.subLinkActive : styles.subLink;
  return (
    <div className={style} onClick={action}>
      {name}
    </div>
  );
};

export default function CustomerCard() {
  const { subLinkId, id } = useParams();
  const navigate = useNavigate();
  const [checkSubList, setCheckSubList] = useState([true, false, false, false]);
  const [page, setPage] = useState(1);
  const [role, setRole] = useState(null);
  const [addressArr, setAddressArr] = useState(null);
  const [devisIsSage, setDevisIsSage] = useState(false);
  const [factureIsSage, setFactureIsSage] = useState(false);

  const arrTemp = window.location.pathname.split("/");
  const idClient = Number(arrTemp[2]);

  const {
    getClientById,
    getAccountBills,
    getAddressIndentificationByClientId,
    state: { client, accountBills, addressList },
  } = useContext(AppContext);

  const billsArray = ["devis", "devisSage", "factures", "factureSage"];

  useEffect(() => {
    getClientById(idClient);
    getAccountBills("1", billsArray[0]);

    getAddressIndentificationByClientId(idClient);
    if (subLinkId) {
      const subLinkTemp = [false, false, false, false];
      subLinkTemp[subLinkId] = true;
      setCheckSubList(subLinkTemp);
    }

    const userRole = getUserRole();
    setRole(userRole);
    if (userRole != "Sale") {
      handleSublist(4);
    }
  }, []);

  const handlePage = (page, type) => {
    getAccountBills(page, type);
    setPage(page);
  };

  const handleSublist = (id) => {
    const arrSubList = [false, false, false, false];
    arrSubList[id] = true;
    setDevisIsSage(false);
    setFactureIsSage(false);
    setCheckSubList(arrSubList);
    getAccountBills("1", billsArray[id]);
    setPage(1);
  };

  useEffect(() => {
    if (addressList && addressList.addresses) {
      const arrTemp = [];
      Object.keys(addressList.addresses).forEach(function (key, index) {
        arrTemp.push(addressList.addresses[key]);
      });
      setAddressArr(arrTemp);
    }
  }, [addressList]);

  const switchDevis = () => {
    setDevisIsSage(!devisIsSage);
    var type = "";
    if (devisIsSage == false) {
      type = "devisSage";
    } else {
      type = "devis";
    }
    handlePage("1", type);
  };

  const switchFacture = () => {
    setFactureIsSage(!factureIsSage);
    var type = "";
    if (factureIsSage == false) {
      type = "factureSage";
    } else {
      type = "facture";
    }
    handlePage("1", type);
  };

  return (
    <>
      <Header
        screenTarget={role === "customer" ? `/home` : `/client/${idClient}`}
        icon={faChevronLeft}
      >
        <div style={{ paddingLeft: "10px" }}>Fiche Client</div>
      </Header>
      <div className={styles.container}>
        <div className={styles.mainInfosContainer}>
          <div className={styles.mainInfosContainerBlock}>
            <p>
              Nombre <br /> de m2 récupérés
            </p>
            <p>
              {addressList["infos"]?.surfaceRecuperable
                ? numberWithSpace(addressList["infos"].surfaceRecuperable)
                : 0}
              <span> m2</span>
            </p>
          </div>
          <div className={styles.mainInfosContainerDivide}></div>
          <div className={styles.mainInfosContainerBlock}>
            <p>valeur récupérée correspondante</p>
            {addressList["infos"]?.valeurEstimative ? (
              <p>
                {numberWithSpace(
                  Number(addressList["infos"]?.valeurEstimative)
                )}{" "}
                <span>€</span>
              </p>
            ) : (
              <p className={styles.noValeur}>En cours de chiffrage</p>
            )}
          </div>
        </div>
      </div>
      <div className={styles.subLinksContainer}>
        {role == "Sales" || role == "admin" ? (
          <>
            <SubLink
              name={"Devis"}
              active={checkSubList[0]}
              action={() => handleSublist(0)}
            />
            <SubLink
              name={"Factures"}
              active={checkSubList[2]}
              action={() => handleSublist(2)}
            />
          </>
        ) : null}
        <SubLink
          name={"cartographies"}
          active={checkSubList[4]}
          action={() => handleSublist(4)}
        />
        <SubLink
          name={"attestations"}
          active={checkSubList[5]}
          action={() => handleSublist(5)}
        />
      </div>
      {checkSubList[0] && (
        <>
          <Button
            className={styles.switchBtn}
            variant="primary"
            onClick={switchDevis}
          >
            {devisIsSage == false
              ? "Afficher devis"
              : "Afficher devis e-commerce"}
          </Button>
          {devisIsSage ? (
            <List
              length={accountBills && accountBills[2] ? accountBills[2] : 0}
              type={"devisSage"}
              obj={accountBills ? accountBills[1] : null}
              handlePage={handlePage}
              page={page}
            />
          ) : (
            <List
              length={accountBills && accountBills[2] ? accountBills[2] : 0}
              type={"devis"}
              obj={accountBills ? accountBills[1] : null}
              handlePage={handlePage}
              page={page}
            />
          )}
        </>
      )}
      {checkSubList[2] && (
        <>
          <Button
            className={styles.switchBtn}
            variant="primary"
            onClick={switchFacture}
          >
            {factureIsSage == false
              ? "Afficher factures"
              : "Afficher factures Proforma"}
          </Button>
          {factureIsSage ? (
            <List
              length={accountBills && accountBills[4] ? accountBills[4] : 0}
              type={"factureSage"}
              obj={accountBills ? accountBills[3] : null}
              handlePage={handlePage}
              page={page}
            />
          ) : (
            <List
              length={accountBills && accountBills[4] ? accountBills[4] : 0}
              type={"factures"}
              obj={accountBills ? accountBills[3] : null}
              handlePage={handlePage}
              page={page}
            />
          )}
        </>
      )}

      {checkSubList[4] && (
        <CartographiesList idClient={idClient} addressArr={addressArr} />
      )}
      {checkSubList[5] && (
        <AttestationList idClient={idClient} addressArr={addressArr} />
      )}
      {role === "customer" && (
        <div className={styles.containerBtn}>
          <button
            onClick={() => navigate(`/client-contact/${id}/0`)}
            className={styles.btnOnline}
          >
            Commander une expertise
          </button>
          <button
            onClick={() => navigate(`/client-contact/${id}/1`)}
            className={styles.btnArchive}
          >
            Céder de la moquette
          </button>
        </div>
      )}
    </>
  );
}
