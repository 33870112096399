import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import Header from "../header/Header";
import Paper from "@material-ui/core/Paper";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import { useContext, useEffect, useState } from "react";

import styles from "./MyIdentifications.module.css";
import { IdentificationCard } from "./IdentifactionCard";
import { IDENTIFICATION_STATUSES } from "src/utils/constants";
import { AppContext } from "src/Contexts/AppContext";
import { Link, useNavigate } from "react-router-dom";

const STATUS_MAP = {
  TO_STUDY: 0,
  IN_PROGRESS: 1,
  TERMINATED: 2,
};

export const MyIdentifications = () => {
  const {
    state: { myIdentifications },
    getIdentifications,
  } = useContext(AppContext);

  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(STATUS_MAP.TO_STUDY);
  const [filteredIdentifications, setFilteredIdentifications] =
    useState(myIdentifications);

  useEffect(() => {
    if (myIdentifications.length > 0) {
      if (selectedTab === STATUS_MAP.TO_STUDY) {
        setFilteredIdentifications(
          myIdentifications.filter(
            ({ status }) => status === IDENTIFICATION_STATUSES.TO_STUDY
          )
        );
      } else if (selectedTab === STATUS_MAP.IN_PROGRESS) {
        setFilteredIdentifications(
          myIdentifications.filter(
            ({ status }) => status === IDENTIFICATION_STATUSES.IN_PROGRESS
          )
        );
      } else if (selectedTab === STATUS_MAP.TERMINATED) {
        setFilteredIdentifications(
          myIdentifications.filter(
            ({ status }) => status === IDENTIFICATION_STATUSES.TERMINATED
          )
        );
      }
    }
  }, [selectedTab, myIdentifications]);

  useEffect(() => {
    getIdentifications();
  }, []);

  return (
    <>
      <Header screenTarget={"/home"} icon={faChevronLeft}>
        Mes identifications
      </Header>
      <Paper square>
        <Tabs
          centered
          value={selectedTab}
          TabIndicatorProps={{ style: { background: "#259f64" } }}
          onChange={(event, newValue) => {
            setSelectedTab(newValue);
          }}
        >
          <Tab className={styles.tabItem} label="à étudier" />
          <Tab className={styles.tabItem} label="EN COURS" />
          <Tab className={styles.tabItem} label="Terminées" />
        </Tabs>
      </Paper>
      <div style={{ padding: "1rem" }}>
        {filteredIdentifications.map((item, index) => {
          if (item.status === "toStudy") {
            return (
              <Link
                key={index}
                to={`/study-identification/commercial/${item.id}`}
                // state={item}
              >
                <IdentificationCard customerData={item} />
              </Link>
            );
          } else if (item.status === "inProgress") {
            return (
              <Link
                key={index}
                to={`/in-progress-identification/commercial/${item.id}`}
                state={{ currentStatus: "inProgress" }}
                // state={item}
              >
                <IdentificationCard customerData={item} />
              </Link>
            );
          } else {
            return (
              <Link
                key={index}
                to={`/in-progress-identification/commercial/${item.id}`}
                state={{ currentStatus: "terminated" }}
              >
                <IdentificationCard customerData={item} />
              </Link>
            );
          }
        })}
      </div>
    </>
  );
};
