import { useContext } from "react";
import { AppContext } from "src/Contexts/AppContext";

import styles from "./Loading.module.css";
import { createPortal } from "react-dom";

const LoadingIcon = () => {
  return (
    <div className="spinner-container">
      <div className={styles.loadingSpinner}></div>
    </div>
  );
};

export const Loading = () => {
  const {
    state: { loading },
  } = useContext(AppContext);
  const isBrowser = typeof window !== "undefined";
  const domNode = isBrowser && document.getElementById("root");

  return (
    <div>
      {loading &&
        isBrowser &&
        domNode &&
        createPortal(
          <aside role="none" className={styles.loadingAnimation}>
            <LoadingIcon />
          </aside>,
          domNode
        )}
    </div>
  );
};
