import React, { useState } from "react";
import ButtonSecondary from "src/components/button/ButtonSecondary";
import Button from "src/components/button/Button";
import Header from "src/components/header/Header";

import calendarWhite from "src/assets/icons/calendar-white.svg";

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import styles from "src/components/ConfirmationScreen/ConfirmationScreen.module.css";

const BUTTON_TYPES = {
  YES: "yes",
  NO: "no",
};

export const ConfirmationScreen = ({
  screenName,
  mainHeading,
  onClickYes,
  onClickNo,
  icon,
  backToHome,
}) => {
  let navigate = useNavigate();
  const [activeButton, setActiveButton] = useState(BUTTON_TYPES.YES);

  const backButton = () => {
    console.log("backButton");
  };

  const onClickContinue = () => {
    if (activeButton === BUTTON_TYPES.YES) {
      onClickYes();
    } else {
      onClickNo();
    }
  };

  return (
    <>
      <Header screenTarget={"/home"} icon={faChevronLeft}>
        {screenName}
      </Header>
      <div className="container">
        <div className="layout-second">
          <div className={styles.subContainer}>
            <div className={styles.iconContainer}>
              <img src={icon} alt="check-logo" className={styles.checkLogo} />
            </div>

            <h1 className={styles.title}>{mainHeading}</h1>
            <div className="buttonContainer">
              <div className={styles.blockButton}>
                <ButtonSecondary
                  status={activeButton === BUTTON_TYPES.YES ? "active" : ""}
                  onClick={() => setActiveButton(BUTTON_TYPES.YES)}
                >
                  Oui
                </ButtonSecondary>
              </div>
              <div className={styles.blockButton}>
                <ButtonSecondary
                  status={activeButton === BUTTON_TYPES.NO ? "active" : ""}
                  onClick={() => setActiveButton(BUTTON_TYPES.NO)}
                >
                  Non
                </ButtonSecondary>
              </div>
            </div>
          </div>

          <div className="container-bottom">
            <div
              className="container-bottom__backButton"
              onClick={() => {
                backToHome == "true" ? navigate("/home") : navigate(-1);
              }}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </div>
            <Button variant="primary bottom" onClick={onClickContinue}>
              Continuer
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};
