import { useContext, useEffect, useState } from "react";
import { AppContext } from "src/Contexts/AppContext";
import Header from "src/components/header/Header";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import infoLieu from "src/assets/icons/info-lieu.svg";
import Button from "src/components/button/Button";

import { SELECTION_OPTION } from "src/components/VenueInformation/constants";
import { SelectionBlock } from "src/components/VenueInformation/SelectionBlock";

import styles from "src/components/VenueInformation/VenueInformation.module.css";

import { useLocation, useNavigate } from "react-router-dom";

export const SameAddress = ({}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const location = useLocation();
  const navigate = useNavigate();

  const [selectedBlock, setSelectedBlock] = useState(SELECTION_OPTION.OUI);
  const [reason, setReason] = useState(null);

  const handleSubmit = () => {
    if (selectedBlock === SELECTION_OPTION.OUI) {
      return;
    }
    if (selectedBlock === SELECTION_OPTION.NON) {
      return;
    }
  };

  const submitForm = () => {
    if (selectedBlock == "Oui") {
      navigate(`/creation-identification/${location.state.createdClientId}`, {
        state: { infos: location.state.infos },
      });
    } else {
      navigate(`/creation-identification/${location.state.createdClientId}`, {
        state: { infos: null },
      });
    }
  };

  return (
    <>
      <Header screenTarget={-1} icon={faChevronLeft}>
        Création fiche client
      </Header>

      <div className={styles.itemContainer}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <div className={styles.iconContainer}>
            <img src={infoLieu} alt="info-lieu-icon" />
          </div>
          <div style={{ marginLeft: "1.5rem" }}>
            <h2>
              L'adresse de facturation et du site d'identification sont-elles
              identique
            </h2>
            {/* <p style={{ fontWeight: "200" }}>{subHeading}</p> */}
          </div>
        </div>
        <div className={styles.easeOfCleaningContainer}>
          <SelectionBlock
            text={SELECTION_OPTION.OUI}
            handleClick={() => setSelectedBlock(SELECTION_OPTION.OUI)}
            isSelected={selectedBlock === SELECTION_OPTION.OUI}
          />
          <SelectionBlock
            text={SELECTION_OPTION.NON}
            handleClick={() => setSelectedBlock(SELECTION_OPTION.NON)}
            isSelected={selectedBlock === SELECTION_OPTION.NON}
          />
        </div>
      </div>
      <div style={{ width: "300px", margin: "20px auto" }}>
        <Button onClick={submitForm} variant="primary">
          Commencer l'identification
        </Button>
      </div>
    </>
  );
};

export default SameAddress;
