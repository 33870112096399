import React, { useEffect, useState } from "react";
import Header from "src/components/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  getAllProducts,
  getAllDropdown,
  getInfosReference,
  getIdentification,
  getProductInfos,
} from "src/services/apiService";

import ProductDetails from "./ProductDetails";
import IdentificationDetails from "./IdentificationDetails";

import CheckContainer from "src/components/listing/CheckContainer";
import ColorContainer from "src/components/listing/ColorContainer";
import QuantityContainer from "src/components/listing/QuantityContainer";

import styles from "src/pages/listing-products/ListingProducts.module.css";
import { SearchInput } from "src/components/SearchInput/SearchInput";
import { useParams } from "react-router-dom";

export default function DetailsProducts() {
  const [informations, setInformations] = useState(null);
  const { typeRef, referenceID } = useParams();

  const mainFetch = async () => {
    var response = null;
    if (typeRef == "identification") {
      response = await getIdentification(referenceID);
      var result = response.data.payload[0];
      var images = [];
      if (result.imagePath) {
        result.imagePath.forEach((image) => {
          images.push(image.filePath);
        });
      } else {
        images = null;
      }
      setInformations({
        roomAttendance: result.roomAttendance,
        surface_m2: result.surface_m2,
        surfaceReview: result.surfaceReview,
        easeOfCleaning: result.easeOfCleaning,
        easeOfCleaningFalseReason: result.easeOfCleaningFalseReason,
        easeOfRemoval: result.easeOfRemoval,
        easeOfRemovalFalseReason: result.easeOfRemovalFalseReason,
        productRating: result.productRating,
        cleaningContract: result.cleaningContract,
        cleaningContractWithOrak: result.cleaningContractWithOrak,
        cleaningContactWithoutOrak: result.cleaningContactWithoutOrak,
        productName: result.productName,
        productBrand: result.productBrand,
        poseurName: result.poseurName,
        proprietaireMoquette: result.proprietaireMoquette,
        dateFabricationMoquette: result.dateFabricationMoquette,
        prixNeufMoquette: result.prixNeufMoquette,
        estimationRachatMoquette: result.estimationRachatMoquette,
        allDocsZipURL: result.allDocsZipURL,
        brochureURL: result.brochureURL,
        ficheTechniqueURL: result.ficheTechniqueURL,
        image: images,
        identificationID: result.id,
        status: result.status,
      });
    } else {
      response = await getProductInfos(referenceID);
      var result = response.data.Table[0];
      var images = [];
      if (result.mainImage) {
        images.push(result.mainImage);
        if (result.additionalImage) {
          result.additionalImage
            .replace(/[\[\]"]/g, "")
            .split(",")
            .forEach((image) => {
              images.push(image);
            });
        }
      } else {
        images = null;
      }
      setInformations({
        images: images,
        title: result.title,
        brand: result.brand,
        sousCouche: result.sousCouche,
        isolationPhoniqueDB: result.isolationPhoniqueDB,
        coefAbsAccoustique: result.coefAbsAccoustique,
        classementFeu: result.classementFeu,
        brochureURL: result.brochureURL,
        ficheTechniqueURL: result.ficheTechniqueURL,
        allDocsZipURL: result.allDocsZipURL,
        alternative_title: result.alternative_title,
        colors: result.colors,
        dimensions: result.dimensions,
        fabricationAnnee: result.fabricationAnnee,
        poidsTotal_g_m2: result.poidsTotal_g_m2,
        pricePerUnit: result.pricePerUnit,
        reference: result.reference,
        quantityAvailable: result.quantityAvailable,
        minimumCommandeQty: result.minimumCommandeQty,
        delaisLivraisonJours: result.delaisLivraisonJours,
      });
    }
  };

  useEffect(() => {
    mainFetch();
  }, []);

  return (
    <>
      <Header screenTarget={-1} icon={faChevronLeft}>
        <div style={{ paddingLeft: "10px" }}>Détails du produit</div>
      </Header>
      {informations ? (
        <div>
          {typeRef == "identification" ? (
            <IdentificationDetails informations={informations} />
          ) : (
            <ProductDetails informations={informations} />
          )}
        </div>
      ) : null}
    </>
  );
}
