import React, { useEffect, useState } from "react";
import styles from "./Details.module.css";
import { numberWithSpace } from "src/utils/functions";
import Logo from "src/assets/icons/logo_noir.svg";
import * as API from "src/services/apiService";
import { useLocation } from "react-router-dom";
import { getUserRole } from "src/utils/functions";
import Button from "src/components/button/Button";
import {
  startingProcess,
  sendingRequestProcess,
} from "src/services/apiService";
import { useNavigate } from "react-router-dom";
import { Modal } from "src/components/Modal/Modal";

export default function IdentificationDetails({ informations }) {
  const [imageIdentification, setImageIdentification] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [role, setRole] = useState(null);

  const onClose = () => {
    setIsModalOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const getImage = async () => {
      if (informations.image) {
        let promises = [];
        for (let i = 0; i < informations.image.length; i++) {
          promises.push(
            await API.getPicture(
              informations.image[i],
              informations.identificationID
            )
          );
        }
        var result = await Promise.all(promises);
        setImageIdentification(result);
      }
    };
    getImage();
    const userRole = getUserRole();
    setRole(userRole);
  }, []);

  const handleStartingProcess = async () => {
    await startingProcess(informations.identificationID);
    navigate(
      `/in-progress-identification/commercial/${informations.identificationID}`,
      {
        state: { currentStatus: "inProgress" },
      }
    );
  };

  const handleRequestProcess = async () => {
    setIsSending(true);
    const responseRequest = await sendingRequestProcess(
      informations.identificationID
    );
    if (responseRequest.data.payload.status == "OK") {
      setIsModalOpen(true);
      setIsSending(false);
    }
  };

  return (
    <div>
      <Modal isOpen={isModalOpen} onClose={onClose}>
        <button
          className={styles.btnModal}
          onClick={() => setIsModalOpen(false)}
        >
          Une demande de mise en process à bien été envoyée.
        </button>
      </Modal>
      <div className={styles.title}>{informations.title}</div>
      <div className={styles.subTitle}>{informations.brand}</div>
      <div className={styles.mainContainer}>
        <div className={styles.imageContainer}>
          <img src={Logo} alt="" />
        </div>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
        >
          <div style={{ width: "150px", margin: "40px 20px" }}>
            <a
              target="_blank"
              href={`https://www.optimal-karpet.fr/api/Templates/getTemplateIdentification?identificationID=${informations.identificationID}`}
            >
              <Button variant="primary">Version PDF</Button>
            </a>
          </div>
        </div>
        <div className={styles.infosContainer}>
          <div className={styles.subTitle}>Identification</div>
          <div className={styles.ficheContainer}>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Nom du produit</div>
              <div className={styles.ficheValue}>
                {informations.productName}
              </div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Marque</div>
              <div className={styles.ficheValue}>
                {informations.productBrand}
              </div>
            </div>
            {informations.poseurName ? (
              <div className={styles.ficheSubContainer}>
                <div className={styles.ficheTitle}>Poseur de la moquette</div>
                <div className={styles.ficheValue}>
                  {informations.poseurName}
                </div>
              </div>
            ) : null}
            {informations.proprietaireMoquette ? (
              <div className={styles.ficheSubContainer}>
                <div className={styles.ficheTitle}>
                  Propriétaire de la moquette
                </div>
                <div className={styles.ficheValue}>
                  {informations.proprietaireMoquette}
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles.ficheContainer}>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Fréquentation de la pièce</div>
              <div className={styles.ficheValue}>
                {informations.roomAttendance}
              </div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Surface</div>
              <div className={styles.ficheValue}>
                {informations.surface_m2} m²
              </div>
            </div>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Source de l'estimation</div>
              <div className={styles.ficheValue}>
                {informations.surfaceReview == "oeil"
                  ? "Estimation a l'oeil"
                  : "Verification par télémètre"}
              </div>
            </div>
          </div>
          <div className={styles.ficheContainer}>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>
                La moquette se nettoie-t-elle facilement ?
              </div>
              <div className={styles.ficheValue}>
                {informations.easeOfCleaning == 1 ? "Oui" : "Non"}
              </div>
            </div>
            {informations.easeOfCleaning == 0 ? (
              <div className={styles.ficheSubContainer}>
                <div className={styles.ficheTitle}>Raison</div>
                <div className={styles.ficheValue}>
                  {informations.easeOfCleaningFalseReason}
                </div>
              </div>
            ) : null}
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>
                La dalle se dépose-t-elle facilement ?
              </div>
              <div className={styles.ficheValue}>
                {informations.easeOfRemoval == 1 ? "Oui" : "Non"}
              </div>
            </div>
            {informations.easeOfRemoval == 0 ? (
              <div className={styles.ficheSubContainer}>
                <div className={styles.ficheTitle}>Raison</div>
                <div className={styles.ficheValue}>
                  {informations.easeOfRemovalFalseReason}
                </div>
              </div>
            ) : null}
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>Potentiel de réemploi</div>
              <div className={styles.ficheValue}>
                {informations.productRating}
              </div>
            </div>
          </div>
          <div className={styles.ficheContainer}>
            <div className={styles.ficheSubContainer}>
              <div className={styles.ficheTitle}>
                Contrat de nettoyage annuel ?
              </div>
              <div className={styles.ficheValue}>
                {informations.cleaningContract == 1 ? "Oui" : "Non"}
              </div>
            </div>
            {informations.cleaningContract == 1 ? (
              informations.cleaningContactWithoutOrak != "" ? (
                <div className={styles.ficheSubContainer}>
                  <div className={styles.ficheTitle}>Autre prestataire</div>
                  <div className={styles.ficheValue}>
                    {informations.cleaningContactWithoutOrak}
                  </div>
                </div>
              ) : (
                <div className={styles.ficheSubContainer}>
                  <div className={styles.ficheTitle}>Orak</div>
                  <div className={styles.ficheValue}>
                    {informations.cleaningContractWithOrak}
                  </div>
                </div>
              )
            ) : null}
          </div>
          <div className={styles.ficheContainer}>
            {informations.dateFabricationMoquette.slice(0, 4) > 1970 ? (
              <div className={styles.ficheSubContainer}>
                <div className={styles.ficheTitle}>Date de fabrication</div>
                <div className={styles.ficheValue}>
                  {`${informations.dateFabricationMoquette.slice(
                    8,
                    10
                  )}/${informations.dateFabricationMoquette.slice(
                    5,
                    7
                  )}/${informations.dateFabricationMoquette.slice(0, 4)}`}
                </div>
              </div>
            ) : null}
            {informations.prixNeufMoquette > 0 ? (
              <>
                <div className={styles.ficheSubContainer}>
                  <div className={styles.ficheTitle}>Prix neuf</div>
                  <div className={styles.ficheValue}>
                    {informations.prixNeufMoquette} €/m² HT
                  </div>
                </div>
                <div className={styles.ficheSubContainer}>
                  <div className={styles.ficheTitle}>
                    Estimation prix rachat
                  </div>
                  <div className={styles.ficheValue}>
                    {numberWithSpace(
                      Number(informations.estimationRachatMoquette) /
                        Number(informations.surface_m2)
                    )}{" "}
                    €/m² HT
                  </div>
                </div>
              </>
            ) : null}
          </div>
          {informations.brochureURL ||
          informations.allDocsZipURL ||
          informations.ficheTechniqueURL ? (
            <div className={styles.ficheContainer}>
              <div className={styles.subTitle}>Documents</div>
              {informations.allDocsZipURL ? (
                <div className={styles.ficheSubContainer}>
                  <div className={styles.ficheTitle}>
                    Donnée technique complète
                  </div>
                  <a
                    target="_blank"
                    href={`https://orak-data.s3.eu-west-3.amazonaws.com/${informations.allDocsZipURL}`}
                    className={styles.ficheLink}
                  >
                    Consulter
                  </a>
                </div>
              ) : null}
              {informations.brochureURL ? (
                <div className={styles.ficheSubContainer}>
                  <div className={styles.ficheTitle}>Brochure</div>
                  <a
                    target="_blank"
                    href={`https://orak-data.s3.eu-west-3.amazonaws.com/${informations.brochureURL}`}
                    className={styles.ficheLink}
                  >
                    Consulter
                  </a>
                </div>
              ) : null}
              {informations.ficheTechniqueURL ? (
                <div className={styles.ficheSubContainer}>
                  <div className={styles.ficheTitle}>Fiche technique</div>
                  <a
                    target="_blank"
                    href={`https://orak-data.s3.eu-west-3.amazonaws.com/${informations.ficheTechniqueURL}`}
                    className={styles.ficheLink}
                  >
                    Consulter
                  </a>
                </div>
              ) : null}
            </div>
          ) : null}
          {role == "Sales" && informations.status == "pending" ? (
            <div className={styles.ficheContainer}>
              <Button variant="primary" onClick={handleStartingProcess}>
                lancer le process de récupération
              </Button>
            </div>
          ) : null}
          {role == "customer" && informations.status == "pending" ? (
            <div className={styles.ficheContainer}>
              <Button
                variant="primary"
                onClick={isSending == true ? null : handleRequestProcess}
              >
                {isSending == true
                  ? "Demande en cours..."
                  : "Demande de demarrage de process"}
              </Button>
            </div>
          ) : null}
        </div>
        <div className={styles.imagesContainer}>
          {imageIdentification ? (
            <div className={styles.imgContainer}>
              {imageIdentification &&
                imageIdentification.map((image, index) => {
                  return (
                    <div key={index} className={styles.image}>
                      <img src={image} />
                    </div>
                  );
                })}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}
