import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faHome } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";

export default function Header({
  children,
  icon,
  screenTarget,
  iconAction = null,
  action = null,
  handleAction,
}) {
  let navigate = useNavigate();

  const handleClick = () => {
    navigate(screenTarget);
  };

  // const handleAction = () => {
  //   navigate(action);
  // };

  return (
    <div className="header">
      <FontAwesomeIcon
        icon={icon}
        className="header__icon"
        onClick={() => handleClick()}
      />
      <div>{children}</div>
      {iconAction ? (
        <>
          <FontAwesomeIcon
            icon={iconAction}
            className="action__header__icon__2"
            onClick={() => handleAction()}
          />
          <FontAwesomeIcon
            icon={faHome}
            className="action__header__icon"
            onClick={() => navigate("/home")}
          />
        </>
      ) : (
        <FontAwesomeIcon
          icon={faHome}
          className="action__header__icon"
          onClick={() => navigate("/home")}
        />
      )}
    </div>
  );
}
