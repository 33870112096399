// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Login_container__UnOzY {\n  height: 100%;\n  background: #fff;\n  padding: 58px 55px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.Login_mainLogo__DLA80 {\n  width: 100%;\n}\n\n.Login_title__Pqf\\+z {\n  margin-top: 48px;\n  margin-bottom: 15px;\n  line-height: 38px;\n  font-weight: 500;\n  font-size: 34px;\n}\n\n.Login_email-input__oPW-z {\n  margin-bottom: 0.5rem;\n}\n\n.Login_formError__TwnQ3 {\n  font-size: 0.9rem;\n  color: #ce202f;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/login/Login.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;EACnB,iBAAiB;EACjB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".container {\n  height: 100%;\n  background: #fff;\n  padding: 58px 55px;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n\n.mainLogo {\n  width: 100%;\n}\n\n.title {\n  margin-top: 48px;\n  margin-bottom: 15px;\n  line-height: 38px;\n  font-weight: 500;\n  font-size: 34px;\n}\n\n.email-input {\n  margin-bottom: 0.5rem;\n}\n\n.formError {\n  font-size: 0.9rem;\n  color: #ce202f;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Login_container__UnOzY",
	"mainLogo": "Login_mainLogo__DLA80",
	"title": "Login_title__Pqf+z",
	"email-input": "Login_email-input__oPW-z",
	"formError": "Login_formError__TwnQ3"
};
export default ___CSS_LOADER_EXPORT___;
