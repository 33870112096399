import { useContext, useEffect, useState } from "react";
import star from "src/assets/icons/star.svg";
import starFilled from "src/assets/icons/star-filled.svg";

import styles from "src/components/VenueInformation/VenueInformation.module.css";
import { HiddenButton } from "./VenueInformation";
import { AppContext } from "src/Contexts/AppContext";

const RATING_TEXTS = {
  1: "Très Mauvais",
  2: "Mauvais",
  3: "Moyen",
  4: "Bien",
  5: "Très bien",
};

export const ProductRating = ({
  handleData,
  error,
  setScreenDataValid,
  submitBtnRef,
  enteredData = null,
}) => {
  const {
    state: { selectedIdentification },
  } = useContext(AppContext);

  useEffect(() => {
    if (selectedIdentification) {
      const userRating = Object.keys(RATING_TEXTS).find(
        (key) => RATING_TEXTS[key] === selectedIdentification.productRating
      );
      setRating(userRating);
    }
  }, [selectedIdentification]);

  const [rating, setRating] = useState(3);

  const handleSetRating = (selectedOption) => {
    setRating(selectedOption);
  };

  const handleSubmit = () => {
    handleData("productRating", RATING_TEXTS[rating]);
    setScreenDataValid(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    if (enteredData["productRating"]) {
      const userRating = Object.keys(RATING_TEXTS).find(
        (key) => RATING_TEXTS[key] === enteredData["productRating"]
      );
      setRating(userRating);
    }
  }, []);

  return (
    <div className={styles.itemContainer}>
      <div className={styles.productRatingContainer}>
        {Array.from(Array(5)).map((item, index) => {
          return index < rating ? (
            <div key={index} className={styles.ratingStarContainer}>
              <img
                className={styles.ratingStar}
                src={starFilled}
                alt="rating-icon"
                onClick={() => handleSetRating(index + 1)}
              />
              {index + 1 === rating && (
                <p className={styles.ratingText}>{RATING_TEXTS[rating]}</p>
              )}
            </div>
          ) : (
            <div key={index}>
              <img
                className={styles.ratingStar}
                src={star}
                alt="rating-icon"
                onClick={() => handleSetRating(index + 1)}
              />
            </div>
          );
        })}
        <HiddenButton btnRef={submitBtnRef} onClick={handleSubmit} />
      </div>
    </div>
  );
};
