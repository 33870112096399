import React, { useEffect, useState } from "react";
import Header from "src/components/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { getAllProductsFilter, getAllDropdown } from "src/services/apiService";

import CheckContainer from "src/components/listing/CheckContainer";
import ColorContainer from "src/components/listing/ColorContainer";
import QuantityContainer from "src/components/listing/QuantityContainer";

import styles from "src/pages/listing-products/ListingProducts.module.css";
import { SearchInput } from "src/components/SearchInput/SearchInput";
import { useNavigate } from "react-router-dom";

export default function InProgressIdentification() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isColorFilterOpen, setIsColorFilterOpen] = useState(false);
  const [isQuantityFilterOpen, setIsQuantityFilterOpen] = useState(false);
  const [isBrandFilterOpen, setIsBrandFilterOpen] = useState(false);
  const [isLiberationFilterOpen, setIsLiberationFilterOpen] = useState(false);
  const [isFabricationFilterOpen, setIsFabricationFilterOpen] = useState(false);
  const [isTypeFilterOpen, setIsTypeFilterOpen] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [allProductsSearch, setAllProductsSearch] = useState([]);
  const [currentColors, setCurrentColors] = useState([]);
  const [currentM2, setCurrentM2] = useState([]);
  const [currentBrands, setCurrentBrands] = useState([]);
  const [currentLiberationDates, setCurrentLiberationDates] = useState([]);
  const [currentFabricationDates, setCurrentFabricationDates] = useState([]);
  const [currentTypes, setCurrentTypes] = useState([]);
  const [currentModels, setCurrentModels] = useState([]);

  const [colorChoosen, setColorChoosen] = useState("");
  const [minQuantityChoosen, setMinQuantityChoosen] = useState([]);
  const [maxQuantityChoosen, setMaxQuantityChoosen] = useState([]);
  const [minQuantityChoosenRc, setMinQuantityChoosenRc] = useState(0);
  const [maxQuantityChoosenRc, setMaxQuantityChoosenRc] = useState(100);
  const [brandsChoosen, setBrandsChoosen] = useState([]);
  const [liberationDatesChoosen, setLiberationDatesChoosen] = useState([]);
  const [fabricationDatesChoosen, setFabricationDatesChoosen] = useState([]);
  const [typeChoosen, setTypeChoosen] = useState([]);
  const [modelsChoosen, setModelsChoosen] = useState([]);

  const [search, setSearch] = useState("");
  const [resultSearch, setResultSearch] = useState([]);

  const navigate = useNavigate();

  const mainFetch = async () => {
    const responseProducts = await getAllProductsFilter();
    const responseDropDown = await getAllDropdown();
    responseProducts.data.Table.forEach((prod) => {
      prod.colors = prod.colors.replace(/\[|\]|'|"/g, "").split(",");
    });
    setAllProducts(responseProducts.data.Table);
    setAllProductsSearch(responseProducts.data.Table);

    const filterColorTemp = [];
    const quantityTemp = [];
    const brandTemp = [];
    const liberationTemp = [];
    const fabricationTemp = [];
    const modelTemp = [];
    const typeTemp = [];

    responseProducts.data.Table.forEach((product) => {
      product.colors.forEach((col) => {
        if (col != "") {
          filterColorTemp.push(col);
        }
      });
      quantityTemp.push(product.quantityAvailable);
      brandTemp.push(product.brand);
      modelTemp.push(product.title);
      typeTemp.push(product.productType);
      if (product.liberationDate != null && product.liberationDate != "") {
        liberationTemp.push(product.liberationDate);
      }
      if (
        product.fabricationAnnee != null &&
        product.fabricationAnnee != "" &&
        product.fabricationAnnee != 0
      ) {
        fabricationTemp.push(product.fabricationAnnee);
      }
    });

    // setting colors

    const filteredColorTemp = [...new Set(filterColorTemp)];
    const finalColors = [];
    responseDropDown.data.payload.data["ColorsList"].forEach((color) => {
      if (filteredColorTemp.some((current) => current == color.id) == true) {
        finalColors.push(color);
      }
    });
    setCurrentColors(finalColors);

    // setting m2

    const filteredQuantity = [...new Set(quantityTemp)];
    filteredQuantity.sort((a, b) => {
      return a - b;
    });
    setCurrentM2(filteredQuantity);
    if (localStorage.getItem("orakFiltersMaxQuantity")) {
      var min = localStorage.getItem("orakFiltersMinQuantity");
      var max = localStorage.getItem("orakFiltersMaxQuantity");
      handleChangeQuantity([min, max]);
      setMinQuantityChoosen(
        Math.round((min * filteredQuantity[filteredQuantity.length - 1]) / 100)
      );
      setMaxQuantityChoosen(
        Math.round((max * filteredQuantity[filteredQuantity.length - 1]) / 100)
      );
    } else {
      handleChangeQuantity([
        filteredQuantity[0],
        filteredQuantity[filteredQuantity.length - 1],
      ]);
      setMinQuantityChoosen(filteredQuantity[0]);
      setMaxQuantityChoosen(filteredQuantity[filteredQuantity.length - 1]);
    }

    // setting brand

    const filteredBrands = [...new Set(brandTemp)];
    filteredBrands.sort();
    setCurrentBrands(filteredBrands);

    // setting model

    const filteredModels = [...new Set(modelTemp)];
    filteredModels.sort();
    setCurrentModels(filteredModels);

    // setting liberation date

    const filteredLiberation = [...new Set(liberationTemp)];
    filteredLiberation.sort();
    setCurrentLiberationDates(filteredLiberation);

    // setting fabrication date

    const filteredFabrication = [...new Set(fabricationTemp)];
    filteredFabrication.sort();
    setCurrentFabricationDates(filteredFabrication);

    // setting types

    const filteredType = [...new Set(typeTemp)];
    filteredType.sort();
    setCurrentTypes(filteredType);
    if (localStorage.getItem("orakFiltersColor")) {
      const colorStorage = localStorage.getItem("orakFiltersColor");
      setColorChoosen(colorStorage);
    }
    if (localStorage.getItem("orakFiltersBrand")) {
      const brandStorage = localStorage.getItem("orakFiltersBrand");
      setBrandsChoosen(brandStorage.split(","));
    }
    if (localStorage.getItem("orakFiltersLiberation")) {
      const liberationStorage = localStorage.getItem("orakFiltersLiberation");
      setLiberationDatesChoosen(liberationStorage.split(","));
    }
    if (localStorage.getItem("orakFiltersFabrication")) {
      const fabricationStorage = localStorage.getItem("orakFiltersFabrication");
      setFabricationDatesChoosen(fabricationStorage.split(","));
    }
    if (localStorage.getItem("orakFiltersType")) {
      const typeStorage = localStorage.getItem("orakFiltersType");
      setTypeChoosen(typeStorage.split(","));
    }
  };

  useEffect(() => {
    mainFetch();
  }, []);

  //All filter functions

  const handleChooseColor = (id) => {
    setColorChoosen(id);
  };

  const handleChangeQuantity = (value) => {
    setMinQuantityChoosen(
      Math.round((value[0] * currentM2[currentM2.length - 1]) / 100)
    );
    setMinQuantityChoosenRc(value[0]);
    setMaxQuantityChoosen(
      Math.round((value[1] * currentM2[currentM2.length - 1]) / 100)
    );
    setMaxQuantityChoosenRc(value[1]);
  };

  const handleCheck = (type, values, status) => {
    var valueTemp = [];
    if (type == "brand") {
      valueTemp = [...brandsChoosen];
      if (status == true) {
        valueTemp.push(values);
      } else {
        valueTemp = valueTemp.filter((current) => current != values);
      }
      setBrandsChoosen(valueTemp);
    } else if (type == "liberation") {
      var valueTemp = [...liberationDatesChoosen];
      if (status == true) {
        valueTemp.push(values);
      } else {
        valueTemp = valueTemp.filter((current) => current != values);
      }
      setLiberationDatesChoosen(valueTemp);
    } else if (type == "fabrication") {
      var valueTemp = [...fabricationDatesChoosen];
      if (status == true) {
        valueTemp.push(values);
      } else {
        valueTemp = valueTemp.filter((current) => current != values);
      }
      setFabricationDatesChoosen(valueTemp);
    } else if (type == "type") {
      var valueTemp = [...typeChoosen];
      if (status == true) {
        valueTemp.push(values);
      } else {
        valueTemp = valueTemp.filter((current) => current != values);
      }
      setTypeChoosen(valueTemp);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  // reseting function

  const handleReset = (type = null) => {
    setColorChoosen("");
    setMinQuantityChoosen([currentM2[0]]);
    handleChangeQuantity([0, 100]);
    // setMaxQuantityChoosen([]);
    setBrandsChoosen([]);
    setLiberationDatesChoosen([]);
    setFabricationDatesChoosen([]);
    setModelsChoosen([]);
    setTypeChoosen([]);
    if (type != "search") {
      setSearch("");
    }
  };

  //   active filter

  useEffect(() => {
    if (search.length <= 0) {
      var prevState = [...allProducts];
      var mainFilter = [...allProducts];
      const colorFilter = [];
      if (colorChoosen != "") {
        mainFilter.forEach((product) => {
          if (product.colors.some((current) => current == colorChoosen)) {
            colorFilter.push(product);
          }
        });
        mainFilter = [...colorFilter];
        prevState = [...colorFilter];
      }
      if (brandsChoosen.length > 0) {
        const brandTemp = [];
        mainFilter.forEach((product) => {
          if (brandsChoosen.includes(product.brand)) {
            brandTemp.push(product);
          }
        });
        mainFilter = [...brandTemp];
        prevState = [...brandTemp];
      } else {
        mainFilter = [...prevState];
      }

      if (liberationDatesChoosen.length > 0) {
        const liberationTemp = [];
        mainFilter.forEach((product) => {
          if (liberationDatesChoosen.includes(product.liberationDate)) {
            liberationTemp.push(product);
          }
        });
        mainFilter = [...liberationTemp];
        prevState = [...liberationTemp];
      } else {
        mainFilter = [...prevState];
      }

      if (fabricationDatesChoosen.length > 0) {
        const fabricationTemp = [];
        mainFilter.forEach((product) => {
          if (fabricationDatesChoosen.includes(product.fabricationAnnee)) {
            fabricationTemp.push(product);
          }
          mainFilter = [...fabricationTemp];
          prevState = [...fabricationTemp];
        });
      } else {
        mainFilter = [...prevState];
      }

      if (typeChoosen.length > 0) {
        const typeTemp = [];
        mainFilter.forEach((product) => {
          if (typeChoosen.includes(product.productType)) {
            typeTemp.push(product);
          }
          mainFilter = [...typeTemp];
          prevState = [...typeTemp];
        });
      } else {
        mainFilter = [...prevState];
      }

      const quantityTemp = [];
      mainFilter.forEach((product) => {
        if (
          (product.quantityAvailable >= minQuantityChoosen &&
            product.quantityAvailable <= maxQuantityChoosen) ||
          (minQuantityChoosen == currentM2[0] &&
            maxQuantityChoosen == currentM2[currentM2.length - 1])
        ) {
          quantityTemp.push(product);
        }
      });

      mainFilter = [...quantityTemp];
      setAllProductsSearch(mainFilter);
    }
  }, [
    colorChoosen,
    minQuantityChoosen,
    maxQuantityChoosen,
    brandsChoosen,
    liberationDatesChoosen,
    fabricationDatesChoosen,
    typeChoosen,
    search,
  ]);

  const handleNavigate = (productType, id) => {
    localStorage.setItem("orakFiltersColor", colorChoosen);
    localStorage.setItem("orakFiltersMinQuantity", minQuantityChoosenRc);
    localStorage.setItem("orakFiltersMaxQuantity", maxQuantityChoosenRc);
    localStorage.setItem("orakFiltersBrand", brandsChoosen);
    localStorage.setItem("orakFiltersLiberation", liberationDatesChoosen);
    localStorage.setItem("orakFiltersFabrication", fabricationDatesChoosen);
    localStorage.setItem("orakFiltersType", typeChoosen);

    navigate(
      `/detail-products/${productType
        .replace(/ /g, "-")
        .replace(/ô/g, "o")
        .replace(/é/g, "e")
        .toLowerCase()}/${id}`,
      { state: { from: "listing" } }
    );
  };

  useEffect(() => {
    if (search.length >= 1) {
      handleReset("search");
      var allProductsTemps = [];
      allProducts.forEach((product) => {
        if (
          product.brand.toLowerCase().includes(search.toLowerCase()) == true ||
          product.title.toLowerCase().includes(search.toLowerCase())
        ) {
          allProductsTemps.push(product);
        }
      });
      setAllProductsSearch(allProductsTemps);
    }
  }, [search]);

  return (
    <>
      <Header screenTarget={`/home`} icon={faChevronLeft}>
        <div style={{ paddingLeft: "10px" }}>Liste des produits</div>
      </Header>
      <div className={styles.mainContainer}>
        <div className={styles.filterContainer}>
          <div
            className={styles.filterHeader}
            onClick={() => setIsFilterOpen(!isFilterOpen)}
          >
            <div>Filtres</div>
            <div>
              <FontAwesomeIcon icon={isFilterOpen ? faXmark : faChevronDown} />
            </div>
          </div>
          {isFilterOpen ? (
            <div>
              <ColorContainer
                setFilter={setIsColorFilterOpen}
                getFilter={isColorFilterOpen}
                values={currentColors}
                handleChooseColor={handleChooseColor}
                colorChoosen={colorChoosen}
              />
              <QuantityContainer
                setFilter={setIsQuantityFilterOpen}
                getFilter={isQuantityFilterOpen}
                currentM2={currentM2}
                minQuantityChoosen={minQuantityChoosen}
                maxQuantityChoosen={maxQuantityChoosen}
                handleChangeQuantity={handleChangeQuantity}
                minQuantityChoosenRc={minQuantityChoosenRc}
                maxQuantityChoosenRc={maxQuantityChoosenRc}
              />
              <CheckContainer
                setFilter={setIsBrandFilterOpen}
                getFilter={isBrandFilterOpen}
                values={currentBrands}
                type={"brand"}
                handleCheck={handleCheck}
                description={"Marque"}
                arrChecked={brandsChoosen}
              />
              <CheckContainer
                setFilter={setIsLiberationFilterOpen}
                getFilter={isLiberationFilterOpen}
                values={currentLiberationDates}
                type={"liberation"}
                handleCheck={handleCheck}
                description={"Date de Libération du bail"}
                arrChecked={liberationDatesChoosen}
              />
              <CheckContainer
                setFilter={setIsFabricationFilterOpen}
                getFilter={isFabricationFilterOpen}
                values={currentFabricationDates}
                type={"fabrication"}
                handleCheck={handleCheck}
                description={"Année de fabrication"}
                arrChecked={fabricationDatesChoosen}
              />
              <CheckContainer
                setFilter={setIsTypeFilterOpen}
                getFilter={isTypeFilterOpen}
                values={currentTypes}
                type={"type"}
                handleCheck={handleCheck}
                description={"Catégories"}
                arrChecked={typeChoosen}
              />
            </div>
          ) : null}
        </div>
        <div className={styles.resultContainer}>
          <div className={styles.searchBarContainer}>
            <SearchInput
              placeholder="Cherchez une réference"
              handleChange={handleSearch}
            />
          </div>
          {allProductsSearch && allProductsSearch.length > 0 ? (
            allProductsSearch.map((product, index) => {
              return (
                <div
                  key={index}
                  className={styles.infoContainer}
                  onClick={() =>
                    handleNavigate(product.productType, product.id)
                  }
                >
                  <div>{product.brand}</div>
                  <div>
                    {product.title
                      .replace(/ - Réemploi/g, "")
                      .replace(/ - Neuve/g, "")}
                  </div>
                  <div>{product.productType}</div>
                </div>
              );
            })
          ) : (
            <div>
              <div>Aucun produit trouvé</div>
              <button className={styles.btnReset} onClick={handleReset}>
                Réinitialiser les filtres
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
