import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "src/Contexts/AppContext";
import Header from "src/components/header/Header";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useParams, useLocation, useNavigate, Link } from "react-router-dom";
import {
  getIdentification,
  getProductsList,
  addImagesToIdentification,
} from "src/services/apiService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InfoMoquette from "src/assets/icons/info_moquette.svg";
import { ProductPhotoContainer } from "src/components/VenueInformation/ProductPhotos";
import { ProgressBar } from "src/components/VenueInformation/ProgressBar";
import { getAllDropdown } from "src/services/apiService";
import Button from "src/components/button/Button";
import checkIcon from "src/assets/check.svg";
import { createNewProduct } from "src/services/apiService";
import * as API from "src/services/apiService";
import { Loading } from "src/components/Loading/Loading";
import { calculateCarpetValue } from "src/utils/functions";
import Select, { components } from "react-select";

import styles from "src/pages/in-progress-identification/OnLine.module.css";
import "./React-select.css";

export default function OnLine() {
  const navigate = useNavigate();
  const location = useLocation();
  const { identificationId } = useParams();
  const [step, setStep] = useState(1);
  const [allDropDown, setAllDropDown] = useState(null);
  const [error1, setError1] = useState(null);
  const [errorLongueur, setErrorLongueur] = useState(null);
  const [errorLargeur, setErrorLargeur] = useState(null);
  const [errorFabAnnee, setErrorFabAnnee] = useState(null);
  const [errorPoid, setErrorPoid] = useState(null);
  const [errorIso, setErrorIso] = useState(null);
  const [errorClassementFeu, setErrorClassementFeu] = useState(null);
  const [errorColor, setErrorColor] = useState(null);
  const [errorLivraison, setErrorLivraison] = useState(null);
  const [errorMaintenance, setErrorMaintenance] = useState(null);
  const [errorSousCouche, setErrorSousCouche] = useState(null);
  const [errorQuantityAvailable, setErrorQuantityAvailable] = useState(null);
  const [errorPricePerUnit, setErrorPricePerUnit] = useState(null);
  const [errorLocalisationUsine, setErrorLocalisationUsine] = useState(null);
  const [errorMinimumCommandeQty, setErrorMinimumCommandeQty] = useState(null);
  const [errorM2Reusable, setErrorM2Reusable] = useState(null);
  const [errorM2EnergyRecovered, setErrorM2EnergyRecovered] = useState(null);
  const [errorReference, setErrorReference] = useState(null);
  const [errorTitle, setErrorTitle] = useState(null);
  const [errorBrand, setErrorBrand] = useState(null);
  const [errorCoefAbsAccoustique, setErrorCoefAbsAccoustique] = useState(null);
  const [identificationData, setIdentificationData] = useState(null);
  const [photosData, setPhotoData] = useState(null);
  const [currentIdentification, setCurrentIdentification] = useState(null);
  const [allProducts, setAllProducts] = useState(null);
  const [options, setOptions] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOptionSelected, setIsOptionSelected] = useState(false);
  const [dimensionsTemp, setDimensionsTemp] = useState({
    longueur: "",
    largeur: "",
  });
  const [form, setForm] = useState({
    fabricationAnnee: "",
    sousCouche: "",
    poidsTotal_g_m2: "",
    isolationPhoniqueDB: "",
    classementFeu: "",
    colors: "",
    dimensions: "",
    quantityAvailable: "",
    minimumCommandeQty: "",
    pricePerUnit: "",
    localisationUsine: "",
    productType: "Réemploi",
    isAvailable: 1,
    brand: "",
    title: "",
    delaisLivraisonJours: "",
    maintenanceIncluseMois: "",
    isNew: 1,
    slug: "",
    coefAbsAccoustique: "",
    identificationId: identificationId,
    dateEnlevement_start: location.state[0].start,
    dateEnlevement_end: location.state[0].end,
    enlevement_entreprise: location.state[0].entreprise,
    enlevement_description: location.state[0].description,
    dateNettoyage_start: location.state[1].start,
    dateNettoyage_end: location.state[1].end,
    nettoyage_entreprise: location.state[1].entreprise,
    nettoyage_description: location.state[1].description,
    dateDepose_start: location.state[2].start,
    dateDepose_end: location.state[2].end,
    depose_entreprise: location.state[2].entreprise,
    depose_description: location.state[2].description,
    m2Reusable: "",
    m2EnergyRecovered: "",
    estimationRachatMoquetteFinal: 0,
    reference: "",
  });
  const [imagesFile, setImageFile] = useState();
  const [removeImage, setRemoveImage] = useState([]);

  const {
    state: { selectedIdentification, loading },
    setIdentification,
    getLoading,
    getLoaded,
  } = useContext(AppContext);

  const mainFetch = async () => {
    const res = await getAllDropdown();
    setAllDropDown(res.data.payload.data);
    const res2 = await getIdentification(identificationId);
    setIdentificationData(res2.data.payload[0]);
    await setIdentification(res2.data.payload[0]);

    const responseProducts = await getProductsList();
    setAllProducts(responseProducts.data.payload.data);
  };

  useEffect(() => {
    if (!location.state) {
      return navigate("/my-identifications");
    }
    mainFetch();
  }, []);

  useEffect(() => {
    if (form.m2Reusable) {
      var price = calculateCarpetValue(
        selectedIdentification.prixNeufMoquette,
        selectedIdentification.dateFabricationMoquette,
        form.m2Reusable
      );
      setForm({ ...form, estimationRachatMoquetteFinal: price });
    }
  }, [form.m2Reusable]);

  async function processUsers() {
    let result;
    let promises = [];
    for (let i = 0; i < selectedIdentification.imagePath.length; i++) {
      promises.push(
        await API.getPicture(
          selectedIdentification.imagePath[i].filePath,
          selectedIdentification.id
        )
      );
    }

    result = await Promise.all(promises);
    for (let i = 0; i < selectedIdentification.imagePath.length; i++) {
      selectedIdentification.imagePath[i].result = result[i];
    }
    return setPhotoData(selectedIdentification);
  }

  useEffect(() => {
    if (selectedIdentification && selectedIdentification.imagePath) {
      processUsers();
      // setCurrentIdentification(selectedIdentification);
    }
    if (selectedIdentification) {
      setForm({
        ...form,
        fabricationAnnee:
          selectedIdentification.dateFabricationMoquette.slice(0, 4) > 1970
            ? selectedIdentification.dateFabricationMoquette.slice(0, 4)
            : "",
        brand: selectedIdentification.productBrand,
        title: selectedIdentification.productName,
      });
    }
  }, [selectedIdentification]);

  // useEffect(() => {
  //   if (identificationData) {
  //   }
  // }, [identificationData]);

  const handleForm1 = (e) => {
    e.preventDefault();
    setError1(null);
    setErrorLongueur(null);
    setErrorLargeur(null);
    setErrorFabAnnee(null);
    setErrorPoid(null);
    setErrorIso(null);
    setErrorClassementFeu(null);
    setErrorColor(null);
    setErrorSousCouche(null);
    setErrorLivraison(null);
    setErrorMaintenance(null);
    setErrorCoefAbsAccoustique(null);
    setErrorM2Reusable(null);
    setErrorM2EnergyRecovered(null);
    setErrorReference(null);

    if (
      dimensionsTemp.longueur == "" ||
      dimensionsTemp.largeur == "" ||
      form.fabricationAnnee == "" ||
      form.sousCouche == "" ||
      form.poidsTotal_g_m2 == "" ||
      form.isolationPhoniqueDB == "" ||
      form.classementFeu == "" ||
      form.colors == "" ||
      form.delaisLivraisonJours == "" ||
      form.maintenanceIncluseMois == "" ||
      form.coefAbsAccoustique == "" ||
      form.reference == ""
    ) {
      if (dimensionsTemp.longueur == "") {
        setErrorLongueur("Longueur est un champ obligatoire");
      }
      if (dimensionsTemp.largeur == "") {
        setErrorLargeur("Largeur est un champ obligatoire");
      }
      if (form.fabricationAnnee == "") {
        setErrorFabAnnee("Année de fabrication est un champ obligatoire");
      }
      if (form.sousCouche == "") {
        setErrorSousCouche("Sous-couche est un champ obligatoire");
      }
      if (form.poidsTotal_g_m2 == "") {
        setErrorPoid("Poid total est un champ obligatoire");
      }
      if (form.isolationPhoniqueDB == "") {
        setErrorIso("Isolation phonique est un champ obligatoire");
      }
      if (form.classementFeu == "") {
        setErrorClassementFeu("Classement feu est un champ obligatoire");
      }
      if (form.colors == "") {
        setErrorColor("Couleur est un champ obligatoire");
      }
      if (form.delaisLivraisonJours == "") {
        setErrorLivraison("Délai de livraison est un champ obligatoire");
      }
      if (form.maintenanceIncluseMois == "") {
        setErrorMaintenance("Maintenance est un champ obligatoire");
      }
      if (form.coefAbsAccoustique == "") {
        setErrorMaintenance(
          "Coefficient d'absorption acoustique est un champ obligatoire"
        );
      }
      if (form.reference == "") {
        setErrorReference("Référence est un champ obligatoire");
      }
      return;
    }
    setForm({
      ...form,
      dimensions: `${dimensionsTemp.longueur}x${dimensionsTemp.largeur}`,
      slug: form.reference.replace(/ /g, "-"),
    });
    setStep(2);
  };

  const handleForm2 = (e) => {
    e.preventDefault();
    setErrorQuantityAvailable(null);
    setErrorPricePerUnit(null);
    setErrorLocalisationUsine(null);
    setErrorMinimumCommandeQty(null);

    if (
      form.quantityAvailable == "" ||
      form.pricePerUnit == "" ||
      form.localisationUsine == "" ||
      form.minimumCommandeQty == "" ||
      form.m2Reusable == "" ||
      form.m2EnergyRecovered == ""
    ) {
      if (form.quantityAvailable == "") {
        setErrorQuantityAvailable(
          "Quantité disponible est un champ obligatoire"
        );
      }
      if (form.pricePerUnit == "") {
        setErrorPricePerUnit("Prix unitaire est un champ obligatoire");
      }
      if (form.localisationUsine == "") {
        setErrorLocalisationUsine(
          "Localisation usine est un champ obligatoire"
        );
      }
      if (form.minimumCommandeQty == "") {
        setErrorMinimumCommandeQty("Commande minumum est un champ obligatoire");
      }
      if (form.m2Reusable == "") {
        setErrorM2Reusable(
          "Nombre de m2 réemployable est un champ obligatoire"
        );
      }
      if (form.m2EnergyRecovered == "") {
        setErrorM2EnergyRecovered(
          "Nombre de m2 valorisé énergétiquement est un champ obligatoire"
        );
      }
      return;
    }
    setStep(3);
  };

  const handleForm3 = async (e) => {
    e.preventDefault();
    setErrorTitle(null);
    setErrorBrand(null);
    getLoading();
    if (form.title == "" || form.brand == "") {
      if (form.title == "") {
        setErrorTitle("Nom du produit obligatoire");
      }
      if (form.brand == "") {
        setErrorBrand("Nom de la marque obligatoire");
      }
      getLoaded();
      return;
    }
    var newImagesFilePath = [];
    var oldImagesFilePath = [];
    var curImagesID = [];
    // setCurrentIdentification({ ...selectedIdentification });

    if (selectedIdentification.imagePath?.length > 0) {
      for (let i = 0; i < selectedIdentification.imagePath.length; i++) {
        var curImage = selectedIdentification.imagePath[i];
        if (!curImage.id && curImage.name) {
          var resImg = await addImagesToIdentification(
            identificationId,
            selectedIdentification.imagePath[i],
            true
          );
          var result = JSON.parse(resImg.data.payload);
          var resImgFilePath = result.filePath;
          newImagesFilePath.push(resImgFilePath);
          curImagesID.push(result.id);
        } else if (curImage.isDeleted == 0 && curImage.id) {
          oldImagesFilePath.push(curImage.result);
          curImagesID.push(curImage.id);
        }
      }
    }
    // fetch all full path from newImagesFilePath
    var newImagesFullPath = [];
    for (let i = 0; i < newImagesFilePath.length; i++) {
      let promises = [];

      promises.push(
        await API.getPicture(newImagesFilePath[i], selectedIdentification.id)
      );

      var result = await Promise.all(promises);
      newImagesFullPath.push(result[0]);
    }
    var finalImages = [...oldImagesFilePath, ...newImagesFullPath];
    var additionalImage = [];
    var mainPhoto = "";
    finalImages.map((photo, index) => {
      if (index === 0) {
        mainPhoto = photo;
      } else {
        additionalImage.push(photo);
      }
    });
    const res = await createNewProduct(
      form,
      curImagesID,
      mainPhoto,
      additionalImage
    );
    if (res.status == 200 && res.data.status == "OK") {
      getLoaded();
      setStep(4);
    }
  };

  useEffect(() => {
    if (allProducts) {
      const optionsTemp = [];
      allProducts.forEach((prod) => {
        optionsTemp.push({
          value: prod.id,
          label: `${prod.title} - ${prod.brand}`,
          image: prod.mainImage,
        });
      });
      setOptions(optionsTemp);
    }
  }, [allProducts]);

  const { SingleValue, Option } = components;

  const showInfosProduct = () => {
    setIsOptionSelected(true);
  };

  const IconSingleValue = (props) => (
    <SingleValue {...props}>
      <img
        src={props.data.image}
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      {props.data.label}
    </SingleValue>
  );

  const IconOption = (props) => (
    <Option {...props}>
      <img
        src={props.data.image}
        style={{
          height: "30px",
          width: "30px",
          borderRadius: "50%",
          marginRight: "10px",
        }}
      />
      {props.data.label}
    </Option>
  );

  const customStyles = {
    option: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }),
    singleValue: (provided) => ({
      ...provided,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    }),
  };

  const handleSelectProduct = (event) => {
    const productSelected = allProducts.filter(
      (prod) => prod.id == event.value
    )[0];
    setDimensionsTemp({
      ...dimensionsTemp,
      longueur: productSelected.dimensions.split("x")[0],
      largeur: productSelected.dimensions.split("x")[1],
    });
    setSelectedOption(event);
    var sousCoucheTemp = allDropDown.SousCouchesList.filter(
      (couche) => couche.name == productSelected.sousCouche
    );
    setForm({
      ...form,
      reference: productSelected.reference ? productSelected.reference : "",
      poidsTotal_g_m2: productSelected.poidsTotal_g_m2
        ? productSelected.poidsTotal_g_m2
        : "",
      isolationPhoniqueDB: productSelected.isolationPhoniqueDB
        ? productSelected.isolationPhoniqueDB
        : "",
      coefAbsAccoustique: productSelected.coefAbsAccoustique
        ? productSelected.coefAbsAccoustique
        : "",
      delaisLivraisonJours: productSelected.delaisLivraisonJours
        ? productSelected.delaisLivraisonJours
        : "",
      maintenanceIncluseMois: productSelected.maintenanceIncluseMois
        ? productSelected.maintenanceIncluseMois
        : "",
      sousCouche: sousCoucheTemp[0] ? sousCoucheTemp[0].id : "",
      classementFeu: productSelected.classementFeu
        ? productSelected.classementFeu
        : "",
      colors: productSelected.colors
        ? productSelected.colors
            .replace(/"/g, "")
            .replace(/\[/g, "")
            .replace(/\]/g, "")
            .split(",")[0]
        : "",
      minimumCommandeQty: productSelected.minimumCommandeQty
        ? productSelected.minimumCommandeQty
        : "",
      pricePerUnit: productSelected.pricePerUnit
        ? productSelected.pricePerUnit
        : "",
    });
    if (form.title == "") {
      setForm({ ...form, title: productSelected.title });
    }
    if (form.brand == "") {
      setForm({ ...form, brand: productSelected.brand });
    }
    showInfosProduct();
  };

  return (
    <>
      <Header screenTarget={`/my-identifications`} icon={faChevronLeft}>
        <div style={{ paddingLeft: "10px" }}>Mise en ligne moquette</div>
      </Header>
      {step >= 1 && step <= 3 && (
        <ProgressBar currentValue={step} maxValue={3} />
      )}
      {step === 1 && (
        <form onSubmit={handleForm1}>
          <div className={styles.container}>
            <div className={styles.infoMoquetteContainer}>
              <div className={styles.subTitleContainer}>
                <div className={styles.greenRound}>
                  <img src={InfoMoquette} alt="info moquette" />
                </div>
                <div className={styles.subInfos}>
                  <h2 className={styles.subTitle}>Informations moquette</h2>
                  <p className={styles.subTitleInfo}>
                    Confirmez les informations pré-saisies
                  </p>
                </div>
              </div>
              {allProducts ? (
                <div className={styles.formSelectContainer}>
                  <Select
                    placeholder="Choisissez un produit déjà existant"
                    value={selectedOption}
                    onChange={(e) => handleSelectProduct(e)}
                    options={options}
                    styles={customStyles}
                    components={{
                      SingleValue: IconSingleValue,
                      Option: IconOption,
                    }}
                  />
                </div>
              ) : null}
              {isOptionSelected == true ? (
                <>
                  <h2 className={styles.subTitleH2}>Fiche technique</h2>

                  <div className={styles.formInputContainer}>
                    <div className={styles.label}>Longueur :</div>
                    <input
                      type="number"
                      className={`main-input ${styles.formInput}`}
                      value={dimensionsTemp.longueur}
                      onChange={(e) =>
                        setDimensionsTemp({
                          ...dimensionsTemp,
                          longueur: e.target.value,
                        })
                      }
                    />
                    {errorLongueur && (
                      <div className={styles.textError}>{errorLongueur}</div>
                    )}
                    <div className={styles.label}>Largeur :</div>
                    <input
                      type="number"
                      className={`main-input ${styles.formInput}`}
                      value={dimensionsTemp.largeur}
                      onChange={(e) =>
                        setDimensionsTemp({
                          ...dimensionsTemp,
                          largeur: e.target.value,
                        })
                      }
                    />
                    {errorLargeur && (
                      <div className={styles.textError}>{errorLargeur}</div>
                    )}
                    <div className={styles.label}>Code produit :</div>
                    <input
                      type="text"
                      className={`main-input ${styles.formInput}`}
                      value={form.reference}
                      onChange={(e) =>
                        setForm({ ...form, reference: e.target.value })
                      }
                    />
                    {errorReference && (
                      <div className={styles.textError}>{errorReference}</div>
                    )}
                    <div className={styles.label}>Année de fabrication :</div>
                    <input
                      type="number"
                      className={`main-input ${styles.formInput}`}
                      value={form.fabricationAnnee}
                      onChange={(e) =>
                        setForm({ ...form, fabricationAnnee: e.target.value })
                      }
                    />
                    {errorFabAnnee && (
                      <div className={styles.textError}>{errorFabAnnee}</div>
                    )}
                    {allDropDown && (
                      <>
                        <div className={styles.label}>Sous-couche :</div>
                        <select
                          className={`main-input ${styles.formInput}`}
                          value={form.sousCouche}
                          onChange={(e) =>
                            setForm({ ...form, sousCouche: e.target.value })
                          }
                        >
                          <option value="-">Sous-couche</option>
                          {allDropDown.SousCouchesList.map((list, index) => {
                            return (
                              <option key={index} value={list.id}>
                                {list.name}
                              </option>
                            );
                          })}
                        </select>
                      </>
                    )}
                    {errorSousCouche && (
                      <div className={styles.textError}>{errorSousCouche}</div>
                    )}
                    <div className={styles.label}>Poid total :</div>
                    <input
                      type="number"
                      className={`main-input ${styles.formInput}`}
                      value={form.poidsTotal_g_m2}
                      onChange={(e) =>
                        setForm({ ...form, poidsTotal_g_m2: e.target.value })
                      }
                    />
                    {errorPoid && (
                      <div className={styles.textError}>{errorPoid}</div>
                    )}
                    <div className={styles.label}>Isolation phonique :</div>
                    <input
                      type="number"
                      className={`main-input ${styles.formInput}`}
                      value={form.isolationPhoniqueDB}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          isolationPhoniqueDB: e.target.value,
                        })
                      }
                    />
                    {errorIso && (
                      <div className={styles.textError}>{errorIso}</div>
                    )}
                    <div className={styles.label}>
                      Coefficient d'absorption acoustique :
                    </div>
                    <input
                      type="number"
                      className={`main-input ${styles.formInput}`}
                      value={form.coefAbsAccoustique}
                      onChange={(e) =>
                        setForm({ ...form, coefAbsAccoustique: e.target.value })
                      }
                    />
                    {errorMaintenance && (
                      <div className={styles.textError}>{errorMaintenance}</div>
                    )}
                    {allDropDown && (
                      <>
                        <div className={styles.label}>Classement feu :</div>
                        <select
                          className={`main-input ${styles.formInput}`}
                          value={form.classementFeu}
                          onChange={(e) =>
                            setForm({ ...form, classementFeu: e.target.value })
                          }
                        >
                          <option value="-">Classement feu</option>
                          {allDropDown.ClassementFeuList.map((list, index) => {
                            return (
                              <option key={index} value={list.name}>
                                {list.name}
                              </option>
                            );
                          })}
                        </select>
                        {errorClassementFeu && (
                          <div className={styles.textError}>
                            {errorClassementFeu}
                          </div>
                        )}
                        <div className={styles.label}>Couleurs :</div>
                        <select
                          className={`main-input ${styles.formInput}`}
                          value={form.colors}
                          onChange={(e) =>
                            setForm({ ...form, colors: e.target.value })
                          }
                        >
                          <option value="-">Couleur</option>
                          {allDropDown.ColorsList.map((list, index) => {
                            return (
                              <option
                                style={{ color: list.color }}
                                key={index}
                                value={list.id}
                              >
                                {list.name}
                              </option>
                            );
                          })}
                        </select>
                        {errorColor && (
                          <div className={styles.textError}>{errorColor}</div>
                        )}
                      </>
                    )}
                    <div className={styles.label}>
                      Délais livraison (jours) :
                    </div>
                    <input
                      type="number"
                      className={`main-input ${styles.formInput}`}
                      value={form.delaisLivraisonJours}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          delaisLivraisonJours: e.target.value,
                        })
                      }
                    />
                    {errorLivraison && (
                      <div className={styles.textError}>{errorLivraison}</div>
                    )}
                    <div className={styles.label}>
                      Maintenance inclus (mois) :
                    </div>
                    <input
                      type="number"
                      className={`main-input ${styles.formInput}`}
                      value={form.maintenanceIncluseMois}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          maintenanceIncluseMois: e.target.value,
                        })
                      }
                    />
                    {errorMaintenance && (
                      <div className={styles.textError}>{errorMaintenance}</div>
                    )}
                  </div>
                </>
              ) : (
                <div
                  style={{
                    paddingBottom: "10px",
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className={styles.productCtaBtn}
                    variant="primary"
                    onClick={showInfosProduct}
                  >
                    Ajouter un nouveau produit
                  </div>
                </div>
              )}
            </div>
            <div className={styles.footerButtonsContainer}>
              <div
                className={styles.backIconContainer}
                onClick={() =>
                  navigate(
                    `/in-progress-identification/commercial/${identificationId}`,
                    { state: { currentStatus: "inProgress" } }
                  )
                }
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              {isOptionSelected == true ? (
                <Button
                  className={styles.footerCtaBtn}
                  variant="primary"
                  type="submit"
                >
                  Continuer
                </Button>
              ) : null}
            </div>
          </div>
        </form>
      )}
      {step === 2 && (
        <form onSubmit={handleForm2}>
          <div className={styles.container}>
            <div className={styles.infoMoquetteContainer}>
              <div className={styles.subTitleContainer}>
                <div className={styles.greenRound}>
                  <img src={InfoMoquette} alt="info moquette" />
                </div>
                <div className={styles.subInfos}>
                  <h2 className={styles.subTitle}>Informations moquette</h2>
                  <p className={styles.subTitleInfo}>
                    Confirmez les informations pré-saisies
                  </p>
                </div>
              </div>
              <h2 className={styles.subTitleH2}>
                Tarif de revente & QTE disponible
              </h2>

              <div className={styles.formInputContainer}>
                <div className={styles.label}>Stock :</div>
                <input
                  type="number"
                  className={`main-input ${styles.formInput}`}
                  value={form.quantityAvailable}
                  onChange={(e) =>
                    setForm({ ...form, quantityAvailable: e.target.value })
                  }
                />
                {errorQuantityAvailable && (
                  <div className={styles.textError}>
                    {errorQuantityAvailable}
                  </div>
                )}
                <div className={styles.label}>Quantité minimum :</div>
                <input
                  type="number"
                  className={`main-input ${styles.formInput}`}
                  value={form.minimumCommandeQty}
                  onChange={(e) =>
                    setForm({ ...form, minimumCommandeQty: e.target.value })
                  }
                />
                {errorMinimumCommandeQty && (
                  <div className={styles.textError}>
                    {errorMinimumCommandeQty}
                  </div>
                )}
                <div className={styles.label}>Tarif à l'unité :</div>
                <input
                  type="number"
                  className={`main-input ${styles.formInput}`}
                  value={form.pricePerUnit}
                  onChange={(e) =>
                    setForm({ ...form, pricePerUnit: e.target.value })
                  }
                />
                {errorPricePerUnit && (
                  <div className={styles.textError}>{errorPricePerUnit}</div>
                )}
                <div className={styles.label}>Localisation usine :</div>
                <input
                  type="text"
                  className={`main-input ${styles.formInput}`}
                  value={form.localisationUsine}
                  onChange={(e) =>
                    setForm({ ...form, localisationUsine: e.target.value })
                  }
                />
                {errorLocalisationUsine && (
                  <div className={styles.textError}>
                    {errorLocalisationUsine}
                  </div>
                )}
                <div className={styles.label}>Nombre de m2 réemployable :</div>
                <input
                  type="number"
                  className={`main-input ${styles.formInput}`}
                  value={form.m2Reusable}
                  onChange={(e) =>
                    setForm({ ...form, m2Reusable: e.target.value })
                  }
                />
                {errorM2Reusable && (
                  <div className={styles.textError}>{errorM2Reusable}</div>
                )}
                <div className={styles.label}>
                  Nombre de m2 valorisé énergétiquement :
                </div>
                <input
                  type="number"
                  className={`main-input ${styles.formInput}`}
                  value={form.m2EnergyRecovered}
                  onChange={(e) =>
                    setForm({ ...form, m2EnergyRecovered: e.target.value })
                  }
                />
                {errorM2EnergyRecovered && (
                  <div className={styles.textError}>
                    {errorM2EnergyRecovered}
                  </div>
                )}
              </div>
            </div>
            <div className={styles.footerButtonsContainer}>
              <div
                className={styles.backIconContainer}
                onClick={() => setStep(1)}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              <Button
                className={styles.footerCtaBtn}
                variant="primary"
                type="submit"
              >
                Continuer
              </Button>
            </div>
          </div>
        </form>
      )}
      {step === 3 && (
        // <form onSubmit={handleForm3}>
        <>
          <div className={styles.container}>
            <div className={styles.infoMoquetteContainer}>
              <div className={styles.subTitleContainer}>
                <div className={styles.greenRound}>
                  <img src={InfoMoquette} alt="info moquette" />
                </div>
                <div className={styles.subInfos}>
                  <h2 className={styles.subTitle}>Informations moquette</h2>
                  <p className={styles.subTitleInfo}>
                    Confirmez les informations pré-saisies
                  </p>
                </div>
              </div>
              <h2 className={styles.subTitleH2}>Photos</h2>
              <div className={styles.formInputContainer}>
                <input
                  type="text"
                  className={`main-input ${styles.formInput}`}
                  placeholder="Nom du produit"
                  value={form.title}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      title: e.target.value,
                      slug: e.target.value.replace(/ /g, "-"),
                    })
                  }
                />
                {errorTitle && (
                  <div className={styles.textError}>{errorTitle}</div>
                )}
                <input
                  type="text"
                  className={`main-input ${styles.formInput}`}
                  placeholder="Marque : Interface"
                  value={form.brand}
                  onChange={(e) => setForm({ ...form, brand: e.target.value })}
                />
                {errorBrand && (
                  <div className={styles.textError}>{errorBrand}</div>
                )}
                <ProductPhotoContainer
                  curProductImages={
                    selectedIdentification != null
                      ? selectedIdentification.imagePath
                      : null
                  }
                  setImageFile={
                    selectedIdentification != null ? setImageFile : null
                  }
                  setRemoveImage={
                    selectedIdentification != null ? setRemoveImage : null
                  }
                  isReviewCommercial={true}
                />
              </div>
            </div>
            <div className={styles.footerButtonsContainer}>
              <div
                className={styles.backIconContainer}
                onClick={() => setStep(2)}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
              <Button
                className={styles.footerCtaBtn}
                variant="primary"
                onClick={handleForm3}
              >
                Terminer et publier
              </Button>
              {loading && <Loading />}
            </div>
            {error1 && (
              <div
                style={{
                  color: "red",
                  margin: "10px auto",
                  textAlign: "center",
                }}
              >
                {error1}
              </div>
            )}
          </div>
          {/* </form> */}
        </>
      )}
      {step === 4 && (
        <div className={styles.container}>
          <div className={styles.finalStepContainer}>
            <div className={styles.finalStepMainContainer}>
              <img src={checkIcon} alt="check-icon" />
              <h2 className={styles.mainHeading}>Mise en ligne terminée</h2>
              <Link to={"/home"}>
                <Button variant="primary" className={styles.secondaryHeading}>
                  Retour
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
