import React, { useContext } from "react";
import { AppContext } from "src/Contexts/AppContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import styles from "./List.module.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";

export default function ListCard({
  type,
  obj,
  token = null,
  identificationId = null,
  name = null,
  firstNameContact = null,
  lastNameContact = null,
}) {
  const {
    getLoading,
    getLoaded,
    state: { loading },
  } = useContext(AppContext);

  var text1 = "";
  var text2 = "";
  var navigate = useNavigate();
  const formatDate = (date) => {
    let d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    let year = d.getFullYear();
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }
    return [day, month, year].join("-");
  };

  switch (type) {
    case "devis": {
      text1 = `Devis n°${obj.id}`;
      text2 = formatDate(new Date(obj.creationDate));
      break;
    }
    case "devisSage": {
      text1 = `Devis n°${obj.id}`;
      text2 = formatDate(new Date(obj.creationDate));
      break;
    }
    case "factures": {
      text1 = `Facture n°${obj.id}`;
      text2 = formatDate(new Date(obj.creationDate));
      break;
    }
    case "factureSage": {
      text1 = `Facture n°${obj.id}`;
      text2 = formatDate(new Date(obj.creationDate));
      break;
    }
    case "re-employment": {
      text1 = "Télécharger l’attestation prélèvement";
      text2 = "";
      break;
    }
    case "re-employed": {
      text1 = "Télécharger l’attestation réemploi";
      text2 = "";
      break;
    }
    default: {
      break;
    }
  }

  const handleDowload = () => {
    getLoading();
    let url = "";
    if (type === "devis" || type === "factures") {
      url = `${
        process.env.REACT_APP_API_URL
      }/Invoices/Facture-proforma?orderID=${obj.id}&isDevis=${
        type === "devis" ? true : false
      }&token=${obj.token}`;
    } else {
      url = `${
        process.env.REACT_APP_API_URL
      }/Invoices/Facture-proforma-sage?orderID=${obj.id}&isDevis=${
        type === "devisSage" ? true : false
      }&token=${obj.token}`;
    }
    axios(url, {
      method: "GET",
      responseType: "blob", //Force to receive data in a Blob Format
    })
      .then((response) => {
        //Create a Blob from the PDF Stream
        const file = new Blob([response.data], { type: "application/pdf" });
        //Build a URL from the file
        const fileURL = URL.createObjectURL(file);
        //Open the URL on new Window
        getLoaded();
        window.open(fileURL);
      })
      .catch((error) => {
        getLoaded();
      });
  };

  return (
    <>
      {(type === "devis" ||
        type === "factures" ||
        type === "devisSage" ||
        type === "factureSage") && (
        <div
          className={styles.cardContainer}
          onClick={
            type === "devis" ||
            type === "factures" ||
            type === "devisSage" ||
            type === "factureSage"
              ? handleDowload
              : null
          }
        >
          <div className={styles.leftBlock}>
            <FontAwesomeIcon icon={faPaperclip} />
            <p>{text1}</p>
          </div>
          <div>
            <p>{text2}</p>
          </div>
        </div>
      )}

      {(type === "re-employment" || type === "re-employed") && (
        <a
          target="_blank"
          href={
            type === "re-employment"
              ? `https://www.optimal-karpet.fr/api/Templates/getTemplate?token=${token}&identificationID=${identificationId}&mode=prelevement`
              : `https://www.optimal-karpet.fr/api/Templates/getTemplate?token=${token}&identificationID=${identificationId}&mode=reemploye`
          }
          className={styles.cardContainer}
        >
          <div className={styles.leftBlock}>
            <FontAwesomeIcon icon={faPaperclip} />
            <p>
              {text1} | {name}
            </p>
          </div>
          <div>
            <p>
              {firstNameContact} {lastNameContact}
            </p>
          </div>
        </a>
      )}
    </>
  );
}
