// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_modal__DJDMv {\n  background-color: rgba(0, 0, 0, 0.5);\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n  overflow-y: hidden;\n  z-index: 99999;\n}\n\n.Modal_container__Ha\\+Ky {\n  display: flex;\n  justify-content: center;\n}\n\n.Modal_modalBody__zZlcF {\n  background-color: #f9f9f9;\n  padding: 1rem;\n}\n", "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.module.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,eAAe;EACf,YAAY;EACZ,WAAW;EACX,MAAM;EACN,OAAO;EACP,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,uBAAuB;AACzB;;AAEA;EACE,yBAAyB;EACzB,aAAa;AACf","sourcesContent":[".modal {\n  background-color: rgba(0, 0, 0, 0.5);\n  position: fixed;\n  height: 100%;\n  width: 100%;\n  top: 0;\n  left: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  overflow: hidden;\n  overflow-y: hidden;\n  z-index: 99999;\n}\n\n.container {\n  display: flex;\n  justify-content: center;\n}\n\n.modalBody {\n  background-color: #f9f9f9;\n  padding: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": "Modal_modal__DJDMv",
	"container": "Modal_container__Ha+Ky",
	"modalBody": "Modal_modalBody__zZlcF"
};
export default ___CSS_LOADER_EXPORT___;
