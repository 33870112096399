import Button from "src/components/button/Button";

import check from "src/assets/check.svg";
import { useNavigate } from "react-router-dom";

import styles from "src/pages/login/LoginValidation.module.css";
import { useContext } from "react";
import { AppContext } from "src/Contexts/AppContext";

export default function LoginValidation() {
  let navigate = useNavigate();

  const backRedirection = (event) => {
    console.log("back");
    event.preventDefault();
    navigate(-1);
  };

  return (
    <div className={styles.container}>
      <div className={styles.subContainer}>
        <img src={check} alt="check-logo" className={styles.checkLogo} />
        <h1 className={styles.title}>Lien de connexion envoyé</h1>
        <p
          className="light"
          style={{
            letterSpacing: "1px",
            lineHeight: "20px",
            marginBottom: "27px",
            textAlign: "center",
          }}
        >
          Merci de vérifier votre boite mail ainsi que vos spams
        </p>
        <Button variant="primary" onClick={backRedirection}>
          Retour
        </Button>
      </div>
    </div>
  );
}
