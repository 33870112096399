import React, { useState, useRef, useContext, useEffect } from "react";
import styles from "./VenueInformation.module.css";
import attachmentIcon from "src/assets/icons/attachment.svg";
import { HiddenButton } from "./VenueInformation";
import { DocsModal } from "./DocsModal";
import { addDocumentationFileToIdentification } from "src/services/apiService";
import { useParams } from "react-router-dom";
import { AppContext } from "src/Contexts/AppContext";
import crossIconBlack from "src/assets/icons/cross-black.svg";
import { Loading } from "src/components/Loading/Loading";

const ItemTile = ({
  name,
  type,
  handleSelectDoc,
  itemKey,
  handleRemoveDoc,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const inputRef = useRef(null);
  const [docName, setDocName] = useState("");

  const addNewDoc = () => {
    inputRef?.current?.click();
  };

  const handleAddDoc = (e, itemKey) => {
    handleSelectDoc({ [itemKey]: e.target.files[0] }, type);
    setDocName(e.target.files[0].name);
    setModalOpen(false);
  };

  const handleRemove = (e, type) => {
    e.stopPropagation();
    handleRemoveDoc(type);
    setDocName(null);
    handleSelectDoc(null, type);
  };
  window.scrollTo(0, 0);
  return (
    <>
      <DocsModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        addNewDoc={addNewDoc}
      />
      <input
        type="file"
        accept="application/pdf,application/msword,
        application/vnd.openxmlformats-officedocument.wordprocessingml.document,.zip"
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => handleAddDoc(e, itemKey)}
      />
      <div
        className={styles.downloadableItemTile}
        onClick={() => setModalOpen(true)}
      >
        <img
          className={styles.downloadIcon}
          src={attachmentIcon}
          alt="download"
        />
        <h4>{docName ? docName : name}</h4>
        {docName && (
          <div
            className={styles.removeDocButton}
            onClick={(e) => handleRemove(e, type)}
          >
            <img src={crossIconBlack} alt="" />
          </div>
        )}
      </div>
    </>
  );
};

export const DownloadableDocs = ({
  handleUpdateIdentification,
  submitBtnRef,
}) => {
  const [productDocs, setProductDocs] = useState([null, null, null]);

  const { identificationId } = useParams();

  const {
    state: { loading, selectedIdentification },
    getLoading,
    getLoaded,
  } = useContext(AppContext);

  const onSubmit = async (e) => {
    e.preventDefault();
    getLoading();
    for (let i = 0; i < productDocs.length; i++) {
      if (productDocs[i] === null) {
        continue;
      } else {
        await addDocumentationFileToIdentification(
          identificationId,
          Object.values(productDocs[i])[0],
          Object.keys(productDocs[i])[0]
        );
      }
    }
    // handleUpdateIdentification();
    getLoaded();
  };

  const handleRemoveDoc = (type) => {
    var newProductDocs = [...productDocs];
    if (type === "brochureURL") {
      newProductDocs.splice(0, 1, null);
    } else if (type === "ficheTechniqueURL") {
      newProductDocs.splice(1, 1, null);
    } else if (type === "allDocsZipURL") {
      newProductDocs.splice(2, 1, null);
    }
    setProductDocs(newProductDocs);
  };

  const handleProductDocs = (data, type) => {
    var newProductDocs = [...productDocs];
    if (type === "brochureURL") {
      newProductDocs[0] = data;
    } else if (type === "ficheTechniqueURL") {
      newProductDocs[1] = data;
    } else if (type === "allDocsZipURL") {
      newProductDocs[2] = data;
    }
    setProductDocs(newProductDocs);
  };
  return (
    <>
      <form onSubmit={(e) => onSubmit(e)}>
        {loading && <Loading />}
        <div className={styles.itemContainer}>
          <ItemTile
            name="Télécharger FDES"
            type="brochureURL"
            handleSelectDoc={handleProductDocs}
            handleRemoveDoc={handleRemoveDoc}
            itemKey={"brochureURL"}
          />
          <ItemTile
            name="Fiche technique"
            type="ficheTechniqueURL"
            handleSelectDoc={handleProductDocs}
            handleRemoveDoc={handleRemoveDoc}
            itemKey={"ficheTechniqueURL"}
          />
          <ItemTile
            name="Documentation"
            type="allDocsZipURL"
            handleSelectDoc={handleProductDocs}
            handleRemoveDoc={handleRemoveDoc}
            itemKey={"allDocsZipURL"}
          />
        </div>
        <HiddenButton type="submit" btnRef={submitBtnRef} />
      </form>
    </>
  );
};
