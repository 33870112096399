import React from "react";
import styles from "src/components/clientCard/ClientCard.module.css";

import building from "src/assets/icons/Building.svg";
import { useSearchParams } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

export default function ClientCard({ card }) {
  return (
    <div className={styles.container}>
      <div className={styles.container__flex} style={{ marginBottom: "10px" }}>
        <div className={styles.container__flexBaseline}>
          <img src={building} alt="check-logo" className={styles.checkLogo} />
          <p style={{ fontWeight: "500", marginLeft: "6px" }}>{card.company}</p>
        </div>
        <p style={{ fontWeight: "400" }}>{card.fullName}</p>
      </div>
      <p style={{ fontWeight: "500", marginBottom: "2px" }}>Siège social</p>
      <p style={{ marginBottom: "2px" }}>{card.address}</p>
      <p style={{ marginBottom: "2px" }}>{card.email}</p>

      <div className={styles.container__flex}>
        <p>{card.phone}</p>
        <div className={styles.container__flex}>
          {/* <div style={{ marginRight: "9px" }}>Voir fiche</div>{" "} */}
          {/* <FontAwesomeIcon icon={faChevronRight} /> */}
        </div>
      </div>
    </div>
  );
}
