import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "src/Contexts/AppContext";
import BuildingCartographie from "src/assets/icons/building-cartographies.svg";
import Header from "src/components/header/Header";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { numberWithSpace } from "src/utils/functions";
import { useParams, useNavigate } from "react-router-dom";

import styles from "./CartographieDetail.module.css";

export const SubCard = ({ recup, identification }) => {
  const navigate = useNavigate();
  return (
    <div
      className={styles.subCardContainer}
      onClick={() =>
        navigate(`/detail-products/identification/${identification.id}`, {
          state: { from: "cartographie" },
        })
      }
    >
      <div>
        <div className={styles.subCardSubContainer}>
          <div className={styles.subCardInfosTop}>
            <p>{identification.place}</p>
            <div className={styles.subCardLightContainer}>
              <div
                className={
                  identification.status == "terminated"
                    ? styles.subCardLightGreen
                    : identification.status == "inProgress"
                    ? styles.subCardLightOrange
                    : styles.subCardLightYellow
                }
              ></div>
              <p>
                {identification.status == "terminated"
                  ? "Terminé"
                  : identification.status == "inProgress"
                  ? "En cours"
                  : "En attente"}
              </p>
            </div>
          </div>
          <div className={styles.cardInfosBottom}>
            <div className={styles.cardInfos}>
              <p>m2 récupérables</p>
              <p>
                {numberWithSpace(
                  Number(
                    identification.surface_m2Final
                      ? identification.surface_m2Final
                      : identification.surface_m2
                  )
                )}
                <span>M2</span>
              </p>
            </div>
            <div className={styles.cardInfos}>
              <p>Valeur correspondante</p>
              {Number(
                identification.estimationRachatMoquetteFinal
                  ? identification.estimationRachatMoquetteFinal /
                      identification.surface_m2Final
                  : identification.estimationRachatMoquette
              ) > 0 ? (
                <p>
                  {numberWithSpace(
                    Number(
                      identification.estimationRachatMoquetteFinal
                        ? identification.estimationRachatMoquetteFinal /
                            identification.surface_m2Final
                        : identification.estimationRachatMoquette /
                            identification.surface_m2
                    )
                  )}{" "}
                  <span>€/m² HT</span>
                </p>
              ) : identification.status == "inProgress" ? (
                <p>
                  {numberWithSpace(
                    Number(
                      identification.estimationRachatMoquetteFinal
                        ? identification.estimationRachatMoquetteFinal
                        : identification.estimationRachatMoquette
                    )
                  )}{" "}
                  {/* <span>€</span> */}
                </p>
              ) : (
                <p className={styles.noValeur}>En cours de chiffrage</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default function CartographiesList() {
  const { id: buildingId, clientid: idClient } = useParams();

  const {
    getIdentificationsByAddressId,
    getAddressIndentificationByClientId,
    state: { client, addressList, identificationsByAddressId },
  } = useContext(AppContext);

  const [addressArr, setAddressArr] = useState(null);
  const [floorInfos, setFloorInfos] = useState(null);
  const [floors, setFloors] = useState([]);
  const [buildingInfo, setBuildingInfo] = useState(null);
  const [mainInfos, setMainInfos] = useState({
    m2: 0,
    valeur: 0,
  });
  useEffect(() => {
    if (addressList != -1) {
      const arrTemp = [];
      Object.keys(addressList.addresses).forEach(function (key, index) {
        arrTemp.push(addressList.addresses[key]);
      });
      setAddressArr(arrTemp);
      setBuildingInfo(arrTemp.filter((add) => add.id == buildingId));
    }
  }, [addressList]);

  useEffect(() => {
    getIdentificationsByAddressId(buildingId, idClient);
    getAddressIndentificationByClientId(idClient);
  }, []);

  useEffect(() => {
    if (identificationsByAddressId) {
      var mainInfosTemp = {
        m2: 0,
        valeur: 0,
      };
      var floorsTemp = [];
      identificationsByAddressId.forEach((address) => {
        mainInfosTemp = {
          m2:
            mainInfosTemp.m2 +
            Number(
              address.surface_m2Final
                ? address.surface_m2Final
                : address.surface_m2
            ),
          valeur:
            mainInfosTemp.valeur +
            Number(
              address.estimationRachatMoquetteFinal
                ? address.estimationRachatMoquetteFinal
                : address.estimationRachatMoquette
            ),
        };
        if (floorsTemp.includes(address.floor) == false) {
          floorsTemp.push(address.floor);
        }
      });
      floorsTemp.sort((a, b) => Number(a) - Number(b));
      floorsTemp.unshift("Tous les étages");
      setFloors(floorsTemp);
      setMainInfos(mainInfosTemp);
      setFloorInfos(identificationsByAddressId);
    }
  }, [identificationsByAddressId]);

  const handleSelectFloor = (floorSelected) => {
    var floorTemp = [...identificationsByAddressId];
    if (floorSelected != -1) {
      floorTemp = floorTemp.filter((floor) => floor.floor == floorSelected);
    }
    setFloorInfos(floorTemp);
    var mainInfosTemp = {
      m2: 0,
      valeur: 0,
    };
    for (let i = 0; i < floorTemp.length; i++) {
      mainInfosTemp = {
        m2:
          floorTemp[i].surface_m2Final != null &&
          floorTemp[i].surface_m2Final != ""
            ? mainInfosTemp.m2 + Number(floorTemp[i].surface_m2Final)
            : mainInfosTemp.m2 + Number(floorTemp[i].surface_m2),
        valeur:
          floorTemp[i].estimationRachatMoquetteFinal != null &&
          floorTemp[i].estimationRachatMoquetteFinal != ""
            ? mainInfosTemp.valeur +
              Number(floorTemp[i].estimationRachatMoquetteFinal)
            : mainInfosTemp.valeur +
              Number(floorTemp[i].estimationRachatMoquette),
      };
    }
    setMainInfos(mainInfosTemp);
  };

  return (
    <>
      {buildingInfo && (
        <>
          <Header
            screenTarget={`/client-detail/${idClient}/4`}
            icon={faChevronLeft}
          >
            <div style={{ paddingLeft: "10px" }}>
              Immeuble {buildingInfo[0].building}
            </div>
          </Header>
          <div className={styles.container}>
            <div className={styles.selectContainer}>
              <p>Séléctionnez un étage</p>
              <select
                className={styles.commercialsSelect}
                onChange={(e) => handleSelectFloor(e.target.value)}
              >
                {floors &&
                  floors.map((floor, index) => {
                    if (index === 0) {
                      return (
                        <option key={index} value={-1}>
                          {floor}
                        </option>
                      );
                    }
                    return (
                      <option key={index} value={floor}>
                        {isNaN(floor) ? floor : `Étage ${floor}`}
                      </option>
                    );
                  })}
              </select>
            </div>
            <div className={styles.mainInfosContainer}>
              <div className={styles.mainInfosContainerBlock}>
                <p>
                  Nombre <br /> de m2 récupérés
                </p>
                <p>
                  {mainInfos && numberWithSpace(mainInfos.m2)} <span>m2</span>
                </p>
              </div>
              <div className={styles.mainInfosContainerDivide}></div>
              <div className={styles.mainInfosContainerBlock}>
                <p>valeur récupérée correspondante</p>
                {mainInfos.valeur > 0 ? (
                  <p>
                    {numberWithSpace(mainInfos.valeur)} <span>€</span>
                  </p>
                ) : (
                  <p className={styles.noValeur}>En cours de chiffrage</p>
                )}
              </div>
            </div>
          </div>
          <div>
            {floorInfos &&
              floorInfos.map((identification, index) => {
                return (
                  <SubCard
                    key={index}
                    recup={false}
                    identification={identification}
                  />
                );
              })}
          </div>
        </>
      )}
    </>
  );
}
