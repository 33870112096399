import logo from "src/assets/logo_noir.svg";
import styles from "src/pages/login/Login.module.css";
import Button from "src/components/button/Button";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AppContext } from "src/Contexts/AppContext";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VALIDATION_ERRORS } from "src/utils/constants";
import { getJWTFromLocalStorage } from "src/utils/functions";

const loginValidationSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required(VALIDATION_ERRORS.EMAIL_EMPTY)
    .label("Email")
    .email(VALIDATION_ERRORS.EMAIL),
});

export default function Login() {
  let navigate = useNavigate();
  const [queryParams] = useSearchParams();

  const { handleSubmit, register, formState } = useForm({
    resolver: yupResolver(loginValidationSchema),
    mode: "onChange",
    reValidateMode: "onChange",
  });

  const {
    state: { error, tokenVerified },
    getLoginLink,
    checkLoginToken,
  } = useContext(AppContext);

  const onSubmit = (data) => {
    const { email } = data;
    getLoginLink(email);
    navigate("/login-validation", { replace: true });
  };

  useEffect(() => {
    const token = queryParams.get("token");
    if (token) {
      checkLoginToken(token);
    }
  }, []);

  useEffect(() => {
    const jwt = getJWTFromLocalStorage();
    if (tokenVerified && !error && jwt) {
      navigate("/home", { replace: true });
    }
  }, [tokenVerified, error]);

  const validationMessage = formState.errors?.email?.message;

  const formErrorText = validationMessage ? validationMessage : error;
  return (
    <div className={styles.container}>
      <div>
        <img src={logo} alt="logo" className={styles.mainLogo} />
        <h1 className={styles.title}>Bienvenue</h1>
        <p
          className="light"
          style={{
            letterSpacing: "1px",
            lineHeight: "20px",
            marginBottom: "35px",
          }}
        >
          Saisissez votre email, un lien magique vous sera envoyé
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <input
            type="email"
            name="email"
            {...register("email")}
            placeholder="Adresse email"
            className={`${styles["email-input"]} main-input`}
          ></input>
          <p className={styles.formError} style={{ marginBottom: "46px" }}>
            {formErrorText}
          </p>
          <Button disabled={!formState.isValid} variant="primary" type="submit">
            Recevoir le code
          </Button>
        </form>
      </div>
    </div>
  );
}
