import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faXmark } from "@fortawesome/free-solid-svg-icons";
import styles from "src/pages/listing-products/ListingProducts.module.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import "./RcSlide.css";

export default function QuantityContainer({
  setFilter,
  getFilter,
  currentM2,
  minQuantityChoosen,
  maxQuantityChoosen,
  handleChangeQuantity,
  minQuantityChoosenRc,
  maxQuantityChoosenRc,
}) {
  return (
    <div className={styles.filterSubContainer}>
      <div
        className={styles.filterHeader}
        onClick={() => setFilter(!getFilter)}
      >
        <div>Quantité en M²</div>
        <div>
          <FontAwesomeIcon icon={getFilter ? faXmark : faChevronDown} />
        </div>
      </div>
      {getFilter ? (
        <div className={styles.rangeContainer}>
          <Slider
            range
            allowCross={false}
            // defaultValue={[0, 10010]}
            value={[minQuantityChoosenRc, maxQuantityChoosenRc]}
            onChange={handleChangeQuantity}
            marks={{ 0: minQuantityChoosen, 100: maxQuantityChoosen }}
          />
        </div>
      ) : null}
    </div>
  );
}
