import React, { useState, useEffect, useContext } from "react";
import { AppContext } from "src/Contexts/AppContext";
import ListCard from "src/components/clientCard/ListCard";

import styles from "./AttestationList.module.css";

export default function AttestationList({
  length,
  type,
  idClient,
  addressArr,
}) {
  const [allIdentifications, setAllIdentifications] = useState([]);

  const {
    getAddressIndentificationByClientId,
    getIdentificationsByAddressId,
    state: { client, addressList, identificationsByAddressId },
  } = useContext(AppContext);

  const getAllIdentifications = async () => {
    addressArr.forEach(async (address) => {
      var result = await getIdentificationsByAddressId(address.id, client.id);
      var resultFiltered = result.filter((res) => res.subStatus != null);
      resultFiltered = resultFiltered.filter(
        (res) => res.status == "terminated"
      );
      if (resultFiltered.length > 0) {
        resultFiltered.sort((a, b) => {
          if (a.subStatus > b.subStatus) {
            return -1;
          }
          if (b.subStatus > a.subStatus) {
            return 1;
          }
          return 0;
        });
        var resultTemp = {
          address: address.address,
          building: address.building,
          name: address.name,
          identifications: resultFiltered,
        };
        setAllIdentifications((currentIdentification) => [
          ...currentIdentification,
          resultTemp,
        ]);
      }
    });
  };

  var isRefreshed = false;
  useEffect(() => {
    if (isRefreshed == false) {
      getAllIdentifications();
      isRefreshed = true;
    }
  }, []);

  return (
    <div className={styles.container}>
      {allIdentifications.length > 0 &&
        allIdentifications.map((identification, index) => {
          return (
            <div key={index}>
              <h4>{identification.address}</h4>
              <p className={styles.immeuble}>
                Immeuble {identification.building}
              </p>
              {identification.identifications[0] !== undefined &&
                identification.identifications[0].subStatus ==
                  "re-employment" && <h5>Moquettes récupérées</h5>}
              {identification.identifications.length > 0 &&
                identification.identifications.map((iden, index) => {
                  if (iden.subStatus == "re-employment") {
                    return (
                      <ListCard
                        key={index}
                        type="re-employment"
                        token={iden.token}
                        identificationId={iden.id}
                        name={identification.name}
                        firstNameContact={iden.firstNameContact}
                        lastNameContact={iden.lastNameContact}
                      />
                    );
                  }
                })}
              {identification.identifications[
                identification.identifications.length - 1
              ].subStatus == "re-employed" && (
                <h5>Moquettes récupérées et ré-employées</h5>
              )}
              {identification.identifications &&
                identification.identifications.map((iden, index) => {
                  if (iden.subStatus == "re-employed") {
                    return (
                      <div key={Number(identification.id)}>
                        <ListCard
                          key={index * Number(identification.id)}
                          type="re-employment"
                          token={iden.token}
                          identificationId={iden.id}
                          name={identification.name}
                          firstNameContact={iden.firstNameContact}
                          lastNameContact={iden.lastNameContact}
                        />
                        <ListCard
                          key={index}
                          type="re-employed"
                          token={iden.token}
                          identificationId={iden.id}
                          name={identification.name}
                          firstNameContact={iden.firstNameContact}
                          lastNameContact={iden.lastNameContact}
                        />
                      </div>
                    );
                  }
                })}
            </div>
          );
        })}
    </div>
  );
}
