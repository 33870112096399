import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import inputStyles from "src/components/CreateClient/CreateClient.module.css";
import Calendar from "src/assets/icons/calendar_range.svg";
import { forwardRef } from "react";

const _DatePickerInput = (
  {
    onClick,
    placeholder,
    value,
    fieldName,
    error,
    startDate,
    endDate,
    disabled,
    ...rest
  },
  ref
) => {
  const [parsedDate, setParsedDate] = useState(null);

  useEffect(() => {
    if (value) {
      var count = 0;
      var val = "";
      for (let i = 0; i < value.length; i++) {
        if (value[i] == "-") {
          count++;
        }
        if (value[i] == "-" && count != 3) {
          val = val + "/";
        } else {
          val = val + value[i];
        }
      }
      setParsedDate(val);
    }
  }, [value]);

  return (
    <>
      {disabled == false ? (
        <div className={inputStyles.formInputContainerModal} onClick={onClick}>
          <div className={inputStyles.datePickerContainer}>
            {parsedDate ? (
              <div>{parsedDate}</div>
            ) : (
              <div>Paramétrer l’intervalle de date</div>
            )}
            <img src={Calendar} alt="" />
            <input
              className="main-input-range"
              placeholder={placeholder}
              name={fieldName}
              onClick={onClick}
              value={value}
              onChange={() => {}}
              ref={ref}
            />
          </div>
          {error && <p className={inputStyles.formError}>{error}</p>}
        </div>
      ) : (
        <div className={inputStyles.formInputContainer} onClick={onClick}>
          {parsedDate ? (
            <div>{parsedDate}</div>
          ) : (
            <div>JJ/MM/AAAA - JJ/MM/AAAA</div>
          )}
          {error && <p className={inputStyles.formError}>{error}</p>}
        </div>
      )}
    </>
  );
};

const DatePickerInput = forwardRef(_DatePickerInput);

export const OrakDatePickerRange = ({
  startDate,
  onChange,
  placeholder,
  endDate,
  disableFuture,
  disabled = false,
}) => {
  return (
    <DatePicker
      style={{ width: "200px" }}
      label="Date"
      dateFormat="dd-MM-yyyy"
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      // inline
      disabled={disabled}
      inputFormat="dd/MM/yyyy"
      placeholderText={placeholder}
      minDate={new Date()}
      showYearDropdown
      yearDropdownItemNumber={25}
      customInput={
        <DatePickerInput
          startDate={startDate}
          endDate={endDate}
          disabled={disabled}
        />
      }
    />
  );
};
