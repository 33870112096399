import { SCREEN_HEADINGS, SELECTION_OPTION } from "./constants";
import { PageHeader } from "./PageHeader";
import { SelectionBlock } from "./SelectionBlock";
import { useEffect } from "react";

import styles from "./VenueInformation.module.css";

export const ContractWithOrak = ({ handleHasOrakInsurance, selectedBlock }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div
      style={{ marginTop: "1rem", paddingTop: "1rem" }}
      className={styles.itemContainer}
    >
      <PageHeader
        heading={SCREEN_HEADINGS.contractWithOrak.heading}
        subHeading={SCREEN_HEADINGS.contractWithOrak.subHeading}
        icon={SCREEN_HEADINGS.contractWithOrak.icon}
      />
      <div className={styles.easeOfCleaningContainer}>
        <SelectionBlock
          text={SELECTION_OPTION.OUI}
          handleClick={() => handleHasOrakInsurance(SELECTION_OPTION.OUI)}
          isSelected={selectedBlock === SELECTION_OPTION.OUI}
        />
        <SelectionBlock
          text={SELECTION_OPTION.NON}
          handleClick={() => handleHasOrakInsurance(SELECTION_OPTION.NON)}
          isSelected={selectedBlock === SELECTION_OPTION.NON}
        />
      </div>
    </div>
  );
};
