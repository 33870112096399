import React, { useState, useContext, useEffect } from "react";
import { AppContext } from "src/Contexts/AppContext";
import { formatPhoneNumber } from "src/utils/functions";
import styles from "./VenueInformation.module.css";

export const CompanyBanner = () => {
  const [currentAddress, setCurrentAddress] = useState(null);
  const [addressArr, setAddressArr] = useState(null);

  const {
    state: { addressList, selectedIdentification },
  } = useContext(AppContext);

  const {
    // address: { name, address, building, floor, digicode },
    floor,
    addressID,
    phoneContact,
    firstNameContact,
    lastNameContact,
  } = selectedIdentification;

  useEffect(() => {
    if (addressList && addressList.addresses) {
      const arrTemp = [];
      Object.keys(addressList.addresses).forEach(function (key, index) {
        arrTemp.push(addressList.addresses[key]);
      });
      setAddressArr(arrTemp);
    }
  }, [addressList]);

  window.scrollTo(0, 0);

  useEffect(() => {
    if (addressArr) {
      const currentAddressTemp = addressArr.filter(
        (add) => add.id == addressID
      );
      setCurrentAddress(currentAddressTemp[0]);
    }
  }, [addressArr]);

  const contactName = `${firstNameContact} ${lastNameContact}`;
  const phoneNumber = formatPhoneNumber(phoneContact);
  return (
    <div className={styles.companyCardContainer}>
      {currentAddress && (
        <div className={styles.cardInnerContainer}>
          <div>
            <p className={styles.companyCard__name}>{currentAddress.name}</p>
            <p className={styles.companyCard__contactNumber}>{phoneNumber}</p>
            <p className={styles.companyCard__address}>
              {currentAddress.address}
            </p>
            <div style={{ display: "flex" }}>
              <p
                className={styles.lighterFont}
                style={{ whiteSpace: "nowrap" }}
              >
                {currentAddress.building}
              </p>
              <span style={{ margin: "0 .75rem", color: "var(--green)" }}>
                |
              </span>
              <p
                className={styles.lighterFont}
                style={{ whiteSpace: "nowrap" }}
              >
                {floor}
                {isNaN(floor) ? "" : floor == 1 ? " étage" : " étages"}
              </p>
              <span style={{ margin: "0 .75rem", color: "var(--green)" }}>
                |
              </span>
              <p
                className={styles.lighterFont}
                style={{ whiteSpace: "nowrap" }}
              >
                Digicode : {currentAddress.digicode}
              </p>
            </div>
          </div>
          <p className={styles.lighterFont}>{contactName}</p>
        </div>
      )}
    </div>
  );
};
