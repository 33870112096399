// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttestationList_container__5XJ9H {\n  width: calc(100% - 20px);\n  margin: 10px;\n  padding: 10px;\n}\n\n.AttestationList_container__5XJ9H h4 {\n  font-size: 16px;\n  font-weight: 500;\n  color: #252525;\n  line-height: 22px;\n}\n\n.AttestationList_container__5XJ9H p {\n  font-size: 14px;\n  font-weight: 500;\n  color: #252525;\n  line-height: 22px;\n}\n\n.AttestationList_immeuble__wsPA5 {\n  margin-top: -20px;\n  margin-bottom: -10px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/clientCard/AttestationList.module.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;EACxB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;EACjB,oBAAoB;AACtB","sourcesContent":[".container {\n  width: calc(100% - 20px);\n  margin: 10px;\n  padding: 10px;\n}\n\n.container h4 {\n  font-size: 16px;\n  font-weight: 500;\n  color: #252525;\n  line-height: 22px;\n}\n\n.container p {\n  font-size: 14px;\n  font-weight: 500;\n  color: #252525;\n  line-height: 22px;\n}\n\n.immeuble {\n  margin-top: -20px;\n  margin-bottom: -10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AttestationList_container__5XJ9H",
	"immeuble": "AttestationList_immeuble__wsPA5"
};
export default ___CSS_LOADER_EXPORT___;
