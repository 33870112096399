import { useNavigate } from "react-router-dom";
import { useContext, useEffect } from "react";
import { withAuth } from "src/components/HOCs/withAuth";
import calendarWhite from "src/assets/icons/calendar-white.svg";
import { ConfirmationScreen } from "src/components/ConfirmationScreen/ConfirmationScreen";
import { AppContext } from "src/Contexts/AppContext";

function NewIdentification() {
  let navigate = useNavigate();

  const { resetContext } = useContext(AppContext);

  useEffect(() => {
    resetContext();
  }, []);

  return (
    <ConfirmationScreen
      screenName="Nouvelle identification"
      mainHeading="Un rendez-vous est-il déjà programmé ?"
      icon={calendarWhite}
      onClickYes={() => navigate("/calendrier")}
      onClickNo={() => navigate("/faire-une-identification")}
      backToHome="true"
    />
  );
}

export default withAuth(NewIdentification);
