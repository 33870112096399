import axios from "axios";
import toast from "src/utils/ToastContext";

axios.interceptors.response.use(null, (error) => {
  const expectedError =
    error.response &&
    error.response.status >= 400 &&
    error.response.status < 500;

  if (!expectedError && !axios.isCancel(error)) {
    return toast.open({
      type: "unexpectedError",
    });
  }

  return Promise.reject(error);
});

const methods = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export default methods;
