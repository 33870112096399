import Header from "src/components/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { withAuth } from "src/components/HOCs/withAuth";
import { Loading } from "src/components/Loading/Loading";

import styles from "src/components/VenueInformation/VenueInformation.module.css";
import * as API from "src/services/apiService";
import { PageHeader } from "./PageHeader";
import { Form } from "src/components/VenueInformation/Form";
import Button from "../button/Button";
import { useContext, useEffect, useRef, useState } from "react";
import {
  STEPS,
  SCREEN_HEADINGS,
  SCREEN_HEADINGS2,
  TOP_BANNER_SCREENS,
} from "./constants";
import { RoomAttendance } from "./RoomAttendance";
import { InfoFooter } from "./InfoFooter";
import { Surface } from "./Surface";
import { EaseOfCleaning } from "./EaseOfCleaning";
import { ProductRating } from "./ProductRating";
import { EaseOfRemoval } from "./EaseOfRemoval";
import { AnnualContract } from "./AnnualContract";
import { ProductPhotoContainer } from "./ProductPhotos";
import { IdentificationComplete } from "./IdentificationComplete";
import { ProgressBar } from "./ProgressBar";
import { AppContext } from "src/Contexts/AppContext";
import { parseCreateIdentificationData } from "src/utils/functions";

import {
  useLocation,
  useNavigate,
  useParams,
  matchRoutes,
  matchPath,
} from "react-router-dom";
import { CarpetInformation } from "./CarpetInformation";
import { CarpetEvaluation } from "./CarpetEvaluation";
import { DownloadableDocs } from "./DownloadableDocs";
import { CompanyBanner } from "./CompanyBanner";

export const HiddenButton = ({ btnRef, onClick, type = "button" }) => (
  <button
    ref={btnRef}
    onClick={onClick}
    style={{ display: "none" }}
    type={type}
  />
);

const VenueInformation = ({
  processSteps = STEPS,
  processHeading = "Faire une identification",
  identificationData,
}) => {
  const {
    state: {
      clientContactData,
      identificationCreated,
      client,
      selectedIdentification,
      infosIdentificationsCreated,
    },
    createIdentification,
    updateIdentification,
    setIdentification,
    setClient,
    resetInfosIdentificationsCreated,
  } = useContext(AppContext);

  const { id: clientId, identificationId } = useParams();

  const navigate = useNavigate();
  const location = useLocation();

  const [activeScreen, setActiveScreen] = useState("venueInformation");
  const [finalStepText, setFinalStepText] = useState("");

  const [screenIndex, setScreenIndex] = useState(1);
  const [enteredData, setEnteredData] = useState({ clientId });
  const [errors, setErrors] = useState({});
  const [currentScreenDataValid, setScreenDataValid] = useState(false);
  const [newIdentificationData, setNewIdendificationData] =
    useState(identificationData);
  const [checkNoCreationDate, setCheckNoCreationDate] = useState(false);
  const [statusReview, setStatusReview] = useState(-1);
  const [currentAddressInfos, setCurrentAddressInfos] = useState(null);
  const [newIdentification, setNewIdentification] = useState(null);

  const isCommercialIdentification = location.pathname.includes("/commercial");
  const refs = {
    [processSteps.VENUE_INFORMATION]: useRef(null),
    [processSteps.ROOM_ATTENDANCE]: useRef(null),
    [processSteps.SURFACE]: useRef(null),
    [processSteps.EASE_OF_CLEANING]: useRef(null),
    [processSteps.PRODUCT_RATING]: useRef(null),
    [processSteps.EASE_OF_REMOVAL]: useRef(null),
    [processSteps.ANNUAL_CLEANING_CONTRACT]: useRef(null),
    [processSteps.PRODUCT_PHOTOS]: useRef(null),
    [processSteps.CARPET_INFORMATION]: useRef(null),
    [processSteps.CARPET_EVALUATION]: useRef(null),
    [processSteps.DOWNLOADABLE_DOCS]: useRef(null),
  };

  const restartIdentification = () => {
    setActiveScreen("venueInformation");
    setEnteredData((prevState) => ({
      ...prevState,
      location: { ...enteredData.location, floor: "", place: "" },
      roomAttendance: "Faible",
      surface: "",
      surfaceReview: "",
      easeOfCleaning: true,
      easeOfCleaningFalseReason: "",
      easeOfRemoval: true,
      easeOfRemovalFalseReason: "",
      productRating: "Moyen",
      cleaningContract: "Oui",
      cleaningContractWithOrak: "Confort",
      cleaningContactWithoutOrak: "",
    }));

    setNewIdentification({
      addressID: infosIdentificationsCreated.infos.currentAddressID,
    });
    resetInfosIdentificationsCreated();
  };

  const handleSetError = (error) => {
    setErrors((prevState) => ({ ...prevState, ...error }));
  };

  const getScreenIndex = (goBack = false) => {
    let nextScreenIndex;
    const currentIndex = Object.values(processSteps).indexOf(activeScreen);

    if (goBack) {
      if (currentIndex !== 0) {
        nextScreenIndex = Object.values(processSteps)[currentIndex - 1];
        setActiveScreen(nextScreenIndex);
        setScreenIndex(
          Object.values(processSteps).indexOf(nextScreenIndex) - 1
        );
      }
    } else {
      nextScreenIndex = Object.values(processSteps)[currentIndex + 1];
      setActiveScreen(nextScreenIndex);
      setScreenIndex(Object.values(processSteps).indexOf(nextScreenIndex) + 1);
    }
  };

  const handleContinueBtn = () => {
    refs[activeScreen]?.current?.click();
  };

  const finishReview = (statusProcess) => {
    setStatusReview(statusProcess);
    handleContinueBtn();
  };

  useEffect(() => {
    if (checkNoCreationDate == true) {
      getScreenIndex(true);
      setScreenDataValid(true);
      var prevIndex = Object.values(processSteps).indexOf(activeScreen) + 1;
      var prevScreenName = Object.values(processSteps)[prevIndex];
      setActiveScreen(prevScreenName);
    }
  }, [checkNoCreationDate]);

  const handleBackBtn = () => {
    const currentIndex = Object.values(processSteps).indexOf(activeScreen);
    if (currentIndex === 0) {
      navigate(-1);
    } else {
      if (checkNoCreationDate == true) {
        var prevIndex = Object.values(processSteps).indexOf(activeScreen) - 2;
      } else {
        var prevIndex = Object.values(processSteps).indexOf(activeScreen) - 1;
      }
      getScreenIndex(true);
      setScreenDataValid(true);
      var prevScreenName = Object.values(processSteps)[prevIndex];
      setActiveScreen(prevScreenName);
    }
  };

  const handleData = (key, value, screenValid = false) => {
    setEnteredData((prevState) => ({ ...prevState, [key]: value }));
    if (currentScreenDataValid || screenValid) {
      getScreenIndex();
    }
  };

  const handleCreateIdentification = async (data) => {
    data = { ...enteredData, ...data };
    var productImages = null;

    if (data.productImages != null) {
      productImages = Object.values(data.productImages);
    }
    const parsedData = parseCreateIdentificationData(data);
    await createIdentification(parsedData, productImages);
  };

  useEffect(() => {
    if (statusReview != -1) {
      handleUpdateIdentification();
    }
  }, [statusReview]);

  const handleUpdateIdentification = async (data) => {
    var newImagesFilePath = [];
    var oldImagesFilePath = [];
    var curImagesID = [];
    var isDraft = false;
    var status = "";
    if (statusReview == 1) {
      isDraft = true;
    }
    if (statusReview == 1) {
      status = "toStudy";
    } else if (statusReview == 2) {
      status = "pending";
    } else if (statusReview == 3) {
      status = "inProgress";
    }
    data = { ...enteredData, ...data, isDraft, status };
    const parsedData = parseCreateIdentificationData(data);
    if (selectedIdentification.imagePath?.length > 0) {
      for (let i = 0; i < selectedIdentification.imagePath.length; i++) {
        var curImage = selectedIdentification.imagePath[i];
        if (!curImage) {
          continue;
        } else if (!curImage.id && curImage.name) {
          var resImg = await API.addImagesToIdentification(
            identificationId,
            selectedIdentification.imagePath[i],
            true
          );
          var result = JSON.parse(resImg.data.payload);
          curImagesID.push(result.id);
        } else if (curImage.isDeleted == 0 && curImage.id) {
          curImagesID.push(curImage.id);
        }
      }
    }
    const res = await updateIdentification(
      parsedData,
      identificationId,
      curImagesID
    );

    if (statusReview == 3) {
      navigate(`/in-progress-identification/commercial/${identificationId}`, {
        state: { currentStatus: "inProgress" },
      });
    }
  };

  useEffect(() => {
    if (identificationCreated && activeScreen != "venueInformation") {
      if (isCommercialIdentification == true && statusReview == 2) {
        setFinalStepText("Votre identification est terminée");
      } else if (isCommercialIdentification == true && statusReview == 1) {
        setFinalStepText("Votre identification a bien été sauvegardée");
      }
      setScreenDataValid(true);
      getScreenIndex();
    }
  }, [identificationCreated]);

  useEffect(() => {
    if (isCommercialIdentification) {
      async function getIdentification(identificationId) {
        try {
          var response = await API.getIdentification(identificationId);
          const {
            data: { payload },
          } = response;

          if (payload.length > 0) {
            setNewIdendificationData(payload[0]);
            setIdentification(payload[0]);
          }
        } catch (ex) {
          console.log("getIdentification failed", ex);
        }
      }
      getIdentification(identificationId);
    } else {
      setIdentification(null);
      setNewIdendificationData(true);
    }

    if (location.state != null) {
      setClient(location.state);
    }
    if (location.state && location.state.infos) {
      setCurrentAddressInfos(location.state.infos);
    }
  }, []);
  const renderComponent = () => {
    switch (activeScreen) {
      case processSteps.VENUE_INFORMATION:
        return (
          <Form
            submitBtnRef={refs[activeScreen]}
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            setScreenDataValid={setScreenDataValid}
            clientContactData={clientContactData}
            client={client}
            adresse={true}
            enteredData={enteredData}
            currentAddressInfos={currentAddressInfos}
            newIdentification={newIdentification}
            setNewIdentification={setNewIdentification}
          />
        );
      case processSteps.ROOM_ATTENDANCE:
        return (
          <RoomAttendance
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
          />
        );
      case processSteps.SURFACE:
        return (
          <Surface
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
          />
        );
      case processSteps.EASE_OF_CLEANING:
        return (
          <EaseOfCleaning
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
          />
        );
      case processSteps.PRODUCT_RATING:
        return (
          <ProductRating
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
          />
        );
      case processSteps.EASE_OF_REMOVAL:
        return (
          <EaseOfRemoval
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
          />
        );
      case processSteps.ANNUAL_CLEANING_CONTRACT:
        return (
          <AnnualContract
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
          />
        );
      case processSteps.PRODUCT_PHOTOS:
        return (
          <ProductPhotoContainer
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            handleCreateIdentification={handleCreateIdentification}
            isOptional={true}
          />
        );
      case processSteps.CARPET_INFORMATION:
        return (
          <CarpetInformation
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
            setCheckNoCreationDate={setCheckNoCreationDate}
          />
        );
      case processSteps.CARPET_EVALUATION:
        return (
          <CarpetEvaluation
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            enteredData={enteredData}
          />
        );
      case processSteps.DOWNLOADABLE_DOCS:
        return (
          <DownloadableDocs
            handleData={handleData}
            handleSetError={handleSetError}
            error={errors}
            submitBtnRef={refs[activeScreen]}
            setScreenDataValid={setScreenDataValid}
            handleUpdateIdentification={handleUpdateIdentification}
            statusReview={statusReview}
          />
        );
      default:
        return <Form />;
    }
  };

  return (
    <>
      <Header screenTarget={-1} icon={faChevronLeft}>
        {processHeading}
      </Header>
      <div className="container">
        {activeScreen === processSteps.FINAL_STEP ? (
          <div className={styles.subContainer}>
            <IdentificationComplete
              message={finalStepText}
              restartIdentification={restartIdentification}
              isCommercialIdentification={isCommercialIdentification}
            />
          </div>
        ) : (
          <div className="identification-steps">
            <ProgressBar
              currentValue={screenIndex}
              maxValue={Object.keys(processSteps).length}
            />
            {TOP_BANNER_SCREENS.includes(activeScreen) && (
              <CompanyBanner identificationData={newIdentificationData} />
            )}
            <div className={styles.subContainer}>
              <PageHeader
                heading={
                  isCommercialIdentification == true &&
                  activeScreen == "venueInformation"
                    ? SCREEN_HEADINGS2[activeScreen].heading
                    : SCREEN_HEADINGS[activeScreen].heading
                }
                subHeading={SCREEN_HEADINGS[activeScreen].subHeading}
                icon={SCREEN_HEADINGS[activeScreen].icon}
              />
            </div>
            {newIdentificationData && renderComponent()}
            <div className={styles.footerContainer}>
              <div style={{ margin: "3rem 0" }}>
                <InfoFooter />
              </div>
              <div className="footerButtonsContainer">
                <div
                  className={styles.backIconContainer}
                  onClick={handleBackBtn}
                >
                  <FontAwesomeIcon icon={faChevronLeft} />
                </div>

                {activeScreen === processSteps.PRODUCT_PHOTOS &&
                // newIdentificationData) ||
                !selectedIdentification ? (
                  <Button
                    className={styles.footerCtaBtn}
                    variant="primary"
                    onClick={handleContinueBtn}
                  >
                    Finaliser l’identification
                  </Button>
                ) : activeScreen === processSteps.DOWNLOADABLE_DOCS ? (
                  <div className={styles.buttonsContainer}>
                    <div className={styles.btnEnd}>
                      <Button
                        className={styles.footerCtaBtn}
                        variant="primary"
                        onClick={() => finishReview(1)}
                      >
                        Sauvegarder et reprendre plus tard
                      </Button>
                    </div>
                    <div className={styles.btnEnd}>
                      <Button
                        className={styles.footerCtaBtn}
                        variant="primary"
                        onClick={() => finishReview(2)}
                      >
                        Finaliser l’identification
                      </Button>
                    </div>
                    <div className={styles.btnEnd}>
                      <Button
                        className={styles.footerCtaBtn}
                        variant="primary"
                        onClick={() => finishReview(3)}
                      >
                        Lancer le process de récuperation
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Button
                    className={styles.footerCtaBtn}
                    variant="primary"
                    onClick={handleContinueBtn}
                  >
                    Continuer
                  </Button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withAuth(VenueInformation);
