import checkIcon from "src/assets/check.svg";
import crossIcon from "src/assets/icons/cross-black.svg";
import Button from "../button/Button";
import { Link } from "react-router-dom";
import React, { useContext } from "react";
import { AppContext } from "src/Contexts/AppContext";

import styles from "./VenueInformation.module.css";
import { useNavigate, useParams } from "react-router-dom";

export const IdentificationComplete = ({
  message = null,
  enteredData = null,
  restartIdentification = null,
  isCommercialIdentification = null,
}) => {
  const {
    state: { infosIdentificationsCreated },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const { id } = useParams();
  window.scrollTo(0, 0);
  return (
    <div className={styles.finalStepContainer}>
      <div className={styles.finalStepMainContainer}>
        <img src={checkIcon} alt="check-icon" />
        <h2 className={styles.mainHeading}>
          {message ? message : "Nouvelle identification finalisée"}
        </h2>
        <Link to={"/home"}>
          <Button variant="primary" className={styles.secondaryHeading}>
            Retour
          </Button>
        </Link>
        {restartIdentification && isCommercialIdentification != true ? (
          <Button
            variant="primary"
            // className={styles.secondaryHeading}
            onClick={restartIdentification ? restartIdentification : null}
          >
            Ajouter une nouvelle identification
          </Button>
        ) : null}
      </div>
    </div>
  );
};
