// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ClientCard_container__Kxf9L {\n  font-size: 14px;\n  padding: 10px;\n  background: var(--text-color);\n  box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);\n  border-radius: 5px;\n  line-height: 22px;\n  font-weight: 300px;\n  margin-bottom: 10px;\n  color: #fff;\n}\n\n.ClientCard_container__flex__0f8MC {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.ClientCard_container__flexBaseline__yFQeB {\n  display: flex;\n  align-items: baseline;\n  justify-content: start;\n}\n", "",{"version":3,"sources":["webpack://./src/components/clientCard/ClientCard.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,aAAa;EACb,6BAA6B;EAC7B,uCAAuC;EACvC,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,qBAAqB;EACrB,sBAAsB;AACxB","sourcesContent":[".container {\n  font-size: 14px;\n  padding: 10px;\n  background: var(--text-color);\n  box-shadow: 0px 1px 3px rgb(0 0 0 / 8%);\n  border-radius: 5px;\n  line-height: 22px;\n  font-weight: 300px;\n  margin-bottom: 10px;\n  color: #fff;\n}\n\n.container__flex {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.container__flexBaseline {\n  display: flex;\n  align-items: baseline;\n  justify-content: start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ClientCard_container__Kxf9L",
	"container__flex": "ClientCard_container__flex__0f8MC",
	"container__flexBaseline": "ClientCard_container__flexBaseline__yFQeB"
};
export default ___CSS_LOADER_EXPORT___;
