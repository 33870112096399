import { VALIDATION_ERRORS } from "src/utils/constants";
import * as yup from "yup";

export const getErrorText = (fieldName) =>
  `${fieldName} est un champ obligatoire`;

export const createClientFormSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .email(VALIDATION_ERRORS.EMAIL)
    .required(getErrorText("Email")),
  nomSocial: yup.string().trim().required(getErrorText("Nom social")),
  siret: yup.string().trim().nullable(),
  // .required(getErrorText("Numéro de SIRET")),
  tva: yup.string().trim().nullable(),
  // .required(getErrorText("Numéro de TVA intracommunautaire")),
  adresse: yup.string().trim().required(getErrorText("Adresse")),
  cp: yup.string().trim().required(getErrorText("CP")),
  ville: yup.string().trim().required(getErrorText("Ville")),
  first: yup.string().trim().required(getErrorText("Nom")),
  last: yup.string().trim().required(getErrorText("Prénom")),
  tel: yup.string().trim().required(getErrorText("Numéro de téléphone")),
  emailContact: yup
    .string()
    .trim()
    .email(VALIDATION_ERRORS.EMAIL)
    .required(getErrorText("Adresse email")),
  telContact: yup.string().trim().required(getErrorText("N° de téléphone")),
  commercial: yup.string().trim().required(getErrorText("Commercial")),
});
