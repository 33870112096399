import Header from "src/components/header/Header";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { CustomerCard } from "src/components/CustomerCard/CustomerCard";
import { SearchInput } from "src/components/SearchInput/SearchInput";

import styles from "src/pages/navigation/CustomerDatabase.module.css";
import { AppContext } from "src/Contexts/AppContext";

import { useContext, useEffect, useState } from "react";

export const CustomerDatabase = ({ identification }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [clients, setClients] = useState([]);

  const {
    getClients,
    searchClient,
    state: { clientsList, enableViewMore },
  } = useContext(AppContext);

  useEffect(() => {
    getClients();
  }, []);

  useEffect(() => {
    setClients(clientsList);
  }, [clientsList]);

  const handleSearch = (e) => {
    const {
      target: { value },
    } = e;
    setTimeout(() => {
      searchClient(value);
    }, 500);
  };

  return (
    <>
      <Header
        screenTarget={pathname == "/customer-database-home" ? "/home" : -1}
        icon={faChevronLeft}
      >
        Base de donnée client
      </Header>
      <div style={{ padding: ".5rem" }}>
        <div className={styles.topContainer}>
          <div className={styles.searchBarContainer}>
            <SearchInput
              placeholder="Tapez le nom du client ici"
              handleChange={handleSearch}
            />
          </div>
          <button
            className={styles.addCustomerButton}
            onClick={() =>
              navigate(
                identification ? "/create-client" : "/create-client-simple"
              )
            }
          >
            +
          </button>
        </div>
        {!!clients.length &&
          clients.map((item) => (
            <Link
              key={item.id}
              // to={`/creation-identification/${item.id}`}
              to={
                identification
                  ? `/creation-identification/${item.id}`
                  : `/client/${item.id}`
              }
              state={item}
            >
              <CustomerCard key={item.id} customerData={item} />
            </Link>
          ))}
        {enableViewMore && (
          <div
            className={styles.viewMoreContainer}
            onClick={() => getClients()}
          >
            <p style={{ marginRight: "5px" }}>Voir plus</p>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
        )}
      </div>
    </>
  );
};
