// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("src/assets/icons/search.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchInput_search-input__kfF7J {\n  width: 100%;\n  height: 3.5rem;\n  padding: 1rem;\n  border: none;\n  margin-bottom: 0.5rem;\n  background: transparent url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 16px;\n  background-position: center right;\n  background-origin: content-box;\n  background-color: #fff;\n}\n\n.SearchInput_search-input__kfF7J::placeholder {\n  font-size: 1rem;\n}\n\n.SearchInput_search-logo__Sh2wl {\n  position: relative;\n}\n", "",{"version":3,"sources":["webpack://./src/components/SearchInput/SearchInput.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,cAAc;EACd,aAAa;EACb,YAAY;EACZ,qBAAqB;EACrB,8EAAyE;EACzE,iCAAiC;EACjC,8BAA8B;EAC9B,sBAAsB;AACxB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":[".search-input {\n  width: 100%;\n  height: 3.5rem;\n  padding: 1rem;\n  border: none;\n  margin-bottom: 0.5rem;\n  background: transparent url(\"src/assets/icons/search.svg\") no-repeat 16px;\n  background-position: center right;\n  background-origin: content-box;\n  background-color: #fff;\n}\n\n.search-input::placeholder {\n  font-size: 1rem;\n}\n\n.search-logo {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search-input": "SearchInput_search-input__kfF7J",
	"search-logo": "SearchInput_search-logo__Sh2wl"
};
export default ___CSS_LOADER_EXPORT___;
